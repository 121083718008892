<template>
    <div>
        <!-- 상단 (PC) -->
        <Header
            v-if="!$vuetify.breakpoint.mobile"
            @nationUpdated="nationUpdated_handler"
            @deckImport="deckImport_handler"
        />

        <!-- 검색 (PC) -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile"
            class="d-flex align-center px-4 py-5 rounded-15"
        >
            <!-- 제품명 -->
            <v-autocomplete
                height="56"
                style="width:200px; font-size:18px;"
                class="shrink rounded-15 font-weight-bold mr-3"
                label="제품명"
                placeholder="전체"
                persistent-placeholder
                dense
                outlined
                clearable
                hide-details
                :items="set_list"
                v-model="keyword.set_name"
            >
                <template v-slot:append>
                    <v-icon class="pt-2 mr-1" color="#412361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 희귀도 -->
            <v-autocomplete
                height="56"
                style="width:147px; font-size:18px;"
                class="shrink rounded-15 font-weight-bold mr-3"
                placeholder="전체"
                persistent-placeholder
                label="희귀도"
                dense
                outlined
                hide-details
                clearable
                :items="computed_rarity_list"
                v-model="keyword.rarity"
            >
                <template v-slot:append>
                    <v-icon class="pt-2 mr-1" color="#412361">mdi-chevron-down</v-icon>
                </template>
            </v-autocomplete>

            <!-- 레귤레이션 -->
            <v-select
                height="56"
                style="width:220px; font-size:18px;"
                class="rounded-15 font-weight-bold mr-3"
                label="레귤레이션(중복 선택 가능)"
                multiple
                dense
                outlined
                hide-details
                placeholder="전체"
                persistent-placeholder
                :menu-props="{ offsetY: true }"
                :items="select_list.regulationMark"
                v-model="keyword.regulationMark"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-1 pt-1">{{item}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="pt-2 mr-1" color="#424361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 카드타입 -->
            <v-select
                height="56"
                style="width:200px; font-size:18px;"
                class="shrink rounded-15 font-weight-bold mr-3"
                placeholder="전체"
                persistent-placeholder
                label="카드타입"
                dense
                outlined
                hide-details
                item-text="name"
                item-value="value"
                :items="select_list.supertype"
                v-model="keyword.supertype"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2">{{item.name}}</span>
                </template>
                <template v-slot:append>
                    <v-icon class="pt-2 mr-1" color="#412361">mdi-chevron-down</v-icon>
                </template>
            </v-select>

            <!-- 카드 이름 -->
            <v-text-field
                height="56"
                style="width:250px; font-size:18px;"
                class="rounded-15 shrink font-weight-bold mr-3"
                outlined
                hide-details
                dense
                placeholder="카드 이름"
                persistent-placeholder
                v-model="keyword.text"
                append-icon="mdi-magnify"
                @keyup.enter="search()"
            >
                <template v-slot:append>
                    <v-icon size="30" class="pt-1 mt-1px mr-1" color="#412361">mdi-magnify</v-icon>
                </template>
            </v-text-field>

            <!-- 검색 버튼 -->
            <v-btn
                depressed
                dark
                class="rounded-10 font-weight-bold mr-3"
                style="font-size:18px;"
                color="primary"
                width="74"
                height="56"
                @click="search()"
            >
                검색
            </v-btn>
        </v-sheet>

        <!-- 검색 목록 & 선택한 목록 -->
        <div
            class="d-flex justify-space-between mt-3"
        >
            <v-sheet
                width="684"
                class="rounded-15 px-7 py-5"
                style="position:relative;"
            >
                <div
                    class="d-flex"
                >
                    <div
                        class="d-flex"
                    >
                        <!-- 번호 -->
                        <v-btn
                            depressed
                            dark
                            class="rounded-10 font-weight-bold mr-6px"
                            style="font-size:14px;"
                            :color="sequence == '번호'? 'primary':'#B3B3C2'"
                            width="70"
                            height="38"
                            @click="sequence == '번호'? (sequence = '번호', align_number = !align_number) : sequence = '번호'"
                        >
                            <v-icon size="16" class="mr-1">{{align_number? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                            번호
                        </v-btn>

                        <!-- 이름 -->
                        <v-btn
                            depressed
                            dark
                            class="rounded-10 font-weight-bold mr-6px"
                            style="font-size:14px;"
                            :color="sequence == '이름'? 'primary':'#B3B3C2'"
                            width="70"
                            height="38"
                            @click="sequence == '이름'? (sequence = '이름', align_name = !align_name) : sequence = '이름'"
                        >
                            <v-icon size="16" class="mr-1">{{align_name? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                            이름
                        </v-btn>

                        <!-- 채용건수 -->
                        <v-btn
                            depressed
                            dark
                            class="rounded-10 font-weight-bold mr-6px"
                            style="font-size:14px;"
                            :color="sequence == '채용건수'? 'primary':'#B3B3C2'"
                            width="93"
                            height="38"
                            @click="sequence == '채용건수'? (sequence = '채용건수', align_amount = !align_amount) : sequence = '채용건수'"
                        >
                            <v-icon size="16" class="mr-1">{{!align_amount? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                            채용건수
                        </v-btn>

                        <!-- 발매일 -->
                        <v-btn
                            depressed
                            dark
                            class="rounded-10 font-weight-bold"
                            style="font-size:14px;"
                            :color="sequence == '발매일'? 'primary':'#B3B3C2'"
                            width="84"
                            height="38"
                            @click="sequence == '발매일'? (sequence = '발매일', align_date = !align_date) : sequence = '발매일'"
                        >
                            <v-icon size="16" class="mr-1">{{!align_date? 'mdi-sort-variant':'mdi-sort-reverse-variant'}}</v-icon>
                            발매일
                        </v-btn>
                    </div>

                    <v-spacer></v-spacer>

                    <!-- PTCGL -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-6px"
                        style="font-size:14px;"
                        :color="onlyPTCGL? '#615DFA':'#b3b3c2'"
                        width="74"
                        height="38"
                        @click="onlyPTCGL = !onlyPTCGL"
                    >
                        PTCGL
                    </v-btn>

                    <!-- 내 콜렉션 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold mr-3"
                        style="font-size:14px;"
                        color="#615DFA"
                        width="90"
                        height="38"
                        @click="loadMyCollection()"
                    >
                        내 콜렉션
                    </v-btn>

                    <!-- 내 콜렉션 -->
                    <v-btn
                        icon
                        class="mt-2px"
                        v-ripple="false"
                        :color="list_type == 'card'? '#23D2E2':'#DFDFDF'"
                        @click="list_type = 'card'"
                    >
                        <v-icon size="36">mdi-image-outline</v-icon>
                    </v-btn>

                    <!-- 내 콜렉션 -->
                    <v-btn
                        icon
                        class="ml-2 mt-2px"
                        v-ripple="false"
                        :color="list_type == 'list'? '#23D2E2':'#DFDFDF'"
                        @click="list_type = 'list'"
                    >
                        <v-icon size="36">mdi-format-list-bulleted</v-icon>
                    </v-btn>
                </div>

                <!-- 로딩 -->
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="d-flex mx-auto my-16"
                    color="primary"
                    size="100"
                ></v-progress-circular>

                <div
                    v-if="!loading && list.length"
                    class="mt-6"
                >
                    <!-- 목록 방식 (card) -->
                    <v-row no-gutters v-if="currentPageList.length && list_type=='card'">
                        <v-col class="pa-0 pb-2" cols="3" v-for="item in currentPageList" :key="item.id">
                            <!-- <v-menu transition="fab-transition" open-on-hover> -->
                                <!-- 확대 전 이미지 -->
                                <!-- <template v-slot:activator="{ on, attrs }"> -->
                                    <v-card @click="selectCard(item)" width="150" style="cursor:pointer" class="mx-auto">
                                        <!-- 한글 이미지가 없을 경우 -->
                                        <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                            <!-- 영어 이미지가 있을 경우 -->
                                            <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                                <!-- PTCGL로고 표시-->
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                            </div>
                                            <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                            <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                                <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                                <!-- PTCGL로고 표시-->
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                            </div>
                                            <!-- 셋 다 이미지가 없는 경우 -->
                                            <div v-else>
                                                <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                                <!-- PTCGL로고 표시-->
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                            </div>
                                        </v-sheet>
                                        <!-- 한글 이미지가 있을 경우 -->
                                        <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                            <!-- 한글 이미지 표시 -->
                                            <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                                <!-- PTCGL로고 표시-->
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                        </v-sheet>
                                    </v-card>
                                    <!-- <v-card @click="selectCard(item)" width="150" v-bind="attrs" v-on="on" style="cursor:pointer" class="mx-auto">
                                        <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                    </v-card> -->
                                <!-- </template> -->

<!--                                <!~~ 확대 이미지 ~~>
                                <v-card elevation="12" @click="selectCard(item)" class="pa-2 rounded-lg">
                                    <!~~ 한글 이미지가 없을 경우 ~~>
                                    <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'">
                                        <!~~ 영어 이미지가 있을 경우 ~~>
                                        <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                            <img width="300" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                        </div>
                                        <!~~ 영어 이미지는 없고 일본 이미지가 있을 경우 ~~>
                                        <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                            <img width="300" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                        </div>
                                        <!~~ 셋 다 이미지가 없는 경우 ~~>
                                        <div v-else>
                                            <v-img width="300" src="@/assets/default.png"></v-img>
                                        </div>
                                    </v-sheet>
                                    <!~~ 한글 이미지가 있을 경우 ~~>
                                    <v-sheet v-if="item.image_kr && item.image_kr!='default.png'">
                                        <!~~ 한글 이미지 표시 ~~>
                                        <img width="300" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                    </v-sheet>

                                    <!~~ <v-img width="300" src="@/assets/default.png"></v-img> ~~>
                                </v-card>-->
                            <!-- </v-menu> -->
                        </v-col>
                    </v-row>

                    <!-- 목록 방식 (list) -->
                    <v-row no-gutters v-if="currentPageList.length && list_type=='list'">
                        <v-col class="py-2" cols="12" v-for="item in currentPageList" :key="item.id">
                            <v-sheet outlined class="px-6 py-4 d-flex justify-center align-center rounded-15">
                                <v-sheet>
                                    <!-- <v-menu transition="fab-transition" open-on-hover> -->
                                        <!-- 확대 전 이미지 -->
                                        <!-- <template v-slot:activator="{ on, attrs }"> -->
                                            <v-card width="150" style="cursor:pointer" class="mx-auto">
                                                <!-- 한글 이미지가 없을 경우 -->
                                                <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                                    <!-- 영어 이미지가 있을 경우 -->
                                                    <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                        <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                                    </div>
                                                    <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                                    <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                                        <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                                    </div>
                                                    <!-- 셋 다 이미지가 없는 경우 -->
                                                    <div v-else>
                                                        <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                                    </div>
                                                </v-sheet>
                                                <!-- 한글 이미지가 있을 경우 -->
                                                <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                                    <!-- 한글 이미지 표시 -->
                                                    <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                                    
                                                </v-sheet>
                                                
                                            </v-card>
                                        <!-- </template> -->
                                        <!-- 확대 이미지 -->
<!--                                        <v-card elevation="24" @click="goToCardDetail(item.id)" class="pt-2 px-2">
                                            <!~~ 한글 이미지가 없을 경우 ~~>
                                            <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'">
                                                <!~~ 영어 이미지가 있을 경우 ~~>
                                                <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                                    <img width="300" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                                </div>
                                                <!~~ 영어 이미지는 없고 일본 이미지가 있을 경우 ~~>
                                                <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'" >
                                                    <img width="300" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                                </div>
                                                <!~~ 셋 다 이미지가 없는 경우 ~~>
                                                <div v-else>
                                                    <v-img width="300" src="@/assets/default.png"></v-img>
                                                </div>
                                            </v-sheet>
                                            <!~~ 한글 이미지가 있을 경우 ~~>
                                            <v-sheet v-if="item.image_kr && item.image_kr!='default.png'">
                                                <!~~ 한글 이미지 표시 ~~>
                                                <img width="300" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                            </v-sheet>
                                        </v-card>-->
                                    <!-- </v-menu> -->
                                </v-sheet>

                                <v-sheet class="ml-10" width="320">
                                    <!-- 카드이름 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="keyword.card_nation=='en' && item.name_en"
                                            style="font-size:24px; font-weight:600; width:320px;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="keyword.card_nation=='kr' && item.name_kr"
                                            style="font-size:24px; font-weight:600; width:320px;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="keyword.card_nation=='jp' && item.name_jp"
                                            style="font-size:24px; font-weight:600; width:320px;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_jp}}
                                        </p>
                                    </div>

                                    <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                    <div class="d-flex">
                                        <!-- 레귤레이션 -->
                                        <p
                                            v-if="item.regulationMark"
                                            style="font-size:15px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            <v-icon size="19" class="pb-2px mr-1">
                                                mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                            </v-icon>
                                        </p>

                                        <!-- 넘버, 토탈넘버 -->
                                        <div class="mr-2">
                                            <!-- 영어 -->
                                            <p
                                                v-if="keyword.card_nation=='en' && item.number_en && item.printedTotal_en"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_en}} / {{item.printedTotal_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="keyword.card_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_kr}} / {{item.printedTotal_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="keyword.card_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_jp}} / {{item.printedTotal_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="d-flex ml-2px mb-1">
                                        <!-- 희귀도 -->
                                        <div>
                                            <!-- 영어 -->
                                            <p
                                                v-if="keyword.card_nation=='en' && item.rarity_en"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="keyword.card_nation=='kr' && item.rarity_kr"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="keyword.card_nation=='jp' && item.rarity_jp"
                                                style="font-size:15px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- 제품명 -->
                                    <div class="ml-1px">
                                        <!-- 영어 -->
                                        <p
                                            v-if="keyword.card_nation=='en' && item.set_en"
                                            style="font-size:15px; font-weight:300; width:320px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="keyword.card_nation=='kr' && item.set_kr"
                                            style="font-size:15px; font-weight:300; width:320px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="keyword.card_nation=='jp' && item.set_jp"
                                            style="font-size:15px; font-weight:300; width:320px;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_jp}}
                                        </p>
                                    </div>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>

                <div
                    v-if="!loading && !list.length"
                    class="mt-6 text-center py-16"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">검색 결과가 없습니다</p>
                </div>

                <!-- 페이지네이션 -->
                <v-pagination
                    class="mt-5"
                    :class="!$vuetify.breakpoint.mobile? 'mb-2':'mb-6'"
                    color="primary"
                    v-model="page"
                    :length="pageLength"
                    :total-visible="10"
                ></v-pagination>
            </v-sheet>
            <v-sheet
                width="342"
                class="rounded-15 px-7 py-5"
            >
                <div
                    class="d-flex justify-space-between"
                >
                    <p
                        class=""
                        style="font-weight:bold; font-size:18px;"
                    >
                        선택한 목록
                    </p>
                    <p
                        class=""
                        style="font-weight:bold; font-size:18px;"
                    >
                        {{selected_list_amount}}/{{deck_type}}
                    </p>
                </div>

                <div
                    style="height:655px; width:298px; overflow-y:auto; overflow-x: hidden;"
                >
                    <!-- 포켓몬 -->
                    <div
                        class="mt-6 mb-1 font-weight-medium"
                        style="color:#F8468D; font-size:15px;"
                    >
                        포켓몬 ({{selected_list_pokemon_amount}})

                        <p
                            class="mb-1 font-weight-medium text-end mr-8"
                            style="color:#F8468D; font-size:11px; margin-top:-18px;"
                        >
                            대표 이미지
                        </p>
                    
                        <v-sheet
                            v-for="item in selected_list_pokemon" :key="item.id"
                            class="mt-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    left
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <v-img
                                            v-on="{ ...menu }"
                                            width="258"
                                            height="50"
                                            position="center 30%"
                                            style="position:relative;"
                                            :src="view_image(item)"
                                        >
                                            <v-img
                                                v-if="checkBasicEnergy(item)"
                                                style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                class="d-flex"
                                                width="26"
                                                height="22"
                                                src="@/assets/tcg.png"
                                            ></v-img>
                                            <div
                                                class="d-flex"
                                                :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                style="position:relative;"
                                            >
                                                <p
                                                    v-if="!is_Standard(item)"
                                                    style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                >
                                                    익스펜디드
                                                </p>
                                                <v-sheet
                                                    height="34"
                                                    width="34"
                                                    class="rounded-circle text-center ma-2" 
                                                    style="background:#f8468d"
                                                >
                                                    <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                </v-sheet>
                                                <div
                                                    class="pl-2 mr-1 mt-1 text-truncate"
                                                    style="width:200px;"
                                                >
                                                    <p
                                                        style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                    </p>
                                                    <p
                                                        style="font-size:14px; margin-bottom:0px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                    </p>
                                                    <p
                                                        style="font-size:9px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </v-img>
                                    </template>
                                    <Detail
                                        :id="item.id"
                                        :isBoardContent="true"
                                        :nation="keyword.nation"
                                    />
                                </v-menu>

                                <div
                                    v-if="edit_mode == 'amount'"
                                >
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="plusAmount(item)"
                                    >
                                        <v-icon size="18">mdi-plus-circle</v-icon>
                                    </v-btn>
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="minusAmount(item)"
                                    >
                                        <v-icon size="18">mdi-minus-circle</v-icon>
                                    </v-btn>
                                </div>
                                <div
                                    v-if="edit_mode == 'sequence'"
                                >
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="upSequence(item)"
                                    >
                                        <v-icon size="18">mdi-chevron-up-circle-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="downSequence(item)"
                                    >
                                        <v-icon size="18">mdi-chevron-down-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-sheet>
                    </div>
                    <p
                        v-if="!selected_list_pokemon.length"
                        class="mt-2 grey--text text-caption"
                    >
                        아직 선택한 포켓몬 카드가 없습니다
                    </p>

                    <!-- 트레이너스 -->
                    <p
                        class="mt-6 mb-1 font-weight-medium"
                        style="color:#F8468D; font-size:15px;"
                    >
                        트레이너스 ({{selected_list_trainer_amount}})

                        <v-sheet
                            v-for="item in selected_list_trainer" :key="item.id"
                            class="mt-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    left
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <v-img
                                            v-on="{ ...menu }"
                                            width="258"
                                            height="50"
                                            position="center 30%"
                                            style="position:relative;"
                                            :src="view_image(item)"
                                        >
                                            <v-img
                                                v-if="checkBasicEnergy(item)"
                                                style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                class="d-flex"
                                                width="26"
                                                height="22"
                                                src="@/assets/tcg.png"
                                            ></v-img>
                                            <div
                                                class="d-flex"
                                                :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                style="position:relative;"
                                            >
                                                <p
                                                    v-if="!is_Standard(item)"
                                                    style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                >
                                                    익스펜디드
                                                </p>
                                                <v-sheet
                                                    height="34"
                                                    width="34"
                                                    class="rounded-circle text-center ma-2" 
                                                    style="background:#f8468d"
                                                >
                                                    <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                </v-sheet>
                                                <div
                                                    class="pl-2 mr-1 mt-1 text-truncate"
                                                    style="width:200px;"
                                                >
                                                    <p
                                                        style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                    </p>
                                                    <p
                                                        style="font-size:14px; margin-bottom:0px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                    </p>
                                                    <p
                                                        style="font-size:9px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </v-img>
                                    </template>
                                    <Detail
                                        :id="item.id"
                                        :isBoardContent="true"
                                        :nation="keyword.nation"
                                    />
                                </v-menu>

                                <div
                                    v-if="edit_mode == 'amount'"
                                >
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="plusAmount(item)"
                                    >
                                        <v-icon size="18">mdi-plus-circle</v-icon>
                                    </v-btn>
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="minusAmount(item)"
                                    >
                                        <v-icon size="18">mdi-minus-circle</v-icon>
                                    </v-btn>
                                </div>
                                <div
                                    v-if="edit_mode == 'sequence'"
                                >
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="upSequence(item)"
                                    >
                                        <v-icon size="18">mdi-chevron-up-circle-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="downSequence(item)"
                                    >
                                        <v-icon size="18">mdi-chevron-down-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-sheet>
                    </p>
                    <p
                        v-if="!selected_list_trainer.length"
                        class="mt-2 grey--text text-caption"
                    >
                        아직 선택한 트레이너스 카드가 없습니다
                    </p>

                    <!-- 에너지 -->
                    <p
                        class="mt-6 mb-1 font-weight-medium"
                        style="color:#F8468D; font-size:15px;"
                    >
                        에너지 ({{selected_list_energy_amount}})

                        <v-sheet
                            v-for="item in selected_list_energy" :key="item.id"
                            class="mt-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    left
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <v-img
                                            v-on="{ ...menu }"
                                            width="258"
                                            height="50"
                                            position="center 30%"
                                            style="position:relative;"
                                            :src="view_image(item)"
                                        >
                                            <v-img
                                                v-if="checkBasicEnergy(item)"
                                                style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                class="d-flex"
                                                width="26"
                                                height="22"
                                                src="@/assets/tcg.png"
                                            ></v-img>
                                            <div
                                                class="d-flex"
                                                :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                style="position:relative;"
                                            >
                                                <p
                                                    v-if="!is_Standard(item)"
                                                    style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                >
                                                    익스펜디드
                                                </p>
                                                <v-sheet
                                                    height="34"
                                                    width="34"
                                                    class="rounded-circle text-center ma-2" 
                                                    style="background:#f8468d"
                                                >
                                                    <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                </v-sheet>
                                                <div
                                                    class="pl-2 mr-1 mt-1 text-truncate"
                                                    style="width:200px;"
                                                >
                                                    <p
                                                        style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                    </p>
                                                    <p
                                                        style="font-size:14px; margin-bottom:0px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                    </p>
                                                    <p
                                                        style="font-size:9px;"
                                                    >
                                                        <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                        <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                        <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </v-img>
                                    </template>
                                    <Detail
                                        :id="item.id"
                                        :isBoardContent="true"
                                        :nation="keyword.nation"
                                    />
                                </v-menu>

                                <div
                                    v-if="edit_mode == 'amount'"
                                >
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="plusAmount(item)"
                                    >
                                        <v-icon size="18">mdi-plus-circle</v-icon>
                                    </v-btn>
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="minusAmount(item)"
                                    >
                                        <v-icon size="18">mdi-minus-circle</v-icon>
                                    </v-btn>
                                </div>
                                <div
                                    v-if="edit_mode == 'sequence'"
                                >
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="upSequence(item)"
                                    >
                                        <v-icon size="18">mdi-chevron-up-circle-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        height="20"
                                        width="18"
                                        x-small
                                        text
                                        color="#ADAFCA"
                                        @click="downSequence(item)"
                                    >
                                        <v-icon size="18">mdi-chevron-down-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-sheet>
                    </p>
                    <p
                        v-if="!selected_list_energy.length"
                        class="mt-2 grey--text text-caption"
                    >
                        아직 선택한 에너지 카드가 없습니다
                    </p>

                </div>
                <div
                    class="d-flex mt-10"
                >
                    <!-- 초기화 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 font-weight-bold"
                        style="font-size:14px;"
                        color="#ADAFCA"
                        width="95"
                        height="44"
                        @click="selected_list = []"
                    >
                        초기화
                    </v-btn>

                    <v-spacer></v-spacer>

                    <!-- 순서변경 -->
                    <v-btn
                        depressed
                        class="rounded-10 font-weight-bold"
                        style="font-size:14px;"
                        color="#ADAFCA"
                        width="95"
                        height="44"
                        @click="edit_mode == 'amount'? edit_mode = 'sequence':edit_mode = 'amount'"
                    >
                        <span
                            v-if="edit_mode == 'amount'"
                            class="white--text"
                        >
                            순서변경
                        </span>
                        <span
                            v-if="edit_mode == 'sequence'"
                            class="white--text"
                        >
                            수량변경
                        </span>
                    </v-btn>
                </div>
            </v-sheet>
        </div>

        <!-- 스탠다드 레귤레이션 & 안내문구 -->
        <div
            class="d-flex justify-space-between mt-3"
        >
            <v-sheet
                width="50%"
                height="48"
                class="rounded-15 mr-2 d-flex align-center px-4"
            >
                <v-icon color="#ADAFCA" class="mr-2">mdi-information-outline</v-icon>
                <p
                    class="my-0"
                    style="font-size:14px;"
                >
                    현재 스탠다드 레귤레이션은 <span class="primary--text font-weight-medium mr-1">{{standard_list.toString()}}</span>입니다.
                </p>
            </v-sheet>

            <v-sheet
                width="50%"
                height="48"
                class="rounded-15 d-flex align-center px-4"
            >
                <v-icon color="#ADAFCA" class="mr-2">mdi-information-outline</v-icon>
                <p
                    class="my-0"
                    style="font-size:14px;"
                >
                    비공개로 덱을 저장하면 포인트가 지급되지 않습니다.
                </p>
            </v-sheet>
        </div>

        <v-sheet
            class="rounded-15 mt-3 pa-5"
        >
            <!-- 덱 정보 입력 (PC) -->
            <div
                v-if="!$vuetify.breakpoint.mobile"
            >
                <!-- 1단 -->
                <div
                    class="d-flex mb-3"
                >
                    <!-- 덱 이름 -->
                    <v-text-field
                        height="56"
                        style="font-size:18px;"
                        class="rounded-15 grow font-weight-bold mr-3"
                        outlined
                        hide-details
                        dense
                        placeholder="덱 이름"
                        persistent-placeholder
                        v-model="title"
                        @keyup.enter="search(false)"
                    ></v-text-field>

                    <!-- 공개유무 -->
                    <v-select
                        height="56"
                        style="width:160px; font-size:18px;"
                        class="shrink rounded-15 font-weight-bold"
                        label="공개유무"
                        dense
                        outlined
                        hide-details
                        :items="select_list.status"
                        v-model="status"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-2 mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>

                <!-- 2단 -->
                <div
                    class="d-flex"
                >
                    <!-- 제품명 시즌 -->
                    <v-autocomplete
                        height="56"
                        style="width:434px; font-size:18px;"
                        class="shrink rounded-15 font-weight-bold mr-3"
                        label="제품명 시즌"
                        placeholder="덱이 사용되는 시즌(환경)의 제품명을 선택하세요"
                        persistent-placeholder
                        dense
                        outlined
                        hide-details
                        :items="season_list"
                        v-model="season"
                    >
                        <template v-slot:append>
                            <v-icon class="pt-2 mr-1" color="#412361"></v-icon>
                        </template>
                    </v-autocomplete>

                    <!-- 덱 태그 -->
                    <v-autocomplete
                        height="56"
                        style="width:210px; font-size:18px;"
                        class="shrink rounded-15 font-weight-bold mr-3"
                        label="덱 태그"
                        dense
                        outlined
                        hide-details
                        multiple
                        placeholder="태그 선택(최대 4개)"
                        persistent-placeholder
                        :items="select_list.tag"
                        v-model="tag"
                        @change="checkTagLength()"
                    >
                        <template v-slot:append>
                            <v-icon class="pt-2 mr-1" color="#412361"></v-icon>
                        </template>
                    </v-autocomplete>

                    <!-- 레귤레이션 -->
                    <v-select
                        height="56"
                        style="width:160px; font-size:18px;"
                        class="shrink rounded-15 font-weight-bold mr-3"
                        label="레귤레이션"
                        readonly
                        dense
                        outlined
                        hide-details
                        :items="select_list.regulationGroup"
                        v-model="regulationGroup"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-2 mr-1" color="#412361"></v-icon>
                        </template>
                    </v-select>

                    <!-- 덱 종류 -->
                    <v-select
                        :key="deck_type_key"
                        height="56"
                        style="width:160px; font-size:18px;"
                        class="shrink rounded-15 font-weight-bold"
                        label="덱 종류"
                        dense
                        outlined
                        hide-details
                        :items="select_list.deck_type"
                        v-model="deck_type"
                        @change="onDeckTypeChange"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-3 pt-1">{{item}}장</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="pt-2 mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>
            </div>

            <!-- Writer -->
            <TipTapWriter
                v-if="options.content"
                class="tiptap_style mt-2"
                :options="options"
                @emit_count="import_content_count"
            />
        </v-sheet>

        <!-- 로딩 -->
        <!-- <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 90%; left: 55.5%; transform: translate(-50%, -50%);"
            indeterminate
            color="primary"
            size="80"
        ></v-progress-circular> -->

        <!-- 하단 버튼 -->
        <div
            class="d-flex justify-center mt-4"
        >
            <!-- 저장 -->
            <v-btn
                depressed
                dark
                class="rounded-10 font-weight-bold mr-2"
                style="font-size:18px;"
                color="#615DFA"
                width="88"
                height="54"
                :disabled="loading"
                @click="save()"
            >
                저장
            </v-btn>

            <!-- 취소 -->
            <v-btn
                depressed
                dark
                class="rounded-10 font-weight-bold"
                style="font-size:18px;"
                color="#ADAFCA"
                width="88"
                height="54"
                @click="cancel()"
            >
                취소
            </v-btn>
        </div>

        <v-dialog
            v-model="dialog.import"
            content-class="rounded-xl"
            width="480"
            style="position:relative;"
        >
            <v-sheet
                class="px-8 py-6"
            >
                <p
                    class="text-center text-h6 font-weight-bold"
                >
                    PTCGL 덱 코드 입력
                </p>
                <v-textarea
                    style="max-height:680px;"
                    class="rounded-lg"
                    outlined
                    rows="18"
                    hide-details
                    v-model="deck_import_text"
                ></v-textarea>

                <!-- 로딩 -->
                <v-progress-circular
                    v-if="loading2"
                    style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                    indeterminate
                    color="primary"
                    size="80"
                ></v-progress-circular>
                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        depressed
                        width="120"
                        height="40"
                        color="#23D2E2"
                        class="rounded-10 white--text mr-2"
                        @click="deckImport()"
                    >
                        <v-icon small class="mr-1">mdi-content-copy</v-icon>
                        입력
                    </v-btn>
                    <v-btn
                        depressed
                        width="120"
                        height="40"
                        color="#ADAFCA"
                        class="rounded-10 white--text"
                        @click="dialog.import = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Header from '../components/Header'
import TipTapWriter from "@/components/tiptap/Writer"
import Detail from '@/components/card/detailForBoard/Detail'
import PointModule from '@/components/PointModule.js'

export default {
    components: {
        Header,
        TipTapWriter,
        Detail,
        PointModule
    },

    data: () => ({
        select_list: {
            // 슈퍼타입
            supertype: [
                {name: '모든 카드',  value:'ALL'},
                {name: '에너지',  value:'Energy'},
                {name: '포켓몬', value:'Pokémon'},
                {name: '트레이너스', value:'Trainer'}
            ],

            // 덱 태그
            tag: [],

            card_nation: [
                "en",
                "kr",
                "jp",
            ],

            search_type: [
                "전체",
            ],

            status: [
                "공개",
                "비공개"
            ],

            deck_type: [
                60,
                30
            ],

            set: {
                en: [],
                kr: [],
                jp: []
            },

            season: {
                en: [],
                kr: [],
                jp: []
            },

            renewal: {
                en: [],
                kr: [],
                jp: []
            },

            rarity: {
                en: [],
                kr: [],
                jp: []
            },

            tag: [],

            regulationMark: [],

            regulationGroup: [
                "익스펜디드",
                "스탠다드"
            ]
        },

        loading:false,
        loading2: false,

        keyword: {
            text: "",
            card_nation: "kr",
            set_name: "",
            rarity: "",
            regulationMark: [],
            supertype: "ALL",
        },

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        title: "",
        status: "공개",

        season: "",
        tag: [],

        deck_type: 60,

        sequence: "채용건수",

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        list_type: "card",

        mobile: {
            search_detail_switch: false
        },

        selected_list: [],

        deck_type_key: 0,

        edit_mode: "amount",

        standard_list: [],

        align_number: false,
        align_name: false,
        align_amount: false,
        align_date: false,

        dialog: {
            import: false
        },

        deck_import_text: "",

        loading: true,
        onlyPTCGL: false
    }),

    computed:{
        // 레귤레이션 그룹
        regulationGroup() {
            return this.selected_list.every(item =>
                    this.renewal_list.includes(item.id)
                ||
                    this.standard_list.includes(item.regulationMark)
                ||
                    (
                        item.regulationMark === null
                    &&
                        (
                                item.supertype == "Energy" && item.subtypes != null
                            &&
                                JSON.parse(item.subtypes).length
                            &&
                                (
                                    JSON.parse(item.subtypes).includes("Basic")
                                ||
                                    JSON.parse(item.subtypes).includes(null)
                                )
                        )
                    )
                )? '스탠다드':'익스펜디드'
        },

        // 재수록카드 목록
        renewal_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.renewal.en
                case 'kr' : return this.select_list.renewal.kr
                case 'jp' : return this.select_list.renewal.jp
            }
        },

        // 제품명
        set_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.set.en
                case 'kr' : return this.select_list.set.kr
                case 'jp' : return this.select_list.set.jp
            }
        },

        // 제품명 시즌
        season_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.season.en
                case 'kr' : return this.select_list.season.kr
                case 'jp' : return this.select_list.season.jp
            }
        },


        // 계산된 희귀도 목록
        computed_rarity_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.rarity.en
                case 'kr' : return this.select_list.rarity.kr
                case 'jp' : return this.select_list.rarity.jp
            }
        },

        // 선택한 목록 > 포켓몬
        selected_list_pokemon() {
            return this.selected_list.filter((e) => e.supertype == 'Pokémon')
        },

        // 선택한 목록 > 트레이너스
        selected_list_trainer() {
            return this.selected_list.filter((e) => e.supertype == 'Trainer')
        },

        // 선택한 목록 > 에너지
        selected_list_energy() {
            return this.selected_list.filter((e) => e.supertype == 'Energy')
        },

        selected_list_amount() {
            return this.selected_list.reduce((sum, item) => sum + (item.amount || 1), 0);
        },

        selected_list_type() {
            return this.selected_list.reduce((acc, e) => {
                if(JSON.parse(e.types) == null) return acc
                if (!e.types || !e.amount) return acc

                let typesArray = JSON.parse(e.types)
                for (let i = 0; i < e.amount; i++) {
                    acc = acc.concat(typesArray)
                }

                return acc
            }, [])
        },

        selected_list_pokemon_amount() {
            let result = this.selected_list.reduce((sum, item) => {
                if (item.supertype === "Pokémon") {
                    return sum + (item.amount || 1)
                }
                return sum
            }, 0)
            return result
        },

        selected_list_trainer_amount() {
            let result = this.selected_list.reduce((sum, item) => {
                if (item.supertype === "Trainer") {
                    return sum + (item.amount || 1)
                }
                return sum
            }, 0)
            return result
        },

        selected_list_energy_amount() {
            let result = this.selected_list.reduce((sum, item) => {
                if (item.supertype === "Energy") {
                    return sum + (item.amount || 1)
                }
                return sum
            }, 0)
            return result
        },
    },

    watch: {
        // 페이징
        page(){
            if(this.list_type == "card")
            {
                this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
            }
            else if(this.list_type == "list")
            {
                this.currentPageList = this.list.slice((this.page-1)*3,(this.page)*3)
            }
        },

        onlyPTCGL(){
            this.search()
        },

        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_number(){
            this.sortList()
        },

        // 순서
        align_name(){
            this.sortList()
        },

        // 순서
        align_amount(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        },

        // 목록 타입
        list_type(){
            if(this.list_type == "list")
            {
                this.currentPageList = this.list.slice(0,3)
                this.pageLength = Math.ceil(this.list.length/3)
                this.list_ready = true
                this.page = 1
            }
            else
            {
                this.currentPageList = this.list.slice(0,12)
                this.pageLength = Math.ceil(this.list.length/12)
                this.list_ready = true
                this.page = 1
            }
        },
    },

    mounted() {
        if(!this.$store.state.is_logined){
            alert("비회원은 덱 레시피 저장이 되지 않고 도중에 로그인을 하시면 만들던 덱 정보가 초기화 됩니다")
        }

        if(this.$store.state.grade == 0){
            alert("등급이 0인 회원은 덱 레시피를 저장하실 수 없습니다")
        }

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 제품명 시즌 목록 불러오기
        this.loadSeasonList()
        
        // 희귀도 목록 불러오기
        this.loadRarityList()

        // 레귤레이션 목록 불러오기
        this.loadRegulationMarkList()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        this.search_first()

        // 카드거래 공지사항 표시
        this.$http.post('/api/admin/deck/notice/import')
        .then((res) => {
            this.options.content = res.data[0].text
        })

        // 태그 목록 불러오기
        this.loadTagList()

        // 재수록카드 목록 불러오기
        this.loadRenewalList()
    },

    methods: {
        // PTCGO 마크 추가시 확인용
        checkBasicEnergy(item) {
            try {
                const subtypes = JSON.parse(item.subtypes)
                return item.ptcgoCode != null || (item.supertype == 'Energy' && Array.isArray(subtypes) && subtypes.includes('Basic'))
            } catch (e) {
                return false
            }
        },

        // 덱 입력 핸들러
        deckImport_handler(){
            if(!this.selected_list.length)
            {
                this.dialog.import = true
            }
            else
            {
                if(confirm("덱 코드 입력시 기존의 선택한 목록은 초기화됩니다.\n진행하시겠습니까?")){
                    this.selected_list = []
                    this.dialog.import = true
                }
            }
        },

        // 덱 입력
        async deckImport(){
            // 로딩 시작
            this.loading2 = true

            if(this.deck_import_text.includes('null')){
                alert("해당 덱 코드에는 null 값이 존재하므로 덱 입력을 하실 수 없습니다.")
                this.loading2 = false
                return
            }

            let deck_import_array = 
            this.deck_import_text
            .split('\n') // 줄바꿈으로 분리
            .filter(e => e != "") // 빈 줄 제거
            .filter(e => /^\d/.test(e)) // 숫자(카드 수량)으로 시작하지 않느 설명줄 제거

            for(let card of deck_import_array){
                console.log("card", card)
                console.log("this.convertList(card)", this.convertList(card))
                
                // 페어리 에너지
                if(this.convertList(card)[1].includes("{Y}"))
                {
                    await this.$http.post("/api/new/deck/select/card/import/byid", {
                        params: {
                            id: "sm1-172",
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }

                // 에너지
                else if(this.convertList(card)[1].includes("Energy"))
                {
                    await this.$http.post("/api/new/deck/select/card/import/basicEnergy", {
                        params: {
                            name_en: this.convertName(this.convertList(card)[1]),
                            ptcgoCode: this.convertList(card)[2],
                            number_en: this.convertList(card)[3]
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }

                // 특수기호 있는 콜렉션 넘버
                else if(
                    this.convertList(card)[2] == "PR-SV"
                    || this.convertList(card)[2] == "PR-SW"
                    || this.convertList(card)[2] == "PR-SM"
                    || this.convertList(card)[2] == "PR-XY"
                    || this.convertList(card)[2] == "PR-BLW"
                    || this.convertList(card)[2] == "PR-HS"
                    || this.convertList(card)[2] == "PR-DPP"
                    || this.convertList(card)[2] == "PR-NP"
                    || this.convertList(card)[2] == "PR"
                    || this.convertList(card)[2].includes("-TG")
                    || this.convertList(card)[2].includes("-GG")
                )
                {
                    await this.$http.post("/api/new/deck/select/card/import/numberConvert", {
                        params: {
                            name_en: this.convertName(this.convertList(card)[1]),
                            ptcgoCode: this.convertList(card)[2],
                            number_en: this.convertList(card)[3]
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }

                // 위 예외처리 이후 나머지
                else
                {
                    await this.$http.post("/api/new/deck/select/card/import", {
                        params: {
                            name_en: this.convertName(this.convertList(card)[1]),
                            ptcgoCode: this.convertList(card)[2],
                            number_en: this.convertList(card)[3]
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], parseInt(this.convertList(card)[0]))
                        }else{
                            await this.searchInTable(card, parseInt(this.convertList(card)[0]))
                        }
                    })
                }
            }

            this.loading2 = false
            this.dialog.import = false
        },

        // 에너지 중괄호 변경하기
        convertName(text){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            text = text.replace("{G}", "Grass")
            text = text.replace("{R}", "Fire")
            text = text.replace("{W}", "Water")
            text = text.replace("{L}", "Lightning")
            text = text.replace("{P}", "Psychic")
            text = text.replace("{F}", "Fighting")
            text = text.replace("{D}", "Darkness")
            text = text.replace("{M}", "Metal")

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        convertList(str) {
            // 먼저 공백으로 문자열을 분할합니다.
            let parts = str.split(' ')

            // 뒤에 PH 붙는거 경우
            if(parts[parts.length - 1] == "PH")
            {
                parts.pop()
            }

            // 첫 번째 값
            let firstValue = parts[0]

            // 마지막 두 값
            let thirdValue = parts[parts.length - 2]
            let fourthValue = parts[parts.length - 1]

            // 중간에 남은 부분이 두 번째 값입니다.
            let secondValue = parts.slice(1, parts.length - 2).join(' ')

            // 에너지카드이고 제품명이 없어서
            // 제품명으로 Energy가 이동한 경우 처리 (Energy 다시 붙여줌)
            if(!secondValue.includes("Energy") && thirdValue == "Energy")
            {
                secondValue = secondValue + " Energy"
                thirdValue = "SVE"
            }
            else if(thirdValue == "Energy")
            {
                thirdValue = "SVE"
            }

            return [firstValue, secondValue, thirdValue, fourthValue]
        },

        async searchInTable(deck_code, amount){
            console.log("searchInTable()")
            console.log(deck_code)
            console.log(amount)

            await this.$http.post("/api/admin/deck/ptcgl/select/specific", {
                params: {
                    text: deck_code.substring(deck_code.indexOf(' ') + 1)
                }
            }).then(async(res) => {
                console.log(res)
                if(res.data.length){
                    await this.$http.post("/api/new/deck/select/card/import/byid", {
                        params: {
                            id: res.data[0].card_id,
                        }
                    }).then(async(res) => {
                        if(res.data.length){
                            await this.selectCard(res.data[0], amount)
                        }
                    })
                }else {
                    alert(deck_code + "PTCGL 카드 데이터를 찾을 수 없습니다. 운영자에게 해당 코드를 공유 해주시면 처리 해드리겠습니다")
                }
            })
        },

        checkTagLength(){
            if(this.tag.length > 4){
                alert("태그는 최대 4개까지 선택할 수 있습니다.")
                this.tag = this.tag.slice(0,4)
            }
        },

        // 태그 목록 불러오기
        loadTagList(){
            this.$http.get('/api/admin/deck/tag/list')
            .then((res) => {
                this.select_list.tag = res.data.reverse().map(e => e.tag)
            })
        },
        
        // 내 콜렉션
        loadMyCollection(){
            this.loading = true
            this.$http.post("/api/new/card_kr/select/search/collection", {
                params: {
                    keyword: this.keyword,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data
                    if(this.list_type == "card"){
                        this.currentPageList = this.list.slice(0,12)
                        this.pageLength = Math.ceil(res.data.length/12)
                    }
                    else if(this.list_type == "list")
                    {
                        this.currentPageList = this.list.slice(0,3)
                        this.pageLength = Math.ceil(res.data.length/3)
                    }
                    this.list_ready = true
                    this.page = 1

                    this.sortList()
                    this.loading = false
                }else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                    this.loading = false
                }
            })
        },

        // 스탠다드인지 확인
        is_Standard(item){
            if(this.renewal_list.includes(item.id))
            {
                return true
            }
            else
            {
                if(item.supertype == "Energy" && item.subtypes != null)
                {
                    if(JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))
                    {
                        return true
                    }
                    else
                    {
                        if(this.standard_list.includes(item.regulationMark))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                }
                else
                {
                    if(this.standard_list.includes(item.regulationMark))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
            }
        },

        // 레귤레이션 목록 불러오기
        loadRegulationMarkList(){
            this.$http.post('/api/admin/card/regulation_mark/list')
            .then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark)
            })
        },

        view_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },


        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 재수록카드 목록 불러오기
        loadRenewalList(){
            // 한글
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })

            // 영어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.renewal.en = res.data.map(a => a.card_id)
            })

            // 일어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })
        },

        // 제품명 시즌 목록 불러오기
        loadSeasonList(){
            // 영어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "en"
                }
            }).then(res => {
                this.select_list.season.en = res.data.map(a => a.name)
            })

            // 한글
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.season.kr = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "jp"
                }
            }).then(res => {
                this.select_list.season.jp = res.data.map(a => a.name)
            })
        },

        // 희귀도 목록 불러오기
        loadRarityList(){
            this.$http.post('/api/card/select/rarity')
            .then(res => {
                this.select_list.rarity.en = res.data.map(e => e.en).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.kr = res.data.map(e => e.kr).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.jp = res.data.map(e => e.jp).filter(e => e!=null && e!="-" && e!="")
            })
        },

        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            text = text.replaceAll('Grass', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png"></img>`)
            text = text.replaceAll('Fire', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png"></img>`)
            text = text.replaceAll('Water', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png"></img>`)
            text = text.replaceAll('Lightning', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png"></img>`)
            text = text.replaceAll('Psychic', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png"></img>`)
            text = text.replaceAll('Fighting', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png"></img>`)
            text = text.replaceAll('Darkness', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png"></img>`)
            text = text.replaceAll('Metal', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png"</img>`)
            text = text.replaceAll('Fairy', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png"</img>`)
            text = text.replaceAll('Dragon', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png"></img>`)
            text = text.replaceAll('Colorless', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png"></img>`)
            return text
        },

        // 국가선택 $Emit Handler
        nationUpdated_handler(card_nation){
            this.keyword = {
                card_nation : card_nation,
                text: "",
                set_name: "",
                rarity: "",
                regulationMark: [],
                types: "",
                supertype: "ALL"
            },

            this.search()

            this.selected_list = []
        },

        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        // 카드 선택
        selectCard(item, amount){
            console.log("selected_card", item)
            console.log(amount)

            // 찬란한 포켓몬
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "Radiant Rare" && this.selected_list.some(e => e.rarity_en == "Radiant Rare")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "K 찬란한" && this.selected_list.some(e => e.rarity_kr == "K 찬란한")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "K かがや" && this.selected_list.some(e => e.rarity_jp == "K かがや")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            // ACE SPEC
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "ACE SPEC Rare" && this.selected_list.some(e => e.rarity_en == "ACE SPEC Rare")){
                    alert("ACE SPEC Rare는 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "ACE SPEC" && this.selected_list.some(e => e.rarity_kr == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "ACE SPEC" && this.selected_list.some(e => e.rarity_jp == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            if(this.selected_list_amount == this.deck_type) {
                alert("최대 수량에 도달하였습니다.")
            }else{
                // 이미 존재하는 지
                let findedExistCard = this.selected_list.find(e => e.id === item.id)

                // 존재하면 수량 증가
                if(findedExistCard)
                {
                    // 30장 덱
                    if(this.deck_type == 30)
                    {
                        // 기본 에너지의 경우
                        if(findedExistCard.supertype == "Energy" && (JSON.parse(findedExistCard.subtypes) == "Basic" || JSON.parse(findedExistCard.subtypes) == null))
                        {
                            findedExistCard.amount += amount? amount : 1
                        }

                        // 기본 에너지가 아닌 경우
                        else
                        {
                            const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 2 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 2)

                            console.log(existsAmountOverFour)

                            if(existsAmountOverFour){
                                alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                            }else {
                                if(item.amount >= 2)
                                {
                                    alert("60장 덱에 기본 에너지 이외의 카드는 2장까지 추가하실 수 있습니다")
                                }
                                else
                                {
                                    item.amount += amount? amount : 1
                                }
                            }
                        }
                    }

                    // 60장 덱
                    if(this.deck_type == 60)
                    {
                        console.log('HI')
                        // 기본 에너지의 경우
                        if(findedExistCard.supertype == "Energy" && (JSON.parse(findedExistCard.subtypes) == "Basic" || JSON.parse(findedExistCard.subtypes) == null))
                        {
                            findedExistCard.amount += amount? amount : 1
                        }

                        // 기본 에너지가 아닌 경우
                        else
                        {
                            const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 4 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)

                            if(existsAmountOverFour){
                                alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                            }else {
                                if(findedExistCard.amount >= 4)
                                {
                                    alert("60장 덱에 기본 에너지 이외의 카드는 4장까지 추가하실 수 있습니다")
                                }
                                else
                                {
                                    findedExistCard.amount += amount? amount : 1
                                }
                            }
                        }
                    }
                }

                // 존재하지 않으면 카드 추가
                else
                {
                    const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                    const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                        // 국가에 따라 다른 속성 이름을 사용
                        const propName = `name_${cardNation}`;
                        const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                        // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                        if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                            // 일치한다면 amount 합산
                            if (!acc[nameWithoutParentheses]) {
                                acc[nameWithoutParentheses] = 0;
                            }
                            acc[nameWithoutParentheses] += obj.amount;
                        }

                        // 각 단계마다의 acc 출력 (디버깅 용)
                        console.log(acc);

                        // acc를 반환해야 다음 iteration에서 사용할 수 있음
                        return acc;
                    }, {});

                    // 합산된 amount가 2, 4 이상인지 확인
                    const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)
                    const existsAmountOverTwo = Object.values(amountSumsByName).some(amount => amount >= 2)

                    if(this.deck_type == 30){
                        if(existsAmountOverTwo){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                        }else {
                            this.selected_list.push(item)

                            if(amount){
                                item.amount += amount-1
                            }
                        }
                    }
                    else if(this.deck_type == 60){
                        if(existsAmountOverFour){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                        }else {
                            this.selected_list.push(item)

                            if(amount){
                                item.amount += amount-1
                            }
                        }
                    }
                }
            }
        },

        // 순서
        sortList(){
            if(this.sequence == "번호")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => a.number_en - b.number_en)
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => a.number_kr - b.number_kr)
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => a.number_jp - b.number_jp)
                }
                if(this.align_number){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "이름")
            {
                if(this.keyword.card_nation == "en"){
                    this.list.sort((a, b) => (typeof a.name_en !== 'string' ? 1 : (typeof b.name_en !== 'string' ? -1 : a.name_en.localeCompare(b.name_en))))
                }else if(this.keyword.card_nation == "kr"){
                    this.list.sort((a, b) => (typeof a.name_kr !== 'string' ? 1 : (typeof b.name_kr !== 'string' ? -1 : a.name_kr.localeCompare(b.name_kr))))
                }else if(this.keyword.card_nation == "jp"){
                    this.list.sort((a, b) => (typeof a.name_jp !== 'string' ? 1 : (typeof b.name_jp !== 'string' ? -1 : a.name_jp.localeCompare(b.name_jp))))
                }
                if(this.align_name){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "채용건수")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => b.amount_en - a.amount_en)
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => b.amount_kr - a.amount_kr)
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => b.amount_jp - a.amount_jp)
                }
                if(this.align_amount){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "발매일")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => new Date(b.releaseDate_en).getTime() - new Date(a.releaseDate_en).getTime())
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => new Date(b.releaseDate_kr).getTime() - new Date(a.releaseDate_kr).getTime())
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => new Date(b.releaseDate_jp).getTime() - new Date(a.releaseDate_jp).getTime())
                }
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }

            // console.log("채용건수", this.list.map(e => e.amount_kr))
            // console.log("발매일", this.list.map(e => e.releaseDate_kr))

            if(this.list_type == "card"){
                this.currentPageList = this.list.slice(0,12)
                this.pageLength = Math.ceil(this.list.length/12)
            }
            else if(this.list_type == "list")
            {
                this.currentPageList = this.list.slice(0,3)
                this.pageLength = Math.ceil(this.list.length/3)
            }

            this.list_ready = true
        },

        // 검색
        search_first(){
            this.loading = true
            this.$http.post("/api/new/card/search/first", {
                params: {
                    nation: this.keyword.card_nation
                }
            }).then((res) => {
                console.log(res.data)
                // console.log(res.data.map(e => e.ptcgoCode))
                if(res.data.length){
                    this.list = res.data

                    // .slice(0,120)
                    if(this.list_type == "card"){
                        this.currentPageList = this.list.slice(0,12)
                        this.pageLength = Math.ceil(res.data.length/12)
                    }
                    else if(this.list_type == "list")
                    {
                        this.currentPageList = this.list.slice(0,3)
                        this.pageLength = Math.ceil(res.data.length/3)
                    }
                    this.list_ready = true
                    this.page = 1
                    this.loading = false

                    this.sortList()
                }else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                    this.loading = false
                }
            })
        },

        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/new/card_"+this.keyword.card_nation+"/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                console.log(res.data.map(e => e.ptcgoCode))
                if(res.data.length){
                    this.list = res.data

                    if(this.onlyPTCGL){
                        this.list = this.list.filter(e => {
                            // PTCGL 코드가 있거나
                            if (e.ptcgoCode) return true
                            
                            try {
                                // 기본 에너지인 경우도 포함
                                const subtypes = JSON.parse(e.subtypes)
                                return e.supertype == 'Energy' && Array.isArray(subtypes) && subtypes.includes('Basic')
                            } catch (e) {
                                return false
                            }
                        })
                    }

                    if(this.list_type == "card"){
                        this.currentPageList = this.list.slice(0,12)
                        this.pageLength = Math.ceil(res.data.length/12)
                    }
                    else if(this.list_type == "list")
                    {
                        this.currentPageList = this.list.slice(0,3)
                        this.pageLength = Math.ceil(res.data.length/3)
                    }
                    this.list_ready = true
                    this.page = 1

                    this.sequence = "발매일"
                    this.sortList()
                    this.loading = false
                }else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                    this.loading = false
                }
            })
        },

        onDeckTypeChange(newValue) {
            if (this.selected_list_amount > newValue) {
                alert("설정하시려는 덱 종류보다 선택한 카드 수가 더 많습니다")
            } else {
                if(newValue == 30){
                    if (this.selected_list_amount > newValue) {
                        alert("설정하시려는 덱 종류보다 선택한 카드 수가 더 많습니다")
                        this.deck_type = 60
                        this.deck_type_key++
                    } else {
                        // 2개 이상이면서 해당 기본에너지가 포함되지 않은 경우
                        if(this.selected_list.some(item => (item.amount > 2) && !(item.supertype == "Energy" && item.subtypes != null && (JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))))){
                            alert("30장 덱으로 변경하시려면 기본 에너지 이외의 카드는 2장까지로 수량을 변경하셔야 합니다")
                            this.deck_type = 60
                            this.deck_type_key++
                        }
                        // 2개 이상인 것은 모두 기본 에너지인 경우
                        else{
                            this.deck_type = 30
                            this.deck_type_key++
                        }
                    }
                // 60장으로 변경하는 거면
                }else{
                    this.deck_type = 60
                    this.deck_type_key++
                }
            }
        },

        cancel(){
            if(confirm("지금 작성하고 있는 내용은 저장되지 않으며\n이전 페이지로 이동됩니다. 이동하시겠습니까?"))
            {
                this.$router.go(-1)
            }
        },

        // 수량 증가
        plusAmount(item){
            // 찬란한 포켓몬
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "Radiant Rare" && this.selected_list.some(e => e.rarity_en == "Radiant Rare")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "K 찬란한" && this.selected_list.some(e => e.rarity_kr == "K 찬란한")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "K かがや" && this.selected_list.some(e => e.rarity_jp == "K かがや")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            // ACE SPEC
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "ACE SPEC Rare" && this.selected_list.some(e => e.rarity_en == "ACE SPEC Rare")){
                    alert("ACE SPEC Rare는 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "ACE SPEC" && this.selected_list.some(e => e.rarity_kr == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "ACE SPEC" && this.selected_list.some(e => e.rarity_jp == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            console.log(item)

            if(this.selected_list_amount < this.deck_type)
            {
                // 30장 덱
                if(this.deck_type == 30)
                {
                    // 기본 에너지의 경우
                    if(item.supertype == "Energy" && item.subtypes != null && (JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null))))
                    {
                        item.amount += 1
                    }

                    // 기본 에너지가 아닌 경우
                    else
                    {
                        const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 2 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 2)

                        console.log(existsAmountOverFour)

                        if(existsAmountOverFour){
                            alert("30장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                        }else {
                            if(item.amount >= 2)
                            {
                                alert("30장 덱에 기본 에너지 이외의 카드는 2장까지 추가하실 수 있습니다")
                            }
                            else
                            {
                                item.amount += 1
                            }
                        }
                    }
                }

                // 60장 덱
                if(this.deck_type == 60)
                {
                    // 기본 에너지의 경우
                    if(item.supertype == "Energy" && item.subtypes != null && (JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null))))
                    {
                        item.amount += 1
                    }

                    // 기본 에너지가 아닌 경우
                    else
                    {
                        const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 4 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)

                        console.log(existsAmountOverFour)

                        if(existsAmountOverFour){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                        }else {
                            if(item.amount >= 4)
                            {
                                alert("60장 덱에 기본 에너지 이외의 카드는 4장까지 추가하실 수 있습니다")
                            }
                            else
                            {
                                item.amount += 1
                            }
                        }
                    }
                }
            }
            else
            {
                alert("덱 최대 수량에 도달하였습니다")
            }
        },

        // 수량 감소
        minusAmount(item){
            if(item.amount >= 2){
                item.amount--
            }else if(item.amount == 1){
                let index = this.selected_list.indexOf(item)
                if (index !== -1) {
                    this.selected_list.splice(index, 1)
                }
            }
        },

        // 순서 위로
        upSequence(item){
            let filteredIndex = 0

            if(item.supertype == 'Pokémon')
            {
                filteredIndex = this.selected_list_pokemon.indexOf(item)
            }
            if(item.supertype == 'Trainer')
            {
                filteredIndex = this.selected_list_trainer.indexOf(item)
            }
            if(item.supertype == 'Energy')
            {
                filteredIndex = this.selected_list_energy.indexOf(item)
            }

            if (filteredIndex !== -1) {
                let originalIndex = this.selected_list.indexOf(item)

                if (originalIndex > 0) {
                    let temp = this.selected_list[originalIndex - 1]
                    this.selected_list[originalIndex - 1] = this.selected_list[originalIndex]
                    this.selected_list[originalIndex] = temp
                }

                this.selected_list = [...this.selected_list]
            }
        },

        // 순서 아래로
        downSequence(item){
            let filteredIndex = 0

            if(item.supertype == 'Pokémon')
            {
                filteredIndex = this.selected_list_pokemon.indexOf(item)
            }
            if(item.supertype == 'Trainer')
            {
                filteredIndex = this.selected_list_trainer.indexOf(item)
            }
            if(item.supertype == 'Energy')
            {
                filteredIndex = this.selected_list_energy.indexOf(item)
            }

            if (filteredIndex !== -1) {
                let originalIndex = this.selected_list.indexOf(item)

                if (originalIndex < this.selected_list.length - 1) {
                    let temp = this.selected_list[originalIndex + 1]
                    this.selected_list[originalIndex + 1] = this.selected_list[originalIndex]
                    this.selected_list[originalIndex] = temp
                }

                this.selected_list = [...this.selected_list]
            }
        },

        // 저장
        save(){
            if(!this.$store.state.is_logined)
            {
                alert("비회원은 덱 레시피 만들기를 할 수 없습니다")
                return
            }

            if(this.title == "")
            {
                alert("덱 이름을 입력해주세요")
            }
            else if(this.season == "")
            {
                alert("제품명 시즌을 선택해주세요")
            }
            else if(this.options.content == "")
            {
                alert("덱 설명을 입력해주세요")
            }
            else if(this.selected_list.length == 0)
            {
                alert("카드를 선택해주세요")
            }
            else if(this.selected_list_amount != this.deck_type)
            {
                alert("덱 총 장수를 채워야 저장하실 수 있습니다")
            }
            else
            {
                this.loading = true

                // 덱 자동 분류





                // 입력
                this.$http.post("/api/new/deck/insert", {
                    params: {
                        writer: this.$store.state.user_id,
                        title: this.title,
                        content: this.options.content,
                        season: this.season,
                        tag: this.tag.toString(),
                        regulation_group: this.regulationGroup,
                        deck_nation: this.keyword.card_nation,
                        size: this.deck_type,
                        original_id: null,
                        status: this.status
                    }
                }).then(async (res) => {
                    for (let item of this.selected_list) {
                        await this.$http.post("/api/new/deck/insert/card", {
                            params: {
                                deck_id: res.data.insertId,
                                deck_nation: this.keyword.card_nation,
                                card_supertype: item.supertype,
                                card_id: item.id,
                                amount: item.amount
                            }
                        })
                    }

                    for (let type of this.selected_list_type) {
                        await this.$http.post("/api/new/deck/insert/type", {
                            params: {
                                deck_id: res.data.insertId,
                                type: type
                            }
                        })
                    }

                    // 포인트 적립
                    if(this.status == "공개"){
                        await PointModule.amountCheck('덱 레시피', '공개용으로 덱레시피 만들기').then((res) => {
                            PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                            PointModule.Record(this.$store.state.user_id, '덱 레시피', '공개용으로 덱레시피 만들기', this.title, res.data[0].amount)
                        })
                    }

                    // 덱 분류하기
                    let e = {
                        id: res.data.insertId
                    }
                    await this.deckUpdate(e)

                    alert("덱이 등록되었습니다.")
                    this.loading = false

                    this.$emit("submit")
                })
            }
        },

        // 덱 분류하기
        async deckUpdate(e){
            // 덱 하나하나 돌리기
            console.log("덱", e)

            // 덱 카드 가져오기 (deck_card_list & card_dump)
            let res = await this.$http.post("/api/league/auto/load/deck_card_list", {
                params: {
                    deck_id: e.id
                }
            })

            let deck_card_list = res.data
            console.log("덱 내 카드 목록", deck_card_list)

            let deck_main_card_list = []

            // 덱 카드 별 메인포켓몬 매칭해보기
            let promises = deck_card_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_card_list", {
                    params: {
                        name: e.name,
                        count: e.amount
                    }
                }).then((res) => {
                    if (res.data.length) {
                        return res.data
                    }
                    return []
                })
            })

            // 메인포켓몬 매칭된 덱들 합치기 (덱분류 후보 생성)
            await Promise.all(promises).then((results) => {
                results.forEach(data => {
                    if (data.length) {
                        deck_main_card_list.push(...data)
                    }
                })
            })

            // 덱분류 후보 (1차 분류)
            console.log("덱분류 후보 (1차 분류)", deck_main_card_list)




            // 덱분류 후보별 메인포켓몬이 더 있는 경우 확인하기
            let promises2 = deck_main_card_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_card_list/multiple", {
                    params: {
                        deck_auto_id: e.deck_auto_id
                    }
                }).then((res) => {
                    if (res.data.length == 1) {
                        // 메인 포켓몬이 하나인 경우
                        return { type: 'single', data: res.data }
                    } else {
                        // 메인 포켓몬이 두 개 이상인 경우
                        return { type: 'multiple', data: res.data }
                    }
                })
            })
            
            let list1 = [] // 메인 포켓몬이 하나인 경우
            let list2 = [] // 메인 포켓몬이 두 개 이상인 경우

            await Promise.all(promises2).then((results) => {
                results.forEach(item => {
                    if (item.type === 'single') {
                        list1.push(item.data)
                    } else if (item.type === 'multiple') {
                        list2.push(item.data)
                    }
                });
            });

            // 결과 확인
            console.log("메인 포켓몬 1개인 경우 (일단 확정): ", list1)
            console.log("메인 포켓몬 2개 이상인 경우: ", list2)


            // 메인 포켓몬 2개 이상을 모두 일치하는지 덱인지 확인
            let deckCardNames = deck_card_list.map(item => item.name)
            list2 = list2.filter(subList => {
                return subList.every(item => deckCardNames.includes(item.main_pokemon_name))
            })

            console.log("조건에 맞는 메인 포켓몬 2개 이상의 리스트: ", list2)

            // 메인 포켓몬 조건을 통과한 덱 (1, 2~ 합산)
            let main_pass_list = [...list1, ...list2]
            console.log("메인 포켓몬 조건을 통과한 덱 (1, 2~ 합산)", main_pass_list)

            // 통과된 덱이 없으면 PASS
            if(!main_pass_list.length){
                // continue
                return
            }

            // ! 덱분류 후보덱 별 하위덱분류(variant) 확인
            let deck_auto_variant_list = []
            for (const e of main_pass_list) {
                let res = await this.$http.post("/api/league/auto/load/deck_auto_variant_list", {
                    params: {
                        deck_auto_id: e.deck_auto_id
                    }
                })

                console.warn("하위덱분류가 있는지 확인", res.data)
                if(res.data.length){
                    console.error("하위덱분류 찾음", res.data)
                    deck_auto_variant_list.push(...res.data)
                }
            }

            //  덱분류 후보덱 별 하위덱분류(variant) 목록
            console.log("하위덱분류 목록", deck_auto_variant_list)
            // ! -- /


            // 첫번쨰 배열로 매핑(메인포켓몬으로 인해 배열로 됨)
            let final_list = []
            final_list = main_pass_list.map(e => e[0])

            // generation 높은순 -> priority 높은순 정렬
            final_list.sort((a, b) => {
                // 먼저 generation 값으로 비교
                if (a.generation > b.generation) {
                    return -1 // a가 b보다 큰 경우
                }
                if (a.generation < b.generation) {
                    return 1 // b가 a보다 큰 경우
                }

                // generation 값이 같으면 priority 값으로 비교
                return b.priority - a.priority // 내림차순 정렬
            })

            // 최종 선정된 덱 분류
            let final_deck = final_list[0]
            console.log("최종 선정된 덱 분류", final_deck)



            // 메인 포켓몬 다시 찾아오기
            let final_main_pokemon = deck_card_list.filter(e => e.name == final_deck.main_pokemon_name)[0]
            console.log("최종 선정된 메인포켓몬", final_main_pokemon)

            this.$http.post("/api/league/auto/update/deck/detail", {
                params: {
                    id: e.id,
                    deck_name: final_deck.deck_name,
                    main_pokemon: final_main_pokemon.name,
                    main_pokemon_id: final_main_pokemon.card_id,
                    main_pokemon_image: final_main_pokemon.image
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    console.log("덱 분류 입력됨")
                }
            })
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:16px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>
<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

::v-deep .v-input__icon--clear {
    padding-top:16px !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}

.gradient-overlay-expanded {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #ff521c, transparent);
  position: relative;
}

.gradient-overlay-standard {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #232323, transparent);
  position: relative;
}

.deckInfoTable {
    width:100%;
}

.deckInfoTable tr td{
    padding: 6px 4px;
}
</style>