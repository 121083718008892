<template>
    <div
        class="mobile"
    >
        <!-- 제목 -->
        <div
            class="d-flex align-center px-4 py-3"
        >
            <p
                style="font-size:16px; font-weight:bold; cursor:pointer;"
                class="ml-1 my-0"
                @click="$router.push('/deck/list')"
            >
                덱 레시피
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>

            <v-spacer></v-spacer>

            <!-- 덱 레시피 주소 복사 -->
            <v-btn
                width="80"
                height="34"
                class="px-0 rounded-15 mr-1"
                style="font-size:12px;"
                color="#615DFA"
                depressed
                dark
                v-ripple="false"
                @click="copyUrl()"
            >
                주소복사
            </v-btn>


            <!-- 덱 출력 -->
            <v-btn
                width="62"
                height="34"
                class="px-0 rounded-15 mr-1"
                style="font-size:12px;"
                color="#615DFA"
                depressed
                dark
                v-ripple="false"
                @click="capture()"
            >
                덱 출력
            </v-btn>

            <!-- 목록 -->
            <v-btn
                width="60"
                height="34"
                class="px-0 rounded-15"
                style="font-size:12px;"
                color="primary"
                depressed
                dark
                v-ripple="false"
                to="/deck/list"
            >
                목록
            </v-btn>
        </div>

        <v-sheet
            v-if="!is_exist"
            height="400"
            class="rounded-15"
            style="padding-top:130px;"
        >
            <v-col class="text-center">
                <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                <p class="blue--text">존재하지 않는 덱 레시피 입니다</p>
            </v-col>
        </v-sheet>
    
        <div
            v-if="is_exist"
            class="px-4"
        >
            <div
                class="d-flex justify-space-between mb-3 mobile"
            >
                <v-select
                    height="36"
                    style="font-size:12px; max-width:49%;"
                    class="flex-grow-1 rounded-10 mr-1"
                    label="카드보기 형식"
                    dense
                    outlined
                    hide-details
                    :items="select_list.view_type"
                    v-model="view_type"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-select
                    height="36"
                    style="font-size:12px; max-width:49%;"
                    class="flex-grow-1 rounded-10"
                    label="국가보기 변경"
                    dense
                    outlined
                    hide-details
                    :items="select_list.nation"
                    v-model="nation"
                    item-text="name"
                    item-value="value"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3">{{item.name}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <!-- <v-select
                    height="36"
                    style="font-size:12px; max-width:49%;"
                    class="flex-grow-1 rounded-10"
                    label="포켓몬카드 정렬"
                    dense
                    outlined
                    hide-details
                    :items="select_list.sort"
                    v-model="sort"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-3">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select> -->
            </div>

            <!-- 덱 -->
            <div
                id="capture"
                class="rounded-15 mb-3 white"
                style="padding:12px 12px; margin-top:36px;"
            >
                <div
                    class="py-4"
                >
                    <div>
                        <!-- 이미지 -->
                        <v-card
                            class="rounded-15 elevation-0 d-flex justify-center"
                            color="transparent"
                            style="overflow:hidden; margin-top:-60px;"
                        >
                            <v-card
                                class="rounded-15 elevation-0 mb-6"
                                style="position:relative;"
                            >
                                <v-img
                                    height="100"
                                    width="267"
                                    class="rounded-15"
                                    position="center 30%"
                                    :src="deck.image"
                                >
                                    <v-sheet
                                        v-if="deck.like_count >= 10"
                                        width="64"
                                        height="24"
                                        class="font-weight-bold rounded-15"
                                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:8px; top:8px;"
                                    >
                                        <span style="color:#424361; font-size:14px;">인기덱</span>
                                    </v-sheet>
                                </v-img>

                                <!-- 이미지 테스트용 -->
                                <!-- <v-img
                                    height="100"
                                    width="267"
                                    class="rounded-15"
                                    position="center 30%"
                                    src="@/assets/card.png"
                                >
                                    <v-sheet
                                        v-if="deck.like_count >= 10"
                                        width="64"
                                        height="24"
                                        class="font-weight-bold rounded-15"
                                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:8px; top:8px;"
                                    >
                                        <span style="color:#424361; font-size:14px;">인기덱</span>
                                    </v-sheet>
                                </v-img> -->

                                <v-sheet
                                    style="position:absolute; bottom:-12px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                    width="180"
                                    height="32"
                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                >
                                    <Avatar
                                        v-if="deck.writer"
                                        :user="deck.writer"
                                        :color="'black'"
                                    />
                                    <p
                                        style="margin-left:-4px; font-size:15px; font-weight:bold; color:#424361;"
                                        class="mb-0 mr-4 mt-1px"
                                    >{{deck.writer_nickname}}</p>
                                </v-sheet>
                            </v-card>
                        </v-card>

                        <!-- 이미지 아래 정보 -->
                        <div
                            class="d-flex justify-space-between mt-2"
                        >
                            <div>
                                <!-- 제목 -->
                                <p
                                    style="font-size:13px; font-weight:bold;"
                                    class="mb-1"
                                >
                                    {{deck.title}}
                                </p>
                                
                                <!-- 작성일자 & 조회수 & 좋아요 & 복사수 -->
                                <p
                                    class="d-flex align-center mb-1"
                                    style="font-size:10px;"
                                >
                                    <v-icon size="12">mdi-clock-outline</v-icon><span class="ml-1">{{new Date(deck.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}</span>
                                    <v-icon size="12" class="ml-4">mdi-eye-outline</v-icon><span class="ml-1">{{deck.view_count}}</span>
                                    <v-icon size="12" class="ml-4">mdi-thumb-up-outline</v-icon><span class="ml-1">{{like_list.length}}</span>
                                    <v-icon size="12" class="ml-4">mdi-content-copy</v-icon><span class="ml-1">{{deck.copy_count}}</span>
                                </p>
                            </div>

                            <div class="text-end mt-6px">
                                <p
                                    style="font-size:10px; margin-bottom:-4px;"
                                    class="ml-1"
                                >
                                    평점 {{deck.rating_avg}} <span style="color:#615DFA">({{deck.rating_count}})</span>
                                </p>
                                <v-rating
                                    :value="deck.rating_avg"
                                    half-increments
                                    readonly
                                    background-color="grey"
                                    color="#615DFA"
                                    size="12"
                                ></v-rating>
                            </div>
                        </div>
                        <p
                            class="mb-0 text-body-2"
                        >
                            <strong>시즌</strong>
                            <font
                            >
                                {{deck.season}}
                            </font>
                        </p>
                    </div>
                </div>

                <v-divider class="mb-4"></v-divider>

                <p
                    class="icu_purple--text text-caption font-weight-medium mb-0 text-center"
                    style="cursor:pointer;"
                    @click="dialog.summary = true"
                >
                    <v-icon size="16" class="pb-2px" color="icu_purple">mdi-open-in-new</v-icon>
                    한 화면에 모든 카드 보기
                </p>

                <!-- 카드 목록 -->
                <div>
                    <!-- 기본 -->
                    <div
                        v-if="view_type == '기본' && !loading"
                    >
                        <div
                            class="pa-4 mb-4"
                        >
                            <!-- 포켓몬 -->
                            <div class="ml-3 mb-6">
                                <p
                                    class="mb-2"
                                    style="font-size:15px; color:#F8468D;"
                                >
                                    포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                                </p>
                                <v-sheet
                                    v-for="item in card_list_pokemon" :key="item.id"
                                    class="mt-1"
                                    width="245"
                                >
                                    <div
                                        class="d-flex"
                                    >
                                        <v-menu
                                            offset-x
                                            right
                                            open-on-hover
                                            nudge-right="0"
                                            max-width="320"
                                            content-class="rounded-15"
                                            :close-on-content-click="true"
                                        >
                                            <template v-slot:activator="{ on: menu }">
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    height="50"
                                                    position="center 30%"
                                                    style="position:relative;"
                                                    :src="view_image(item)"
                                                >
                                                    <v-img
                                                        v-if="checkBasicEnergy(item)"
                                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                        class="d-flex"
                                                        width="26"
                                                        height="22"
                                                        src="@/assets/tcg.png"
                                                    ></v-img>
                                                    <div
                                                        class="d-flex"
                                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                        style="position:relative;"
                                                    >
                                                        <p
                                                            v-if="!is_Standard(item)"
                                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                        >
                                                            익스펜디드
                                                        </p>
                                                        <v-sheet
                                                            height="34"
                                                            width="34"
                                                            class="rounded-circle text-center ma-2" 
                                                            style="background:#f8468d"
                                                        >
                                                            <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                        </v-sheet>
                                                        <div
                                                            class="pl-2 mr-1 mt-1 text-truncate"
                                                            style="width:184px;"
                                                        >
                                                            <p
                                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:14px; margin-bottom:0px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:9px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-img>
                                            </template>
                                            <Detail
                                                :id="item.card_id"
                                                :isBoardContent="true"
                                                :nation="nation"
                                            />
                                        </v-menu>
                                    </div>
                                </v-sheet>
                            </div>

                            <!-- 트레이너스 -->
                            <div class="ml-3 mb-6">
                                <p
                                    class="mb-2"
                                    style="font-size:15px; color:#F8468D;"
                                >
                                    트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                                </p>
                                <v-sheet
                                    v-for="item in card_list_trainer" :key="item.id"
                                    class="mt-1"
                                    width="245"
                                >
                                    <div
                                        class="d-flex"
                                    >
                                        <v-menu
                                            offset-x
                                            right
                                            open-on-hover
                                            nudge-right="0"
                                            max-width="320"
                                            content-class="rounded-15"
                                            :close-on-content-click="true"
                                        >
                                            <template v-slot:activator="{ on: menu }">
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    height="50"
                                                    position="center 30%"
                                                    style="position:relative;"
                                                    :src="view_image(item)"
                                                >
                                                    <v-img
                                                        v-if="checkBasicEnergy(item)"
                                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                        class="d-flex"
                                                        width="26"
                                                        height="22"
                                                        src="@/assets/tcg.png"
                                                    ></v-img>
                                                    <div
                                                        class="d-flex"
                                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                        style="position:relative;"
                                                    >
                                                        <p
                                                            v-if="!is_Standard(item)"
                                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                        >
                                                            익스펜디드
                                                        </p>
                                                        <v-sheet
                                                            height="34"
                                                            width="34"
                                                            class="rounded-circle text-center ma-2" 
                                                            style="background:#f8468d"
                                                        >
                                                            <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                        </v-sheet>
                                                        <div
                                                            class="pl-2 mr-1 mt-1 text-truncate"
                                                            style="width:184px;"
                                                        >
                                                            <p
                                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:14px; margin-bottom:0px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:9px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-img>
                                            </template>
                                            <Detail
                                                :id="item.card_id"
                                                :isBoardContent="true"
                                                :nation="nation"
                                            />
                                        </v-menu>
                                    </div>
                                </v-sheet>
                            </div>

                            <!-- 에너지 -->
                            <div class="ml-3 mb-6">
                                <p
                                    class="mb-2"
                                    style="font-size:15px; color:#F8468D;"
                                >
                                    에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                                </p>
                                <v-sheet
                                    v-for="item in card_list_energy" :key="item.id"
                                    class="mt-1"
                                    width="245"
                                >
                                    <div
                                        class="d-flex"
                                    >
                                        <v-menu
                                            offset-x
                                            right
                                            open-on-hover
                                            nudge-right="0"
                                            max-width="320"
                                            content-class="rounded-15"
                                            :close-on-content-click="true"
                                        >
                                            <template v-slot:activator="{ on: menu }">
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    height="50"
                                                    position="center 30%"
                                                    style="position:relative;"
                                                    :src="view_image(item)"
                                                >
                                                    <v-img
                                                        v-if="checkBasicEnergy(item)"
                                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                        class="d-flex"
                                                        width="26"
                                                        height="22"
                                                        src="@/assets/tcg.png"
                                                    ></v-img>
                                                    <div
                                                        class="d-flex"
                                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                        style="position:relative;"
                                                    >
                                                        <p
                                                            v-if="!is_Standard(item)"
                                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                        >
                                                            익스펜디드
                                                        </p>
                                                        <v-sheet
                                                            height="34"
                                                            width="34"
                                                            class="rounded-circle text-center ma-2" 
                                                            style="background:#f8468d"
                                                        >
                                                            <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                        </v-sheet>
                                                        <div
                                                            class="pl-2 mr-1 mt-1 text-truncate"
                                                            style="width:184px;"
                                                        >
                                                            <p
                                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:14px; margin-bottom:0px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                            </p>
                                                            <p
                                                                style="font-size:9px;"
                                                            >
                                                                <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                                <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                                <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </v-img>
                                            </template>
                                            <Detail
                                                :id="item.card_id"
                                                :isBoardContent="true"
                                                :nation="nation"
                                            />
                                        </v-menu>
                                    </div>
                                </v-sheet>
                            </div>


                        </div>
                    </div>

                    <!-- 이미지(전체) -->
                    <div
                        v-if="view_type == '이미지(전체)' && !loading"
                        class="mb-4"
                    >
                        <div
                            class="d-flex align-center pr-2 mb-4"
                        >
                            <p
                                class="mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="ml-4 mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="ml-4 mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <!-- 포켓몬 -->
                        <v-row
                            no-gutters
                        >
                            <v-col
                                v-for="(item, index) in card_list_total_view" :key="'전체'+ index"
                                class="pa-1"
                                cols="6"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                :src="view_image(item)"
                                            ></v-img>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- 이미지(중복) -->
                    <div
                        v-if="view_type == '이미지(중복)' && !loading"
                        class="mb-4"
                    >
                        <div
                            class="d-flex align-center pr-2 mb-4"
                        >
                            <p
                                class="mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="ml-4 mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="ml-4 mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <!-- 포켓몬 -->
                        <v-row
                            no-gutters
                        >
                            <v-col
                                v-for="item in card_list" :key="'중복'+item.id"
                                class="pa-1"
                                cols="6"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <!-- 이미지 -->
                                            <v-card
                                                class="rounded-lg elevation-0"
                                                style="overflow:hidden;"
                                            >
                                                <v-card
                                                    class="rounded-lg elevation-0 mb-6"
                                                    style="position:relative;"
                                                >
                                                    <v-img
                                                        v-on="{ ...menu }"
                                                        width="258"
                                                        class="rounded-lg"
                                                        position="center 30%"
                                                        :src="view_image(item)"
                                                    ></v-img>
                                                    <v-sheet
                                                        style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                        width="72"
                                                        height="32"
                                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                                    >
                                                        <p
                                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                                            class="mb-0 text-center mt-1px"
                                                        >{{item.amount}}</p>
                                                    </v-sheet>
                                                </v-card>
                                            </v-card>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- 표(카드포함) -->
                    <div
                        v-if="view_type == '표(카드포함)' && !loading"
                        class="mb-4"
                    >
                        <div
                            class="d-flex align-center pr-2 mb-4"
                        >
                            <p
                                class="mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="ml-4 mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <p
                                class="ml-4 mb-2"
                                style="font-size:13px; color:#F8468D;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                        </div>

                        <v-data-table
                            id="list"
                            hide-default-header
                            :items="card_list"
                            :footer-props="{
                                'items-per-page-options': [-1],
                                'itemsPerPageText': ''
                            }"
                            hide-default-footer
                            mobile-breakpoint="0"
                        >
                            <template v-slot:header>
                                <tr>
                                    <th style="width:80px; padding-left:0px;">이미지</th>
                                    <th>카드</th>
                                </tr>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td style="width:80px; padding:8px 4px;">
                                        <v-img
                                            v-if="nation=='en'" 
                                            width="48"
                                            class="mx-auto"
                                            :src="'/upload/card_en_image/'+(item.image_en==null? 'default.png':encodeURI(item.image_en))"
                                        ></v-img>
                                        <v-img 
                                            v-if="nation=='kr'"
                                            width="48"
                                            class="mx-auto"
                                            :src="'/upload/card_kr_image/'+(item.image_kr==null? 'default.png':encodeURI(item.image_kr))"
                                        ></v-img>
                                        <v-img 
                                            v-if="nation=='jp'" 
                                            width="48"
                                            class="mx-auto"
                                            :src="'/upload/card_jp_image/'+(item.image_jp==null? 'default.png':encodeURI(item.image_jp))"
                                        ></v-img>
                                        <!-- <v-img 
                                            width="80"
                                            class="mx-auto"
                                            src="@/assets/card.png"
                                        ></v-img> -->
                                    </td>
                                    <td style="max-width:120px !important; padding:8px 4px;">
                                        <p v-if="nation=='en'" class="mb-0" style="font-size:14px; font-weight:bold;">{{item.name_en}}</p>
                                        <p v-if="nation=='kr'" class="mb-0" style="font-size:14px; font-weight:bold;">{{item.name_kr}}</p>
                                        <p v-if="nation=='jp'" class="mb-0" style="font-size:14px; font-weight:bold;">{{item.name_jp}}</p>
                                        <p class="mb-0" style="font-size:11px; letter-spacing:-0.5px;">
                                            <v-icon class="mr-2px mb-2px" size="14" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                            <font v-if="nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                            <font v-if="nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                            <font v-if="nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                            <font v-if="nation=='en' && item.printedTotal_en"> / {{item.printedTotal_en.replaceAll("\"", "")}}</font>
                                            <font v-if="nation=='kr' && item.printedTotal_kr"> / {{item.printedTotal_kr}}</font>
                                            <font v-if="nation=='jp' && item.printedTotal_jp"> / {{item.printedTotal_jp}}</font>
                                            <span class="ml-2">
                                                <font v-if="nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                                                <font v-if="nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                                                <font v-if="nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                                            </span>
                                        </p>
                                        <p v-if="nation=='en' && item.set_en" class="mb-0 text-truncate" style="font-size:11px; letter-spacing:-0.2px;">{{item.set_en}}</p>
                                        <p v-if="nation=='kr' && item.set_kr" class="mb-0 text-truncate" style="font-size:11px; letter-spacing:-0.2px;">{{item.set_kr}}</p>
                                        <p v-if="nation=='jp' && item.set_jp" class="mb-0 text-truncate" style="font-size:11px; letter-spacing:-0.2px;">{{item.set_jp}}</p>
                                        <div
                                            class="d-flex"
                                        >
                                            <font class="d-block" style="font-size:12px; font-weight:bold;">
                                                <span v-if="item.supertype == 'Pokémon'">포켓몬</span>
                                                <span v-if="item.supertype == 'Trainer'">트레이너스</span>
                                                <span v-if="item.supertype == 'Energy'">에너지</span>
                                            </font>
                                            <font v-if="item.subtypes" class="d-block" style="margin-left:12px; font-size:12px; font-weight:bold;">
                                                {{computed_subtypes(item)}}
                                            </font>
                                        </div>
                                        <v-sheet
                                            outlined
                                            class="rounded-xl px-2 d-flex justify-center"
                                            style="font-size:12px;"
                                        >
                                            {{item.amount}}장
                                        </v-sheet>
                                    </td>
                                    <!-- <td style="width:100px">
                                        <font class="d-block text-center" style="min-width:100px;">
                                            <v-icon class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                        </font>
                                    </td>
                                    <td style="width:80px"><font class="d-block text-center" style="min-width:80px;">{{item.amount}}</font></td>
                                    <td style="width:120px"><font class="d-block text-center" style="min-width:120px;">{{item.price? $toComma(item.price):0}}원</font></td> -->
                                </tr>
                            </template>
                        </v-data-table>
                    </div>

                    <!-- 표(텍스트만) -->
                    <div
                        v-if="view_type == '표(텍스트만)' && !loading"
                        class="mb-10"
                    >
                        <v-sheet class="py-10 text-center">
                            <v-icon size="60" color="#424361" class="mb-2">mdi-close-circle-outline</v-icon>
                            <p style="font-size:14px; font-weight:bold">
                                표(텍스트만) 보기는 모바일버전에서<br/>
                                지원하지 않습니다. PC버전에서 확인해주세요
                            </p>
                        </v-sheet>
                    </div>

                    <!-- 로딩 -->
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        class="d-flex my-16 mx-auto"
                        size="100"
                    ></v-progress-circular>
                </div>

                <v-btn
                    height="26"
                    width="96"
                    class="px-0 d-block mx-auto rounded-10"
                    style="font-size:10px; letter-spacing:-1px; min-width:0px; border:1px solid #E0E0EB;"
                    text
                    tile
                    depressed
                    v-ripple="false"
                    @click="deckExport_handler()"
                >
                    <v-sheet height="18" width="16" class="d-flex">
                        <v-img contain src="@/assets/deck_export.svg"></v-img>
                    </v-sheet>
                    <span style="color:#424361;">덱 코드 출력</span>
                </v-btn>


                <div
                    class="d-flex align-center justify-space-between"
                >
                    <!-- 태그 -->
                    <p
                        class="ml-2px mb-0 pb-2px"
                    >
                        <font
                            v-if="deck.tag"
                            class="primary--text"
                            style="font-size:10px;"
                        >
                            {{"#"+deck.tag?.replaceAll(',', ' #')}}
                        </font>
                    </p>

                    <div
                        class="d-flex"
                        style="font-size:10px; font-weight:500;"
                    >
                        <div
                            v-if="type_list.filter(item => item.type === 'Grass').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Grass.png" />
                            {{type_list.filter(item => item.type === 'Grass').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fire').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Fire.png" />
                            {{type_list.filter(item => item.type === 'Fire').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Water').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Water.png" />
                            {{type_list.filter(item => item.type === 'Water').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Lightning').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Lightning.png" />
                            {{type_list.filter(item => item.type === 'Lightning').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Psychic').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Psychic.png" />
                            {{type_list.filter(item => item.type === 'Psychic').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fighting').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Fighting.png" />
                            {{type_list.filter(item => item.type === 'Fighting').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Darkness').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Darkness.png" />
                            {{type_list.filter(item => item.type === 'Darkness').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Metal').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Metal.png" />
                            {{type_list.filter(item => item.type === 'Metal').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fairy').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Fairy.png" />
                            {{type_list.filter(item => item.type === 'Fairy').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Dragon').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Dragon.png" />
                            {{type_list.filter(item => item.type === 'Dragon').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Colorless').length"
                            class="d-flex align-center mx-1"
                        >
                            <img width="16" height="16" style="vertical-align:sub; margin-right:2px;" src="https://icu.gg/upload/type/Colorless.png" />
                            {{type_list.filter(item => item.type === 'Colorless').length}}
                        </div>
                    </div>
                </div>

                <v-divider class="my-1 mb-3"></v-divider>

                <div>
                    <div
                        class="d-flex justify-space-between"
                        style="font-size:10px;"
                    >
                        {{deck.regulation_group}} {{deck.size}}장 
                        
                        <p
                            class="text-end mb-0"
                            style="font-size:10px;"
                        >
                            덱 예상 가격 : {{$toComma(card_list.reduce((sum, card) => sum + (card.price || 0), 0))}}원
                            <v-tooltip top color="primary" content-class="rounded-15">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ml-2px mb-1px"
                                        color="grey lighten-1"
                                        size="14"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-help-circle-outline
                                    </v-icon>
                                </template>
                                <div class="white--text text-center pa-1">
                                    각 카드별 마지막 거래가의 총 합산 가격으로<br/>
                                    정확하지 않을 수 있으니 참고만 바랍니다
                                </div>
                            </v-tooltip>
                        </p>
                    </div>
                    <p
                        style="font-size:10px;"
                    >
                        {{deck.deck_code}}
                    </p>
                </div>

                <!-- 본문 -->
                <TipTapReader
                    v-if="options.content"
                    class="tiptap_style mb-2"
                    :options="options"
                />

                <!-- 버튼 (덱 추천, 덱 테스트, 덱 복사) -->
                <div
                    class="d-flex justify-center mb-1"
                >
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-size:10px;"
                        color="#615DFA"
                        width="88"
                        height="26" 
                        @click="like()"             
                    >
                        <v-icon size="12" class="mr-1">mdi-thumb-up-outline</v-icon>
                        덱 추천
                    </v-btn>
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 mx-2"
                        style="font-size:10px;"
                        color="#615DFA"
                        width="88"
                        height="26"      
                        @click="dialog.deck_test = true; reset_deck_test()"          
                    >
                        <v-icon size="12" class="mr-1">mdi-hand-back-left-outline</v-icon>
                        덱 테스트
                    </v-btn>
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-size:10px;"
                        color="#615DFA"
                        width="88"
                        height="26"           
                        @click="dialog.copy = true"
                    >
                        <v-icon size="12" class="mr-1">mdi-export-variant</v-icon>
                        덱 복사
                    </v-btn>
                </div>

                <!-- 복사된 덱 안내 -->
                <p
                    v-if="original_deck.id"
                    class="text-center"
                    style="font-size:10px; cursor:pointer;"
                    @click="$router.push('/deck/read?id='+original_deck.id)"
                >
                    이 덱 레시피는 <span style="color:#23D2E2;">{{original_deck.title}}</span>에서 복사된 덱 레시피입니다
                </p>

                <!-- 스탠다드 레귤레이션 안내 -->
                <div class="d-flex align-center justify-center px-2 mt-4">
                    <v-icon color="#ADAFCA" class="mr-1">mdi-information-outline</v-icon>
                    <p
                        class="my-0"
                        style="font-size:11px;"
                    >
                        현재 스탠다드 레귤레이션은 <span class="primary--text font-weight-medium mr-1">{{standard_list.toString()}}</span>입니다.
                    </p>
                </div>

                <!-- 댓글 -->
                <Review
                    :deck="deck"
                    @Updated="reloadDeck"
                />
            </div>
            <!-- 버튼 (목록, 덱 복사, 덱 수정, 덱 삭제) -->
            <div
                class="d-flex mb-3"
            >
                <v-btn
                    depressed
                    dark
                    small
                    class="rounded-5 mr-1"
                    style="font-size:10px; font-weight:bold;"
                    color="#ADAFCA"
                    width="48"
                    height="22"
                    @click="$router.push('/deck/list')"
                >
                    목록
                </v-btn>

                <v-btn
                    depressed
                    dark
                    small
                    class="rounded-5"
                    style="font-size:10px; font-weight:bold;"
                    color="#ADAFCA"
                    width="48"
                    height="22"
                    @click="$router.push('/deck/list')"
                >
                    신고
                </v-btn>

                <v-spacer></v-spacer>

                <!-- 블라인드 (관리자일 경우) -->
                <v-btn
                    v-if="$store.state.type=='관리자' && status!='블라인드됨'"
                    dark
                    depressed
                    width="24"
                    height="24"
                    icon
                    class="mr-6px pa-0 font-weight-bold rounded-circle"
                    color="#23D2E2"
                    @click="blindPost()"
                >
                    <v-img contain src="@/assets/board/blind.svg"></v-img>
                </v-btn>

                <!-- 블라인드 해제 (관리자일 경우) -->
                <v-btn
                    v-if="$store.state.type=='관리자' && status=='블라인드됨'"
                    dark
                    depressed
                    width="24"
                    height="24"
                    icon
                    class="mr-6px pa-0 font-weight-bold rounded-circle"
                    color="#23D2E2"
                    @click="blindClearPost()"
                >
                    <v-img contain src="@/assets/board/blind.svg"></v-img>
                </v-btn>

                <!-- 게시판 이동 (관리자일 경우) -->
                <v-btn
                    v-if="$store.state.type=='관리자'"
                    dark
                    depressed
                    width="24"
                    height="24"
                    icon
                    class="mr-6px pa-0 font-weight-bold rounded-circle"
                    style="background:#615DFA"
                    @click="dialog.movePost = true"
                >
                    <v-icon size="18">mdi-arrow-right-bold</v-icon>
                </v-btn>

                <!-- 글쓴이일 경우 또는 관리자일 경우 -->
                <div
                    v-if="deck.writer == $store.state.user_id || $store.state.type=='관리자'"
                    class="d-flex"
                >
                    <v-btn
                        dark
                        depressed
                        width="24"
                        height="24"
                        icon
                        class="mr-6px pa-0 font-weight-bold rounded-circle"
                        color="#F8468D"
                        @click="updateDeck()"
                    >
                        <v-img contain src="@/assets/board/update.svg"></v-img>
                    </v-btn>
                    <v-btn
                        dark
                        depressed
                        width="24"
                        height="24"
                        icon
                        class="pa-0 font-weight-bold rounded-circle"
                        color="#F8468D"
                        @click="deleteDeck()"
                    >
                        <v-img contain src="@/assets/board/delete.svg"></v-img>
                    </v-btn>
                </div>
            </div>
        </div>

        <BannerView
            v-if="$store.state.ads_banner.deck_recipe"
            class="mt-3"
            type="deck_recipe"
            width="100%"
            :height="46/360*100+'vw'"
            :round="'rounded-0'"
        />

        <!-- 덱 테스트 (dialog) (Mobile) -->
        <v-bottom-sheet
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.export"
            fullscreen
        >
            <v-sheet
                color="#e4e4e4"
                class="pa-2"
                height="100vh"
            >
                <v-sheet
                    class="py-4 px-3 mobile2"
                    color="#f8f7fb"
                    style="position:relative; border:2px solid #f8468d;"
                >
                    <v-icon style="position:absolute; right:8px; top:8px;" color="red" @click="dialog.export = false">mdi-close</v-icon>

                    <p
                        style="font-size:16px; font-weight:bold;"
                        class="px-2 pt-1"
                    >
                        PTCGL 덱 코드 출력
                    </p>

                    <v-textarea
                        style="background:white; border:none !important; height:400px !important; min-height:400px; padding:2px;"
                        class="rounded-lg"
                        rows="14"
                        hide-details
                        v-model="deck_export_text"
                    ></v-textarea>

                    <div
                        class="d-flex justify-center mt-4"
                    >
                        <v-btn
                            depressed
                            dark
                            class="ml-3 rounded-10"
                            style="font-size:13px; font-weight:bold;"
                            color="#F8468D"
                            width="78"
                            height="34"
                            @click="deckExport_copy()"
                        >
                            <v-icon size="13" class="mr-1">mdi-content-copy</v-icon>
                            복사
                        </v-btn>
                        <v-btn
                            depressed
                            dark
                            class="ml-3 rounded-10"
                            style="font-size:13px; font-weight:bold;"
                            color="#ADAFCA"
                            width="78"
                            height="34"
                            @click="dialog.export = false"
                        >
                            닫기
                        </v-btn>
                    </div>
                </v-sheet>
            </v-sheet>
        </v-bottom-sheet>

        <!-- 덱 테스트 (dialog) (Mobile) -->
        <v-bottom-sheet
            v-if="$vuetify.breakpoint.mobile"
            v-model="dialog.deck_test"
            fullscreen
        >
            <v-sheet
                color="#e4e4e4"
                class="pa-2"
                height="100vh"
            >
                <v-sheet
                    class="py-4 px-3"
                    color="#f8f7fb"
                    style="position:relative; border:2px solid #f8468d;"
                >
                    <v-icon style="position:absolute; right:8px; top:8px;" color="red" @click="dialog.deck_test = false">mdi-close</v-icon>
                    <p
                        class="ml-2 font-weight-bold mb-3"
                        style="font-size:16px;"
                    >
                        덱 테스트
                    </p>
                    <v-sheet
                        class="rounded-lg py-3 px-5 mb-4"
                    >
                        <p
                            class="font-weight-bold"
                        >
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                            첫 손패 7장
                        </p>
                        <div
                            class="d-flex"
                            style="overflow-x:auto"
                        >
                            <!-- 포켓몬 -->
                            <v-sheet
                                v-for="(item, index) in card_list_hand" :key="index"
                                class="pa-1"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <!-- 이미지 -->
                                            <v-card
                                                class="rounded-lg elevation-0"
                                                style="overflow:hidden;"
                                            >
                                                <!-- {{item.name_kr}} -->
                                                <v-card
                                                    class="rounded-lg elevation-0 mb-6"
                                                    style="position:relative;"
                                                >
                                                    <v-img
                                                            v-on="{ ...menu }"
                                                        width="144"
                                                        class="rounded-lg"
                                                        position="center 30%"
                                                        :src="view_image(item)"
                                                    ></v-img>
                                                    <v-sheet
                                                        style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                        width="72"
                                                        height="32"
                                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                                    >
                                                        <p
                                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                                            class="mb-0 text-center mt-1px"
                                                        >{{index+1}}</p>
                                                    </v-sheet>
                                                    
                                                </v-card>
                                            </v-card>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                            :key="componentKey"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>
                    </v-sheet>
                    <v-sheet
                        class="rounded-lg py-3 px-5 mb-4"
                    >
                        <p
                            class="font-weight-bold"
                        >
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                            프라이즈 카드 6장
                        </p>
                        <div
                            class="d-flex"
                            style="overflow-x:auto"
                        >
                            <!-- 포켓몬 -->
                            <v-sheet
                                v-for="(item, index) in card_list_prize" :key="index"
                                class="pa-1"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <!-- 이미지 -->
                                            <v-card
                                                class="rounded-lg elevation-0"
                                                style="overflow:hidden;"
                                            >
                                                <!-- {{item.name_kr}} -->
                                                <v-card
                                                    class="rounded-lg elevation-0 mb-6"
                                                    style="position:relative;"
                                                >
                                                    <v-img
                                                            v-on="{ ...menu }"
                                                        width="144"
                                                        class="rounded-lg"
                                                        position="center 30%"
                                                        :src="view_image(item)"
                                                    ></v-img>
                                                    <v-sheet
                                                        style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                        width="72"
                                                        height="32"
                                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                                    >
                                                        <p
                                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                                            class="mb-0 text-center mt-1px"
                                                        >{{index+8}}</p>
                                                    </v-sheet>
                                                </v-card>
                                            </v-card>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                            :key="componentKey"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>
                    </v-sheet>
                    <v-sheet
                        class="rounded-lg py-3 px-5 pb-0"
                        height="412"
                    >
                        <p
                            class="font-weight-bold"
                        >
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                            드로우 카드 ({{card_list_draw.length+13}}/{{deck.size}})
                        </p>
                        <div
                            class="d-flex"
                            style="height:250px; overflow-x:auto"
                            ref="drawContainer"
                        >
                            <!-- 포켓몬 -->
                            <v-sheet
                                v-for="(item, index) in card_list_draw" :key="index"
                                class="pa-1"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <!-- 이미지 -->
                                            <v-card
                                                class="rounded-lg elevation-0"
                                                style="overflow:hidden;"
                                            >
                                                <!-- {{item.name_kr}} -->
                                                <v-card
                                                    class="rounded-lg elevation-0 mb-6"
                                                    style="position:relative;"
                                                >
                                                    <v-img
                                                            v-on="{ ...menu }"
                                                        width="144"
                                                        class="rounded-lg"
                                                        position="center 30%"
                                                        :src="view_image(item)"
                                                    ></v-img>
                                                    <v-sheet
                                                        style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                        width="72"
                                                        height="32"
                                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                                    >
                                                        <p
                                                            style="font-size:15px; font-weight:bold; color:#424361;"
                                                            class="mb-0 text-center mt-1px"
                                                        >{{index+14}}</p>
                                                    </v-sheet>
                                                </v-card>
                                            </v-card>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                            :key="componentKey"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>

                        <div
                            class="mt-6 d-flex justify-center mobile"
                        >
                            <v-select
                                height="32"
                                style="width:90px;"
                                class="shrink rounded-10"
                                :items="select_list.draw_amount"
                                outlined
                                item-text="name"
                                item-value="value"
                                v-model="draw_amount"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="pl-2 pt-5px">{{item.name}}</span>
                                </template>
                                <template v-slot:append>
                                    <v-icon class="mr-1 pt-2px" color="#424361">mdi-chevron-down</v-icon>
                                </template>
                            </v-select>

                            <v-btn
                                width="80"
                                height="32"
                                small
                                depressed
                                dark
                                class="ml-1 rounded-10"
                                style="font-size:13px; font-weight:bold;"
                                color="#F8468D"
                                @click="draw()"
                            >
                                드로우
                            </v-btn>
                        </div>
                    </v-sheet>

                    <div
                        class="d-flex justify-center mt-4"
                    >
                        <v-btn
                            depressed
                            dark
                            class="ml-3 rounded-10"
                            style="font-size:13px; font-weight:bold;"
                            color="#23d2e2"
                            width="80"
                            height="34"
                            @click="reset_deck_test()"
                        >
                            다시 뽑기
                        </v-btn>
                        <v-btn
                            v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                            depressed
                            dark
                            class="ml-3 rounded-10"
                            style="font-size:13px; font-weight:bold;"
                            color="#B3B3C2"
                            width="80"
                            height="34"
                            @click="dialog.deck_test = false"
                        >
                            닫기
                        </v-btn>
                    </div>
                </v-sheet>
            </v-sheet>
        </v-bottom-sheet>

        <!-- 덱 테스트 (dialog) -->
        <v-dialog
            v-if="!$vuetify.breakpoint.mobile"
            v-model="dialog.deck_test"
            width="1220"
            content-class="rounded-15"
        >
            <v-sheet
                class="rounded-15 py-8 px-12"
                color="#f8f7fb"
            >
                <p
                    class="text-h6 font-weight-bold"
                >
                    덱 테스트
                </p>
                <v-sheet
                    class="rounded-lg py-3 px-5 mb-4"
                >
                    <p
                        class="font-weight-bold"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        첫 손패 7장
                    </p>
                    <div
                        class="d-flex"
                        style="overflow-x:auto"
                    >
                        <!-- 포켓몬 -->
                        <v-sheet
                            v-for="(item, index) in card_list_hand" :key="index"
                            class="pa-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <!-- {{item.name_kr}} -->
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                        v-on="{ ...menu }"
                                                    width="144"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{index+1}}</p>
                                                </v-sheet>
                                                
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                        :key="componentKey"
                                    />
                                </v-menu>
                            </div>
                        </v-sheet>
                    </div>
                </v-sheet>
                <v-sheet
                    class="rounded-lg py-3 px-5 mb-4"
                >
                    <p
                        class="font-weight-bold"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        프라이즈 카드 6장
                    </p>
                    <div
                        class="d-flex"
                        style="overflow-x:auto"
                    >
                        <!-- 포켓몬 -->
                        <v-sheet
                            v-for="(item, index) in card_list_prize" :key="index"
                            class="pa-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <!-- {{item.name_kr}} -->
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                        v-on="{ ...menu }"
                                                    width="144"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{index+8}}</p>
                                                </v-sheet>
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                        :key="componentKey"
                                    />
                                </v-menu>
                            </div>
                        </v-sheet>
                    </div>
                </v-sheet>
                <v-sheet
                    class="rounded-lg py-3 px-5 pb-0"
                    height="412"
                >
                    <p
                        class="font-weight-bold"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        드로우 카드 ({{card_list_draw.length+13}}/{{deck.size}})
                    </p>
                    <div
                        class="d-flex"
                        style="height:250px; overflow-x:auto"
                        ref="drawContainer"
                    >
                        <!-- 포켓몬 -->
                        <v-sheet
                            v-for="(item, index) in card_list_draw" :key="index"
                            class="pa-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <!-- {{item.name_kr}} -->
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                        v-on="{ ...menu }"
                                                    width="144"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{index+14}}</p>
                                                </v-sheet>
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                        :key="componentKey"
                                    />
                                </v-menu>
                            </div>
                        </v-sheet>
                    </div>

                    <div
                        class="mt-6 d-flex justify-center"
                    >
                        <v-select
                            style="width:120px;"
                            class="shrink rounded-20"
                            :items="select_list.draw_amount"
                            outlined
                            item-text="name"
                            item-value="value"
                            v-model="draw_amount"
                        ></v-select>

                        <v-btn
                            height="56"
                            depressed
                            dark
                            class="ml-3 rounded-15"
                            style="font-size:18px; font-weight:bold;"
                            color="#F8468D"
                            width="142"
                            @click="draw()"
                        >
                            드로우
                        </v-btn>
                    </div>
                </v-sheet>

                <div
                    class="d-flex justify-center mt-8"
                >
                    <v-btn
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#23d2e2"
                        width="142"
                        height="49"
                        @click="reset_deck_test()"
                    >
                        다시 뽑기
                    </v-btn>
                    <v-btn
                        v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#B3B3C2"
                        width="142"
                        height="49"
                        @click="dialog.deck_test = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 덱 복사 (dialog) -->
        <v-dialog
            v-model="dialog.copy"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                color="#F8F7FB"
                class="pa-5"
                width="300"
                height="200"
            >
                <p
                    class="text-center mb-4"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    덱 복사하기
                </p>

                <div
                    class="d-flex mb-5"
                    style="width:100%;"
                >
                    <v-sheet
                        width="50%"
                        color="transparent"
                        class="text-center mobile"
                    >
                        <p style="font-size:11pt; margin-bottom:4px; color:#615DFA;">원본 덱 국가</p>
                        <v-text-field
                            class="rounded-20"
                            height="34"
                            style="background:white;"
                            hide-details
                            dense
                            outlined
                            readonly
                            v-model="computed_nation"
                        ></v-text-field>
                    </v-sheet>
                    <v-sheet
                        color="transparent"
                        class="d-flex justify-center align-end pb-2 mx-1"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-sheet>
                    <v-sheet
                        width="50%"
                        color="transparent"
                        class="text-center mobile"
                    >
                        <p style="font-size:11pt; margin-bottom:4px; color:#615DFA;">복사할 덱 국가</p>
                        <v-select
                            class="rounded-20"
                            height="34"
                            style="background:white;"
                            hide-details
                            dense
                            outlined
                            :items="nation_list"
                            v-model="copy_nation"
                        ></v-select>
                    </v-sheet>
                </div>

                <div class="d-flex justify-center">
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-weight:bold;"
                        color="#615DFA"
                        width="107"
                        height="36"
                        :to="'/deck/copy?id='+this.$route.query.id+'&nation='+this.copy_nation"
                    >
                        복사하기
                    </v-btn>
                    <v-btn
                        v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-weight:bold;"
                        color="#B3B3C2"
                        width="107"
                        height="36"
                        @click="dialog.copy = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.summary"
            content-class="rounded-xl"
        >
            <v-sheet
                class="px-4 py-4"
                style="position:relative;"
            >
                <v-icon style="position:absolute; top:18px; right:16px;" size="24" color="black" @click="dialog.summary = false">mdi-close</v-icon>
                <p
                    class="text-subtitle-1 font-weight-bold mb-0 ml-1"
                >
                    {{deck.title}}
                </p>
                <p
                    class="text-caption mb-0 ml-1"
                >
                    {{deck.season}}
                </p>

                <!-- 포켓몬 -->
                <v-row
                    class="mt-4"
                    no-gutters
                >
                    <v-col
                        v-for="item in card_list" :key="'중복'+item.id"
                        class="px-1"
                        cols="2"
                    >
                        <div
                            class="d-flex"
                        >
                            <!-- 이미지 -->
                            <v-card
                                class="rounded-lg elevation-0"
                                style="overflow:hidden;"
                            >
                                <v-card
                                    class="rounded-lg elevation-0 mb-3"
                                    style="position:relative;"
                                >
                                    <v-img
                                        width="80"
                                        class="rounded-lg"
                                        position="center 30%"
                                        :src="view_image(item)"
                                    ></v-img>
                                    <v-sheet
                                        style="position:absolute; bottom:-6px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                        width="24"
                                        height="12"
                                        class="d-flex align-center justify-center mx-auto rounded-20"
                                    >
                                        <p
                                            style="font-size:8px; font-weight:bold; color:#424361;"
                                            class="mb-0 text-center mt-2px"
                                        >{{item.amount}}</p>
                                    </v-sheet>
                                </v-card>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Header from '../components/Header'
import Avatar from '@/components/AvatarForDeck'
import TipTapReader from "@/components/tiptap/Reader"
import PointModule from '@/components/PointModule.js'
import Review from "./ReviewMobile"
import Detail from '@/components/card/detailForBoard/Detail'
import BannerView from '@/components/BannerView'
import html2canvas from 'html2canvas'

export default {
    components: {
        Header,
        Avatar,
        TipTapReader,
        PointModule,
        Review,
        Detail,
        BannerView
    },

    data: () => ({
        select_list: {
            view_type: [
                "기본",
                "이미지(전체)",
                "이미지(중복)",
                "표(카드포함)",
                "표(텍스트만)"
            ],

            nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ],

            // 포켓몬카드 정렬
            sort: [
                "기본",
                "카드번호순",
                "HP순",
                "HP(역순)"
            ],

            draw_amount: [
                { name: "1장", value: 1 },
                { name: "2장", value: 2 },
                { name: "3장", value: 3 },
                { name: "4장", value: 4 },
                { name: "5장", value: 5 },
                { name: "6장", value: 6 },
                { name: "7장", value: 7 },
                // { name: "전부", value: 60 },
            ],

            renewal: {
                en: [],
                kr: [],
                jp: []
            },
        },

        // 덱 기본 정보
        deck: {
            title: "",
            writer: "",
            image: "",
            image_en: "",
            image_kr: "",
            image_jp: "",
            writer_nickname: "",
            regulation_group: "",
            deck_nation: "",
            size: "",
            deck_code: "",
            status: "",
            created: "",
            view_count: 0,
            like_count: 0,
            copy_count: 0,
            rating_avg: 0,
            rating_count: 0,
            tag: "",
            original_id: null
        },

        original_deck: {
            title: "",
            writer_nickname: "",
            regulation_group: "",
            deck_nation: "",
            size: "",
            deck_code: "",
            status: "",
            created: "",
        },

        nation_list: [
            {
                text: "국내판",
                value: "kr"
            },
            {
                text: "북미판",
                value: "en"
            },
            {
                text: "일본판",
                value: "jp"
            }
        ],

        copy_nation: "",


        view_type: "기본",
        nation: "",
        sort: "기본",

        // 덱 카드 목록
        card_list: [],

        // 덱 속성 목록
        type_list: [],

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        standard_list: [],

        dialog: {
            deck_test: false,
            copy:false,
            export: false,
            summary: false
        },

        draw_amount: 1,

        card_list_hand: [],
        card_list_prize: [],
        card_list_draw: [],

        like_list: [],

        componentKey: 0,

        supertype: {
            Energy: [],
            Pokémon: [],
            Trainer: []
        },

        is_exist:false,
        deck_export_text: "",

        loading: false
    }),

    computed: {
        // 재수록카드 목록
        renewal_list(){
            switch(this.nation){
                case 'en' : return this.select_list.renewal.en
                case 'kr' : return this.select_list.renewal.kr
                case 'jp' : return this.select_list.renewal.jp
            }
        },

        // 복사하기 기존 nation 표시
        computed_nation(){
            if(this.deck.deck_nation == "kr")
            {
                return "국내판"
            }
            else if(this.deck.deck_nation == "en")
            {
                return "북미판"
            }
            else if(this.deck.deck_nation == "jp")
            {
                return "일본판"
            }
        },

        // 선택한 목록 > 포켓몬
        card_list_pokemon() {
            return this.card_list.filter((e) => e.card_supertype == 'Pokémon')
        },

        // 선택한 목록 > 트레이너스
        card_list_trainer() {
            return this.card_list.filter((e) => e.card_supertype == 'Trainer')
        },

        // 선택한 목록 > 에너지
        card_list_energy() {
            return this.card_list.filter((e) => e.card_supertype == 'Energy')
        },

        card_list_amount() {
            return this.card_list.reduce((sum, item) => sum + (item.amount || 1), 0);
        },

        // 카드 보기 형식 > 이미지(전체)
        card_list_total_view(){
            let result = []
            for (let card of this.card_list) {
                for (let i = 0; i < card.amount; i++) {
                    // 객체를 복사하여 결과 배열에 추가
                    result.push({ ...card })
                }
            }
            return result
        },

        card_list_total_view_without_hand(){
            return this.card_list_total_view.filter(item => !this.card_list_hand.includes(item))
        },

        card_list_total_view_without_hand_prize(){
            return this.card_list_total_view_without_hand.filter(item => !this.card_list_prize.includes(item))
        },

        card_list_total_view_without_hand_prize_draw(){
            return this.card_list_total_view_without_hand_prize.filter(item => !this.card_list_draw.includes(item))
        },
    },

    watch: {
        sort(){
            this.sortList()
        },

        nation(){
            this.deck.deck_nation = this.nation
        }
    },

    mounted(){
        // 덱 추천한 사람 목록 불러오기
        this.loadDeckLikeList()

        // 덱 정보 불러오기
        this.loadDeck()

        // 덱 카드 목록 불러오기
        this.loadDeckCardList()

        // 덱 속성 목록 불러오기
        this.loadDeckTypeList()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        this.reset_deck_test()

        this.$http.post("/api/new/deck/update/view_count", {
            params: {
                id: this.$route.query.id
            }
        })

        // 카드종류(supertype)별 subtypes list 불러오기
        this.load_supertype_list()

        // 재수록카드 목록 불러오기
        this.loadRenewalList()
    },

    methods: {
        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },
        
        // PTCGO 마크 추가시 확인용
        checkBasicEnergy(item) {
            try {
                const subtypes = JSON.parse(item.subtypes)
                return item.ptcgoCode != null || (item.supertype == 'Energy' && Array.isArray(subtypes) && subtypes.includes('Basic'))
            } catch (e) {
                return false
            }
        },
        
        // 덱 출력 핸들러
        deckExport_handler(){ 
            // null이 없을 경우
            if("every", this.card_list.every(e => e.ptcgoCode != null))
            {
                this.dialog.export = true
                this.deck_export_text = ""
                this.deck_export_text +=  "Pokémon: " + this.card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for(let card of this.card_list_pokemon)
                {
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"
                this.deck_export_text +=  "Trainer: " + this.card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for(let card of this.card_list_trainer)
                {
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"
                this.deck_export_text +=  "Energy: " + this.card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for(let card of this.card_list_energy)
                {
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"
                this.deck_export_text +=  "Total Cards: " + this.deck.size

                this.$copyText(this.deck_export_text)
            }
            // null이 있을 경우
            else
            {
                alert("PTCGL에 출시 되지 않은 카드가 있어 출력이 불가능합니다.\n다른 카드로 변경해주세요. (카드 우측 하단의 PTCGL 마크를 확인해주세요)")
            }
        },

        convertName(){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            if(text.includes("Energy")){
                text = text.replace("Grass", "{G}")
                text = text.replace("Fire", "{R}")
                text = text.replace("Water", "{W}")
                text = text.replace("Lightning", "{L}")
                text = text.replace("Psychic", "{P}")
                text = text.replace("Fighting", "{F}")
                text = text.replace("Darkness", "{D}")
                text = text.replace("Metal", "{M}")
            }

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        deckExport_copy(){
            // console.log("Hi")
            // console.log(this.deck_export_text)
            // this.$copyText(this.deck_export_text)
            // this.$copyText("HI")
            // this.$copyText(this.deck_export_text)
            alert("PTCGL 덱 코드가 복사되었습니다")
        },

        // 재수록카드 목록 불러오기
        loadRenewalList(){
            // 한글
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })

            // 영어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.renewal.en = res.data.map(a => a.card_id)
            })

            // 일어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })
        },

        copyUrl(){
            this.$copyText(window.location.origin + this.$route.fullPath)
            alert("덱 레시피 주소가 복사 되었습니다")
        },
        
        // 카드종류(supertype)별 subtypes list 불러오기
        load_supertype_list(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },

        // Subtypes 매칭
        computed_subtypes(item){
            console.log(item)
            if(item.supertype == "Energy")
            {
                console.log(this.supertype.Energy.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Energy.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(item.supertype == "Pokémon")
            {
                console.log(this.supertype.Pokémon.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Pokémon.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(item.supertype == "Trainer")
            {
                console.log(this.supertype.Trainer.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Trainer.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
        },

        reset_deck_test(){
            this.loadDeckCardList()
            
            this.card_list_hand = []
            this.card_list_prize = []
            this.card_list_hand = this.getRandomElements(this.card_list_total_view, 7)

            // this.card_list_total_view

            this.card_list_prize = this.getRandomElements(this.card_list_total_view_without_hand, 6)
            this.card_list_draw = []
            this.componentKey++
        },

        getRandomElements(arr, count) {
            // 배열 복사 및 셔플
            const shuffled = [...arr].sort(() => 0.5 - Math.random())
            // 상위 'count' 개의 요소 반환
            return shuffled.slice(0, count)
        },

        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 스탠다드인지 확인
        is_Standard(item){
            if(this.renewal_list.includes(item.id))
            {
                return true
            }
            else
            {
                if(item.supertype == "Energy" && item.subtypes != null)
                {
                    if(JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))
                    {
                        return true
                    }
                    else
                    {
                        if(this.standard_list.includes(item.regulationMark))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                }
                else
                {
                    if(this.standard_list.includes(item.regulationMark))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
            }
        },
        
        // 이미지 표시
        view_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },

        // 덱 정보 불러오기
        loadDeck(){
            this.$http.post("/api/new/deck/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.is_exist = true
                }

                this.deck = res.data[0]
                this.options.content = res.data[0].content
                this.nation = this.deck.deck_nation

                this.deck.image = this.computed_image(this.deck)

                if(this.deck.status == "비공개" && this.deck.writer != this.$store.state.user_id && this.$store.state.type != "관리자")
                {
                    alert("비공개된 덱은 작성자 외에는 볼 수 없습니다.")
                    this.$router.push("/deck/list")
                }

                if(this.deck.original_id != null)
                {
                    this.$http.post("/api/new/deck/select/specific", {
                        params: {
                            id: this.deck.original_id
                        }
                    }).then((res) => {
                        // console.log(res)
                        if(res.data.length){
                            this.original_deck = res.data[0]
                        }
                    })
                }
            })
        },

        // 덱 정보 다시 불러오기
        reloadDeck(){
            this.$http.post("/api/new/deck/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                console.log(res)
                this.deck = res.data[0]
                this.options.content = res.data[0].content
                this.deck.image = this.computed_image(this.deck)
            })
        },

        // 덱 카드 목록 불러오기
        loadDeckCardList(){
            this.loading = true
            this.$http.post("/api/new/deck/select/card", {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                console.log("card_list", res)
                console.log("card_list.subtypes", res.data.map(e => e.subtypes))
                this.card_list = res.data
                this.sortList()
                this.loading = false
            })
        },

        // 덱 속성 목록 불러오기
        loadDeckTypeList(){
            this.$http.post("/api/new/deck/select/type", {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                this.type_list = res.data
            })
        },

        // 덱 복사하기
        copyDeck(){
            this.$router.push("/deck/copy?id="+this.$route.query.id)
        },

        // 덱 수정하기
        updateDeck(){
            this.$router.push("/deck/update?id="+this.$route.query.id+"&nation="+this.deck.deck_nation)
        },

        // 덱 삭제하기
        deleteDeck(){
            if(confirm("정말 해당 덱을 삭제하시겠습니까?")){
                // 포인트 적립 취소
                if(this.deck.status == "공개"){
                    PointModule.amountCheck('덱 레시피', '공개용으로 덱레시피 만들기').then((res) => {
                        PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                        PointModule.Record(this.$store.state.user_id, '덱 레시피', '공개용으로 덱레시피 만들기', this.deck.title, -res.data[0].amount)
                    })
                }
                this.$http.post("/api/new/deck/delete", {
                    params: {
                        id: this.$route.query.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("덱이 삭제되었습니다.")
                        this.$router.push("/deck/list")
                    }
                })
            }
        },

        // 드로우
        draw(){
            //  this.card_list_draw.push this.getRandomElements(this.card_list_total_view, this.draw_amount)
            if(parseInt(this.card_list_draw.length) + parseInt(this.draw_amount) <= parseInt(this.deck.size) - 13){
                this.card_list_draw = this.card_list_draw.concat(this.getRandomElements(this.card_list_total_view_without_hand_prize_draw, this.draw_amount))

                // 스크롤 우측 끝으로 이동 (Ref 참조)
                this.$nextTick(() => {
                    setTimeout(() => {
                        const container = this.$refs.drawContainer;
                        container.scrollLeft = container.scrollWidth;
                    }, 50)  // 50ms 지연 후 실행
                })

            }else{
                alert("덱 총 수량 이상으로 드로우하실 수 없습니다")
            }
        },

        // 추천한 사람 리스트 가져오기
        loadDeckLikeList(){
            this.$http.post('/api/new/deck/like/select', {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                this.like_list = res.data.map(a => a.nickname)
            })
        },

        // 추천
        like(){
            if(!this.$store.state.is_logined){
                alert("덱 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.user_id==this.deck.writer){
                alert('자신의 덱에 추천을 할 수 없습니다.')
            }else{
                if(this.like_list.includes(this.$store.state.nickname)){
                    alert("이미 추천한 덱입니다.")
                }else{
                    this.$http.post('/api/new/deck/like/insert', {
                        params: {
                            deck_id: this.$route.query.id,
                            user_id: this.$store.state.user_id
                        }
                    }).then((res) => {
                        if(res.status=='200'){
                            alert("덱이 추천되었습니다")

                            // 포인트 적립
                            PointModule.amountCheck('덱 레시피', '덱 추천').then((res) => {
                                PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                PointModule.Record(this.$store.state.user_id, '덱 레시피', '덱 추천', this.deck.title, res.data[0].amount)
                            })

                            // 추천한 사람 리스트 가져오기
                            this.$http.post('/api/new/deck/like/select', {
                                params: {
                                    deck_id: this.$route.query.id
                                }
                            }).then((res) => {
                                this.like_list = res.data.map(a => a.nickname)
                            })
                        }
                    })
                }
            }   
        },

        // 정렬
        sortList(){
            if(this.sort == "기본")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => a.table_id - b.table_id),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
            else if(this.sort == "카드번호순")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => new Date(a.number_kr).getTime() - new Date(b.number_kr).getTime()),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
            else if(this.sort == "HP순")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => b.hp - a.hp),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
            else if(this.sort == "HP(역순)")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => a.hp - b.hp),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
        },

        // 캡처
        capture(){
            const captureElement = document.getElementById('capture')

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = this.deck.title
                a.click()
            })
        }
    }
}
</script>
<style scoped>
/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

::v-deep .v-input__icon--clear {
    padding-top:16px !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}

.gradient-overlay-expanded {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #ff521c, transparent);
  position: relative;
}

.gradient-overlay-standard {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #232323, transparent);
  position: relative;
}


/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 36px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 36px;
    height:36px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 36px;
    min-height: 36px;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 36px !important;
    height: 36px !important;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 36px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .mobile .v-input--switch .v-label {
    font-size:11px !important;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 36px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 36px;
    height:36px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 36px;
    min-height: 36px;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 36px !important;
    height: 36px !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 36px;
    margin-left: 12px;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #36d6e4 !important;
}
</style>



<style scoped>
/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 36px;
    height: 36px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 36px;
    height: 36px;
}

/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #436361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #36d6e4 !important;
}

</style>

<style scoped>
/* 스타일 반응형 성공 */

/* v-autocomplete 핵심 */
/* V-select, v-text-field 핵심 */
/* ::v-deep .mobile .v-select .v-input__control .v-input__slot {
    padding-bottom:12px !important;
} */

/* V-select, v-text-field 핵심 */
::v-deep .mobile .v-select .v-select__slot {
    height:36px;
    padding-bottom:6px !important;
}

::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    height: 36px;
}

::v-deep .mobile .v-select fieldset {
    height:36px;
}

/* V-select, v-text-field 핵심 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    padding-bottom:4px !important;
}

::v-deep .mobile .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    border: none;
}

::v-deep .mobile2 .v-input input:focus, .v-input input:active, .v-input textarea:focus, .v-input textarea:active {
    height:400px !important;
}
</style>