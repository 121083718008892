<template>
    <v-sheet class="px-8 mb-6">
        <!-- 평가 목록 -->
        <v-sheet v-for="item in review_list" :key="item.id">
            <!-- 리뷰 상단 -->
            <div class="d-flex mt-2 mx-2">
                <!-- 프로필 & 기능 -->
                <div class="d-flex align-center">
                    <!-- 프로필 -->
                    <Avatar v-if="item.user_id" :user="item.user_id" :color="'black'" />

                    <!-- 닉네임 -->
                    <p class="ml-2 my-0 font-weight-bold" color="#424361" style="font-size:13px; line-height:18px !important;">
                        {{item.nickname}}
                    </p>

                    <!-- 댓글 작성일 -->
                    <font class="ml-2 mr-2" style="font-size:13px; color:#ADAFCA; line-height:18px;">
                        {{new Date(item.created).toLocaleString()}}
                    </font>

                    <!-- 삭제, 수정 -->
                    <div class="d-flex">
                        <!-- 삭제 -->
                        <v-sheet
                            v-if="item.user_id==$store.state.user_id || $store.state.type=='관리자'"
                            title="삭제"
                            width="18"
                            height="18"
                            class="mx-1"
                            style="cursor:pointer;"
                            @click="review_delete(item)"
                        >
                            <v-img contain src="@/assets/board/delete.svg"></v-img>
                        </v-sheet>

                        <!-- 수정 -->
                        <v-sheet
                            v-if="!item.isUpdating && (item.user_id==$store.state.user_id || $store.state.type=='관리자')"
                            title="수정"
                            width="18"
                            height="18"
                            class="mx-1"
                            style="cursor:pointer;"
                            @click="review_update(item)"
                        >
                            <v-img contain src="@/assets/board/update.svg"></v-img>
                        </v-sheet>

                        <!-- 블라인드 처리 -->
                        <v-sheet
                            v-if="item.status!='블라인드' && $store.state.type=='관리자'"
                            title="블라인드 처리"
                            width="18"
                            height="18"
                            class="mx-1"
                            style="cursor:pointer;"
                            @click="review_blind(item)"
                        >
                            <v-img contain src="@/assets/board/blind.svg"></v-img>
                        </v-sheet>
                    </div>
                </div>
                <v-spacer></v-spacer>

                <!-- 평점 -->
                <div class="mt-5">
                    <!-- 성능 평점 -->
                    <div class="d-flex align-center">
                        <p v-if="item.rate_perform_check" class="mt-1 mb-0 mr-2" style="font-size:13px;">평점</p>
                        <v-rating
                            v-if="item.rate_perform_check"
                            v-model="item.rate_perform"
                            :readonly="!item.isUpdating"
                            half-increments
                            background-color="grey"
                            color="#615DFA"
                            size="16"
                            style="height:25px;"
                        ></v-rating>
                    </div>
                </div>
            </div>

            <!-- 리뷰 내용 -->
            <div>
                <!-- 블라인드됨 -->
                <v-sheet 
                    v-if="item.status=='블라인드'"
                    class="py-3"
                >
                    <p class="text-caption text-center my-2">해당 평가는 관리자에 의해 블라인드 처리되었습니다.</p>
                    <v-sheet class="d-flex justify-end">
                        <v-btn
                            v-if="$store.state.type=='관리자'"
                            class="rounded-10 mr-2"
                            color="#23D2E2"
                            small
                            dark
                            depressed
                            @click="review_unblind(item)"
                        >   
                            블라인드 해제
                        </v-btn>
                    </v-sheet>
                </v-sheet>

                <!-- 기본 -->
                <v-sheet v-if="item.status!='블라인드' && !item.isUpdating" class="ml-10 mb-4" width="620">
                    <v-textarea
                        dense
                        hide-details
                        flat
                        solo
                        v-model="item.content"
                        readonly
                        rows="1"
                        style="font-size:13px;"
                        auto-grow
                    ></v-textarea>
                </v-sheet>

                <!-- 수정 시 -->
                <div>
                    <!-- 내용 -->
                    <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1">
                        <v-textarea
                            hide-details
                            flat
                            solo
                            v-model="item.content"
                            placeholder="댓글을 입력해주세요"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 수정, 취소 버튼 -->
                    <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                        <v-btn
                            class="rounded-10"
                            width="90"
                            height="35"
                            dark
                            depressed
                            color="primary"
                            @click="review_update_submit(item)"
                        >
                            수정
                        </v-btn>
                        <v-btn
                            class="rounded-10 ml-2"
                            width="90"
                            height="35"
                            dark
                            depressed
                            color="primary"
                            @click="review_update(item)"
                        >
                            취소
                        </v-btn>
                    </div>
                </div>
            </div>

            <v-divider></v-divider>
        </v-sheet>

        <!-- 평가 달기 -->
        <div class="mt-6">
            <v-sheet class="d-flex align-center justify-center font-weight-medium mb-2">
                <!-- 평점 -->
                <p style="font-size:15px;" class="mt-1 mb-0">이 덱의 평점은?</p>

                <!-- 입력 여부 -->
                <v-checkbox
                    dense
                    hide-details
                    class="ml-2 mb-2px"
                    color="#615DFA"
                    v-model="review.rate_perform_check"
                    :label="review.rate_perform_check? '별점과 댓글 같이 남기기':'댓글만 남기기'"
                    @change="perform_check_changed()"
                ></v-checkbox>

                <!-- 평점 -->
                <v-rating
                    :disable="review.rate_perform_check"
                    :readonly="!review.rate_perform_check"
                    v-model="review.rate_perform"
                    half-increments
                    background-color="grey"
                    color="#615DFA"
                    size="20"
                    style="height:23px;"
                ></v-rating>
            </v-sheet>

            <!-- 평가 내용 -->
            <v-sheet outlined class="d-flex justify-center py-1">
                <v-textarea
                    hide-details
                    flat
                    solo
                    v-model="review.content"
                    placeholder="평가 내용을 입력해주세요"
                ></v-textarea>
            </v-sheet>

            <!-- 제출 -->
            <v-sheet class="d-flex justify-end mt-3">
                <v-btn
                    dark
                    depressed
                    color="primary"
                    class="rounded-10"
                    @click="reviewSubmit()"
                >
                    댓글 쓰기
                </v-btn>
            </v-sheet>
        </div>
    </v-sheet>
</template>
<script>
import PointModule from '@/components/PointModule.js'
import Avatar from "@/components/Avatar"

export default {
    components: {
        PointModule,
        Avatar
    },

    props: ["deck_id", "text_nation", "deck"],

    data: () => ({
        review_list: [],

        review: {
            rate_perform_check: false,
            rate_perform: 0,
            content: ""
        },
    }),

    mounted(){
        // 현재 카드 평점
        this.load_sum()
    },

    methods: {
        // 현재 카드 평점
        load_sum(){
            // 리뷰 목록
            this.$http.post('/api/new/deck/review/list', {
                params: {
                    deck_id: this.$route.query.id,
                }
            }).then(res => {
                this.review_list = res.data
            })

            this.$emit("Updated")
        },

        // 리뷰 수정 모드 스위치
        review_update(item){
            item.isUpdating = !item.isUpdating
        },

        // 리뷰 수정
        review_update_submit(item){
            this.$http.post('/api/new/deck/review/update', {
                params: {
                    deck_id: this.$route.query.id,
                    writer: this.$store.state.user_id,
                    rate_perform_check: item.rate_perform_check,
                    rate_perform: item.rate_perform,
                    content: item.content,
                    id: item.id
                }
            }).then(() => {
                this.load_sum()
            })
        },

        // 리뷰 삭제
        review_delete(item){
            if(confirm("해당 리뷰를 삭제하시겠습니까?")){
                this.$http.post('/api/new/deck/review/delete', {
                    params: {
                        id: item.id
                    }
                }).then(res => {
                    // 포인트 적립 취소
                    PointModule.amountCheck('덱 레시피', '댓글').then((res) => {
                        PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                        PointModule.Record(this.$store.state.user_id, '덱 레시피', '댓글', this.deck.title, -res.data[0].amount)
                    })

                    this.load_sum()
                })
            }
        },

        // 리뷰 블라인드
        review_blind(item){
            this.$http.post('/api/new/deck/review/blind', {
                params: {
                    id: item.id
                }
            }).then(res => {
                this.load_sum()
                alert("리뷰가 블라인드되었습니다.")
            })
        },

        // 리뷰 블라인드 해제
        review_unblind(item){
            this.$http.post('/api/new/deck/review/unblind', {
                params: {
                    id: item.id
                }
            }).then(res => {
                this.load_sum()
                alert("블라인드 해제되었습니다.")
            })
        },

        // 리뷰 입력
        reviewSubmit(){
            if(!this.$store.state.user_id){
                alert("덱 평가는 로그인 이후에만 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 덱레시피 리뷰 작성이 제한됩니다.')
            }
            else{
                this.$http.post('/api/new/deck/review/insert', {
                    params: {
                        deck_id: this.$route.query.id,
                        writer: this.$store.state.user_id,
                        rate_perform_check: this.review.rate_perform_check,
                        rate_perform: this.review.rate_perform,
                        content: this.review.content
                    }
                }).then(() => {
                    // 포인트 적립
                    PointModule.amountCheck('덱 레시피', '댓글').then((res) => {
                        PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                        PointModule.Record(this.$store.state.user_id, '덱 레시피', '댓글', this.deck.title, res.data[0].amount)
                    })

                    this.load_sum()
                })

                // 입력란 초기화
                this.review = {
                    rate_perform_check: false,
                    rate_perform: 0,
                    content: ""
                }
            }
        },

        // 리뷰 성능 평점 상태 변경
        perform_check_changed(){
            if(this.review.rate_perform_check && this.deck.writer == this.$store.state.user_id){
                alert("자신의 덱에 평점을 남길 수 없습니다.")
                this.review.rate_perform_check = 0
            }

            if(!this.review.rate_perform_check){
                this.review.rate_perform = 0
            }
        },

        // 리뷰 수정하기 > 성능 평점 상태 변경
        perform_check_changed(item){
            if(item.rate_perform_check && this.deck.writer == this.$store.state.user_id){
                alert("자신의 덱에 평점을 남길 수 없습니다.")
                item.rate_perform_check= 0
            }

            if(!item.rate_perform_check){
                item.rate_perform = 0
            }
        },
    }
}
</script>
<style scoped>
.shadow{
    box-shadow: 0px 0px 20px 10px #F1F2F6 !important;
}
</style>