<template>
    <div>
        <!-- 상단 (PC) -->
        <Header
            v-if="!$vuetify.breakpoint.mobile"
            @deckExport="deckExport_handler"
        />

        <v-sheet
            v-if="!is_exist"
            height="400"
            class="rounded-15"
            style="padding-top:130px;"
        >
            <v-col class="text-center">
                <v-icon x-large color="blue" class="mb-4">mdi-close-octagon-outline</v-icon>
                <p class="blue--text">존재하지 않는 덱 레시피 입니다</p>
            </v-col>
        </v-sheet>
    
        <div
            v-if="is_exist"
        >
            <!-- 덱 -->
            <div
                id="capture"
                class="rounded-15 mb-3 white"
                style="padding:30px 30px;"
            >
                <div
                    class="py-4 d-flex justify-space-between"
                >
                    <div
                        class="d-flex"
                    >
                        <!-- 이미지 -->
                        <v-card
                            class="rounded-15 elevation-0"
                            style="overflow:hidden;"
                        >
                            <v-card
                                class="rounded-15 elevation-0 mb-6"
                                style="position:relative;"
                            >
                                <v-img
                                    height="100"
                                    width="267"
                                    class="rounded-15"
                                    position="center 30%"
                                    :src="deck.image"
                                >
                                    <v-sheet
                                        v-if="deck.like_count >= 10"
                                        width="64"
                                        height="24"
                                        class="font-weight-bold rounded-15"
                                        style="background:rgba(255,255,255,0.6); text-align:center; position:absolute; right:8px; top:8px;"
                                    >
                                        <span style="color:#424361; font-size:14px;">인기덱</span>
                                    </v-sheet>
                                </v-img>
                                <v-sheet
                                    style="position:absolute; bottom:-12px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                    width="180"
                                    height="32"
                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                >
                                    <Avatar
                                        v-if="deck.writer"
                                        :user="deck.writer"
                                        :color="'black'"
                                    />
                                    <p
                                        style="margin-left:-4px; font-size:15px; font-weight:bold; color:#424361;"
                                        class="mb-0 mr-4 mt-1px"
                                    >{{deck.writer_nickname}}</p>
                                </v-sheet>
                            </v-card>
                        </v-card>

                        <div
                            class="ml-10 pr-4"
                            style="position:relative;"
                        >
                            <p
                                style="font-size:16px; font-weight:medium; position:absolute; top:-20px;"
                                class="mb-2"
                            >
                                <span v-if="deck.deck_name!=null && deck.deck_name_kr==null">{{deck.deck_name}}</span>
                                <span v-if="deck.deck_name!=null && deck.deck_name_kr!=null">{{deck.deck_name_kr}}</span>
                            </p>
                            <p
                                style="font-size:30px; font-weight:bold;"
                                class="mb-2"
                            >
                                {{deck.title}}
                            </p>
                            <div
                                class="d-flex align-center"
                            >
                            </div>
                            <p
                                class="d-flex align-center mb-1"
                                style="font-size:16px;"
                            >
                                <v-icon size="20">mdi-clock-outline</v-icon><span class="ml-1">{{new Date(deck.created).toLocaleDateString().slice(0, -1).replaceAll(". ", "-")}}</span>
                                <v-icon size="20" class="ml-4">mdi-eye-outline</v-icon><span class="ml-1">{{deck.view_count}}</span>
                                <v-icon size="20" class="ml-4">mdi-thumb-up-outline</v-icon><span class="ml-1">{{like_list.length}}</span>
                                <v-icon size="20" class="ml-4">mdi-content-copy</v-icon><span class="ml-1">{{deck.copy_count}}</span>
                            </p>

                            <div
                                class="d-flex align-center"
                            >
                                <v-rating
                                    :value="deck.rating_avg"
                                    half-increments
                                    readonly
                                    background-color="grey"
                                    color="#615DFA"
                                    size="24"
                                ></v-rating>
                                <p
                                    style="font-size:19px;"
                                    class="ml-2 mb-0"
                                >
                                    평점 {{deck.rating_avg}} <span style="color:#615DFA">({{deck.rating_count}})</span>
                                </p>

                                <p
                                    class="icu_purple--text text-caption font-weight-medium mb-0 ml-4"
                                    style="cursor:pointer;"
                                    @click="dialog.summary = true"
                                >
                                    <v-icon size="16" class="pb-2px" color="icu_purple">mdi-open-in-new</v-icon>
                                    한 화면에 모든 카드 보기
                                </p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <v-select
                            height="44"
                            style="font-size:18px; width:188px;"
                            class="shrink rounded-15 font-weight-bold mr-3 mb-3"
                            label="카드보기 형식"
                            dense
                            outlined
                            hide-details
                            :items="select_list.view_type"
                            v-model="view_type"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-3 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>

                        <v-select
                            height="44"
                            style="font-size:18px; width:188px;"
                            class="shrink rounded-15 font-weight-bold mr-3"
                            label="국가보기 변경"
                            dense
                            outlined
                            hide-details
                            :items="select_list.nation"
                            v-model="nation"
                            item-text="name"
                            item-value="value"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-3 pt-1">{{item.name}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>

                        <!-- <v-select
                            height="44"
                            style="font-size:18px; width:188px;"
                            class="shrink rounded-15 font-weight-bold mr-3"
                            label="포켓몬카드 정렬"
                            dense
                            outlined
                            hide-details
                            :items="select_list.sort"
                            v-model="sort"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-3 pt-1">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select> -->
                    </div>
                </div>

                <v-divider class="mb-4"></v-divider>

                <!-- 기본 -->
                <div
                    v-if="view_type == '기본' && !loading"
                >
                    <div
                        class="d-flex pa-4 mb-10"
                    >
                        <!-- 포켓몬 -->
                        <div
                            style="margin-right:80px;"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#F8468D;"
                            >
                                포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <v-sheet
                                v-for="item in card_list_pokemon" :key="item.id"
                                class="mt-1"
                                width="245"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                height="50"
                                                position="center 30%"
                                                style="position:relative;"
                                                :src="view_image(item)"
                                            >
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                                <div
                                                    class="d-flex"
                                                    :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                    style="position:relative;"
                                                >
                                                    <p
                                                        v-if="!is_Standard(item)"
                                                        style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                    >
                                                        익스펜디드
                                                    </p>
                                                    <v-sheet
                                                        height="34"
                                                        width="34"
                                                        class="rounded-circle text-center ma-2" 
                                                        style="background:#f8468d"
                                                    >
                                                        <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                    </v-sheet>
                                                    <div
                                                        class="pl-2 mr-1 mt-1 text-truncate"
                                                        style="width:184px;"
                                                    >
                                                        <p
                                                            style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:14px; margin-bottom:0px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:9px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>

                        <!-- 트레이너스 -->
                        <div
                            style="margin-right:80px;"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#F8468D;"
                            >
                                트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <v-sheet
                                v-for="item in card_list_trainer" :key="item.id"
                                class="mt-1"
                                width="245"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                height="50"
                                                position="center 30%"
                                                style="position:relative;"
                                                :src="view_image(item)"
                                            >
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                                <div
                                                    class="d-flex"
                                                    :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                    style="position:relative;"
                                                >
                                                    <p
                                                        v-if="!is_Standard(item)"
                                                        style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                    >
                                                        익스펜디드
                                                    </p>
                                                    <v-sheet
                                                        height="34"
                                                        width="34"
                                                        class="rounded-circle text-center ma-2" 
                                                        style="background:#f8468d"
                                                    >
                                                        <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                    </v-sheet>
                                                    <div
                                                        class="pl-2 mr-1 mt-1 text-truncate"
                                                        style="width:184px;"
                                                    >
                                                        <p
                                                            style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:14px; margin-bottom:0px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:9px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>

                        <!-- 에너지 -->
                        <div
                            style="margin-right:80px;"
                        >
                            <p
                                class="mb-2"
                                style="font-size:15px; color:#F8468D;"
                            >
                                에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                            </p>
                            <v-sheet
                                v-for="item in card_list_energy" :key="item.id"
                                class="mt-1"
                                width="245"
                            >
                                <div
                                    class="d-flex"
                                >
                                    <v-menu
                                        offset-x
                                        right
                                        open-on-hover
                                        nudge-right="0"
                                        max-width="320"
                                        content-class="rounded-15"
                                        :close-on-content-click="true"
                                    >
                                        <template v-slot:activator="{ on: menu }">
                                            <v-img
                                                v-on="{ ...menu }"
                                                width="258"
                                                height="50"
                                                position="center 30%"
                                                style="position:relative;"
                                                :src="view_image(item)"
                                            >
                                                <v-img
                                                    v-if="checkBasicEnergy(item)"
                                                    style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                    class="d-flex"
                                                    width="26"
                                                    height="22"
                                                    src="@/assets/tcg.png"
                                                ></v-img>
                                                <div
                                                    class="d-flex"
                                                    :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                                    style="position:relative;"
                                                >
                                                    <p
                                                        v-if="!is_Standard(item)"
                                                        style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                                    >
                                                        익스펜디드
                                                    </p>
                                                    <v-sheet
                                                        height="34"
                                                        width="34"
                                                        class="rounded-circle text-center ma-2" 
                                                        style="background:#f8468d"
                                                    >
                                                        <span style="line-height:34px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                                    </v-sheet>
                                                    <div
                                                        class="pl-2 mr-1 mt-1 text-truncate"
                                                        style="width:184px;"
                                                    >
                                                        <p
                                                            style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:14px; margin-bottom:0px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                                        </p>
                                                        <p
                                                            style="font-size:9px;"
                                                        >
                                                            <span v-if="nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                            <span v-if="nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                            <span v-if="nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </v-img>
                                        </template>
                                        <Detail
                                            :id="item.card_id"
                                            :isBoardContent="true"
                                            :nation="nation"
                                        />
                                    </v-menu>
                                </div>
                            </v-sheet>
                        </div>


                    </div>
                    <p
                        class="text-end mb-2 pr-2"
                    >
                        덱 예상 가격 : {{$toComma(card_list.reduce((sum, card) => sum + (card.price || 0), 0))}}원
                        <v-tooltip top color="primary" content-class="rounded-15">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="ml-1"
                                    color="grey lighten-1"
                                    size="20"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <div class="white--text text-center pa-1">
                                각 카드별 마지막 거래가의 총 합산 가격으로<br/>
                                정확하지 않을 수 있으니 참고만 바랍니다
                            </div>
                        </v-tooltip>
                    </p>

                    <div
                        class="d-flex justify-space-between pr-2"
                    >
                        <div
                            class="px-4"
                        >
                            {{deck.regulation_group}}

                            {{deck.size}}장

                            {{deck.deck_code}}
                        </div>
                        <div
                            class="d-flex"
                        >
                            <div
                                v-if="type_list.filter(item => item.type === 'Grass').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                                {{type_list.filter(item => item.type === 'Grass').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Fire').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                                {{type_list.filter(item => item.type === 'Fire').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Water').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                                {{type_list.filter(item => item.type === 'Water').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Lightning').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                                {{type_list.filter(item => item.type === 'Lightning').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Psychic').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                                {{type_list.filter(item => item.type === 'Psychic').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Fighting').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                                {{type_list.filter(item => item.type === 'Fighting').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Darkness').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                                {{type_list.filter(item => item.type === 'Darkness').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Metal').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                                {{type_list.filter(item => item.type === 'Metal').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Fairy').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                                {{type_list.filter(item => item.type === 'Fairy').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Dragon').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                                {{type_list.filter(item => item.type === 'Dragon').length}}
                            </div>
                            <div
                                v-if="type_list.filter(item => item.type === 'Colorless').length"
                                class="d-flex align-center ml-3"
                            >
                                <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                                {{type_list.filter(item => item.type === 'Colorless').length}}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 이미지(전체) -->
                <div
                    v-if="view_type == '이미지(전체)' && !loading"
                    class="mb-10"
                >
                    <div
                        class="d-flex align-center pr-2"
                    >
                        <p
                            class="mb-2"
                            style="font-size:15px; color:#424361;"
                        >
                            포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9"
                            style="font-size:15px; color:#424361;"
                        >
                            트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9 mr-10"
                            style="font-size:15px; color:#424361;"
                        >
                            에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>

                        <div
                            v-if="type_list.filter(item => item.type === 'Grass').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                            {{type_list.filter(item => item.type === 'Grass').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fire').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                            {{type_list.filter(item => item.type === 'Fire').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Water').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                            {{type_list.filter(item => item.type === 'Water').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Lightning').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                            {{type_list.filter(item => item.type === 'Lightning').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Psychic').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                            {{type_list.filter(item => item.type === 'Psychic').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fighting').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                            {{type_list.filter(item => item.type === 'Fighting').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Darkness').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                            {{type_list.filter(item => item.type === 'Darkness').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Metal').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                            {{type_list.filter(item => item.type === 'Metal').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fairy').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                            {{type_list.filter(item => item.type === 'Fairy').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Dragon').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                            {{type_list.filter(item => item.type === 'Dragon').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Colorless').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                            {{type_list.filter(item => item.type === 'Colorless').length}}
                        </div>

                        <v-spacer></v-spacer>

                        <p
                            class="text-end mb-2 pr-2"
                        >
                            <span>덱 예상 가격 : {{$toComma(card_list.reduce((sum, card) => sum + (card.price || 0), 0))}}원</span>
                            <v-tooltip top color="primary" content-class="rounded-15">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ml-1"
                                        color="grey lighten-1"
                                        size="20"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-help-circle-outline
                                    </v-icon>
                                </template>
                                <div class="white--text text-center pa-1">
                                    각 카드별 마지막 거래가의 총 합산 가격으로<br/>
                                    정확하지 않을 수 있으니 참고만 바랍니다
                                </div>
                            </v-tooltip>

                            <!-- <span class="ml-4">{{deck.deck_code}}</span> -->
                        </p>

                    </div>

                    <!-- 포켓몬 -->
                    <v-row
                        no-gutters
                    >
                        <v-col
                            v-for="(item, index) in card_list_total_view" :key="'전체'+ index"
                            class="pa-1"
                            cols="2"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <v-img
                                            v-on="{ ...menu }"
                                            width="258"
                                            :src="view_image(item)"
                                        ></v-img>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                    />
                                </v-menu>
                            </div>
                        </v-col>
                    </v-row>
                    <div
                        class="px-4 mt-4 text-end"
                    >
                        {{deck.regulation_group}}

                        {{deck.size}}장

                        {{deck.deck_code}}
                    </div>
                </div>

                <!-- 이미지(중복) -->
                <div
                    v-if="view_type == '이미지(중복)' && !loading"
                    class="mb-10"
                >
                    <div
                        class="d-flex align-center pr-2"
                    >
                        <p
                            class="mb-2"
                            style="font-size:15px; color:#424361;"
                        >
                            포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9"
                            style="font-size:15px; color:#424361;"
                        >
                            트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9 mr-10"
                            style="font-size:15px; color:#424361;"
                        >
                            에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>

                        <div
                            v-if="type_list.filter(item => item.type === 'Grass').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                            {{type_list.filter(item => item.type === 'Grass').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fire').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                            {{type_list.filter(item => item.type === 'Fire').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Water').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                            {{type_list.filter(item => item.type === 'Water').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Lightning').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                            {{type_list.filter(item => item.type === 'Lightning').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Psychic').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                            {{type_list.filter(item => item.type === 'Psychic').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fighting').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                            {{type_list.filter(item => item.type === 'Fighting').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Darkness').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                            {{type_list.filter(item => item.type === 'Darkness').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Metal').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                            {{type_list.filter(item => item.type === 'Metal').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fairy').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                            {{type_list.filter(item => item.type === 'Fairy').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Dragon').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                            {{type_list.filter(item => item.type === 'Dragon').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Colorless').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                            {{type_list.filter(item => item.type === 'Colorless').length}}
                        </div>

                        <v-spacer></v-spacer>

                        <p
                            class="text-end mb-2 pr-2"
                        >
                            <span>덱 예상 가격 : {{$toComma(card_list.reduce((sum, card) => sum + (card.price || 0), 0))}}원</span>
                            <v-tooltip top color="primary" content-class="rounded-15">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ml-1"
                                        color="grey lighten-1"
                                        size="20"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-help-circle-outline
                                    </v-icon>
                                </template>
                                <div class="white--text text-center pa-1">
                                    각 카드별 마지막 거래가의 총 합산 가격으로<br/>
                                    정확하지 않을 수 있으니 참고만 바랍니다
                                </div>
                            </v-tooltip>

                            <!-- <span class="ml-4">{{deck.deck_code}}</span> -->
                        </p>

                    </div>

                    <!-- 포켓몬 -->
                    <v-row
                        no-gutters
                    >
                        <v-col
                            v-for="item in card_list" :key="'중복'+item.id"
                            class="pa-1"
                            cols="2"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="258"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{item.amount}}</p>
                                                </v-sheet>
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                    />
                                </v-menu>
                            </div>
                        </v-col>
                    </v-row>
                    <div
                        class="px-4 mt-4 text-end"
                    >
                        {{deck.regulation_group}}

                        {{deck.size}}장

                        {{deck.deck_code}}
                    </div>
                </div>

                <!-- 표(카드포함) -->
                <div
                    v-if="view_type == '표(카드포함)' && !loading"
                    class="mb-10"
                >
                    <div
                        class="d-flex align-center pr-2"
                    >
                        <p
                            class="mb-2"
                            style="font-size:15px; color:#424361;"
                        >
                            포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9"
                            style="font-size:15px; color:#424361;"
                        >
                            트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9 mr-10"
                            style="font-size:15px; color:#424361;"
                        >
                            에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>

                        <div
                            v-if="type_list.filter(item => item.type === 'Grass').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                            {{type_list.filter(item => item.type === 'Grass').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fire').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                            {{type_list.filter(item => item.type === 'Fire').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Water').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                            {{type_list.filter(item => item.type === 'Water').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Lightning').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                            {{type_list.filter(item => item.type === 'Lightning').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Psychic').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                            {{type_list.filter(item => item.type === 'Psychic').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fighting').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                            {{type_list.filter(item => item.type === 'Fighting').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Darkness').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                            {{type_list.filter(item => item.type === 'Darkness').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Metal').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                            {{type_list.filter(item => item.type === 'Metal').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fairy').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                            {{type_list.filter(item => item.type === 'Fairy').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Dragon').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                            {{type_list.filter(item => item.type === 'Dragon').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Colorless').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                            {{type_list.filter(item => item.type === 'Colorless').length}}
                        </div>

                        <v-spacer></v-spacer>

                        <p
                            class="text-end mb-2 pr-2"
                        >
                            <span>덱 예상 가격 : {{$toComma(card_list.reduce((sum, card) => sum + (card.price || 0), 0))}}원</span>
                            <v-tooltip top color="primary" content-class="rounded-15">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ml-1"
                                        color="grey lighten-1"
                                        size="20"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-help-circle-outline
                                    </v-icon>
                                </template>
                                <div class="white--text text-center pa-1">
                                    각 카드별 마지막 거래가의 총 합산 가격으로<br/>
                                    정확하지 않을 수 있으니 참고만 바랍니다
                                </div>
                            </v-tooltip>

                            <!-- <span class="ml-4">{{deck.deck_code}}</span> -->
                        </p>

                    </div>

                    <v-data-table
                        id="list"
                        hide-default-header
                        :items="card_list"
                        :footer-props="{
                            'items-per-page-options': [-1],
                            'itemsPerPageText': ''
                        }"
                        hide-default-footer
                    >
                        <template v-slot:header>
                            <tr>
                                <th style="width:80px; padding-left:0px;">이미지</th>
                                <th style="width:400px;">카드</th>
                                <th style="width:120px">타입</th>
                                <th style="width:100px; padding-left:0px; padding:right:0px;">레귤레이션</th>
                                <th style="width:80px">수량</th>
                                <th style="width:120px">가격</th>
                            </tr>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                <td style="width:80px; padding:8px 4px;">
                                    <v-img
                                        v-if="nation=='en'" 
                                        width="48"
                                        class="mx-auto"
                                        :src="'/upload/card_en_image/'+(item.image_en==null? 'default.png':encodeURI(item.image_en))"
                                    ></v-img>
                                    <v-img 
                                        v-if="nation=='kr'"
                                        width="48"
                                        class="mx-auto"
                                        :src="'/upload/card_kr_image/'+(item.image_kr==null? 'default.png':encodeURI(item.image_kr))"
                                    ></v-img>
                                    <v-img 
                                        v-if="nation=='jp'" 
                                        width="48"
                                        class="mx-auto"
                                        :src="'/upload/card_jp_image/'+(item.image_jp==null? 'default.png':encodeURI(item.image_jp))"
                                    ></v-img>
                                </td>
                                <td style="width:100%">
                                    <p v-if="nation=='en'" class="mb-0" style="font-size:18px; font-weight:bold;">{{item.name_en}}</p>
                                    <p v-if="nation=='kr'" class="mb-0" style="font-size:18px; font-weight:bold;">{{item.name_kr}}</p>
                                    <p v-if="nation=='jp'" class="mb-0" style="font-size:18px; font-weight:bold;">{{item.name_jp}}</p>
                                    <p class="mb-0 text-caption">
                                        <font v-if="nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                        <font v-if="nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                        <font v-if="nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                        <font v-if="nation=='en' && item.printedTotal_en"> / {{item.printedTotal_en.replaceAll("\"", "")}}</font>
                                        <font v-if="nation=='kr' && item.printedTotal_kr"> / {{item.printedTotal_kr}}</font>
                                        <font v-if="nation=='jp' && item.printedTotal_jp"> / {{item.printedTotal_jp}}</font>
                                        <span class="ml-2">
                                            <font v-if="nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                                            <font v-if="nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                                            <font v-if="nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                                        </span>
                                    </p>
                                    <p v-if="nation=='en' && item.set_en" class="text-caption mb-0">{{item.set_en}}</p>
                                    <p v-if="nation=='kr' && item.set_kr" class="text-caption mb-0">{{item.set_kr}}</p>
                                    <p v-if="nation=='jp' && item.set_jp" class="text-caption mb-0">{{item.set_jp}}</p>
                                </td>
                                <td style="width:128px">
                                    <font class="d-block text-center" style="min-width:128px; font-size:18px; font-weight:bold;">
                                        <span v-if="item.supertype == 'Pokémon'">포켓몬</span>
                                        <span v-if="item.supertype == 'Trainer'">트레이너스</span>
                                        <span v-if="item.supertype == 'Energy'">에너지</span>
                                    </font>
                                    <font v-if="item.subtypes" class="d-block text-center" style="min-width:128px; font-size:12px;">
                                        {{computed_subtypes(item)}}
                                    </font>
                                </td>
                                <td style="width:100px">
                                    <font class="d-block text-center" style="min-width:100px;">
                                        <v-icon class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                    </font>
                                </td>
                                <td style="width:80px"><font class="d-block text-center" style="min-width:80px;">{{item.amount}}</font></td>
                                <td style="width:120px"><font class="d-block text-center" style="min-width:120px;">{{item.price? $toComma(item.price):0}}원</font></td>
                            </tr>
                        </template>
                    </v-data-table>
                    <div
                        class="px-4 mt-4 text-end"
                    >
                        {{deck.regulation_group}}

                        {{deck.size}}장

                        {{deck.deck_code}}
                    </div>
                </div>

                <!-- 표(텍스트만) -->
                <div
                    v-if="view_type == '표(텍스트만)' && !loading"
                    class="mb-10"
                >
                    <div
                        class="d-flex align-center pr-2"
                    >
                        <p
                            class="mb-2"
                            style="font-size:15px; color:#424361;"
                        >
                            포켓몬({{card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9"
                            style="font-size:15px; color:#424361;"
                        >
                            트레이너스({{card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>
                        <p
                            class="mb-2 ml-9 mr-10"
                            style="font-size:15px; color:#424361;"
                        >
                            에너지({{card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0)}})
                        </p>

                        <div
                            v-if="type_list.filter(item => item.type === 'Grass').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png" />
                            {{type_list.filter(item => item.type === 'Grass').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fire').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png" />
                            {{type_list.filter(item => item.type === 'Fire').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Water').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png" />
                            {{type_list.filter(item => item.type === 'Water').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Lightning').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png" />
                            {{type_list.filter(item => item.type === 'Lightning').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Psychic').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png" />
                            {{type_list.filter(item => item.type === 'Psychic').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fighting').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png" />
                            {{type_list.filter(item => item.type === 'Fighting').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Darkness').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png" />
                            {{type_list.filter(item => item.type === 'Darkness').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Metal').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png" />
                            {{type_list.filter(item => item.type === 'Metal').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Fairy').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png" />
                            {{type_list.filter(item => item.type === 'Fairy').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Dragon').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png" />
                            {{type_list.filter(item => item.type === 'Dragon').length}}
                        </div>
                        <div
                            v-if="type_list.filter(item => item.type === 'Colorless').length"
                            class="d-flex align-center ml-3 mb-2"
                        >
                            <img width="30" height="30" class="mr-1" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png" />
                            {{type_list.filter(item => item.type === 'Colorless').length}}
                        </div>

                        <v-spacer></v-spacer>

                        <p
                            class="text-end mb-2 pr-2"
                        >
                            <span>덱 예상 가격 : {{$toComma(card_list.reduce((sum, card) => sum + (card.price || 0), 0))}}원</span>
                            <v-tooltip top color="primary" content-class="rounded-15">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ml-1"
                                        color="grey lighten-1"
                                        size="20"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-help-circle-outline
                                    </v-icon>
                                </template>
                                <div class="white--text text-center pa-1">
                                    각 카드별 마지막 거래가의 총 합산 가격으로<br/>
                                    정확하지 않을 수 있으니 참고만 바랍니다
                                </div>
                            </v-tooltip>

                            <!-- <span class="ml-4">{{deck.deck_code}}</span> -->
                        </p>

                    </div>

                    <v-data-table
                        id="list"
                        hide-default-header
                        :items="card_list"
                        :footer-props="{
                            'items-per-page-options': [-1],
                            'itemsPerPageText': ''
                        }"
                        hide-default-footer
                    >
                        <template v-slot:header>
                            <tr>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:8px; width:180px;">카드이름</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px;">콜렉션넘버</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:200px">제품명</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:120px">희귀도</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px">타입</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:120px">서브타입</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px">레귤레이션</th>
                                <th style="background:#f7f7f7; padding:8px; font-size:15px; text-align:start; padding-left:0px; width:100px">수량</th>
                            </tr>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                <td class="pl-2">
                                    <p v-if="nation=='en'" class="mb-0" style="">{{item.name_en}}</p>
                                    <p v-if="nation=='kr'" class="mb-0" style="">{{item.name_kr}}</p>
                                    <p v-if="nation=='jp'" class="mb-0" style="">{{item.name_jp}}</p>
                                </td>
                                <td class="pl-0">
                                    <font v-if="nation=='en' && item.number_en">{{item.number_en.replaceAll("\"", "")}}</font>
                                    <font v-if="nation=='kr' && item.number_kr">{{item.number_kr}}</font>
                                    <font v-if="nation=='jp' && item.number_jp">{{item.number_jp}}</font>
                                </td>
                                <td class="pl-0">
                                    <p v-if="nation=='en' && item.set_en" class="text-caption mb-0">{{item.set_en}}</p>
                                    <p v-if="nation=='kr' && item.set_kr" class="text-caption mb-0">{{item.set_kr}}</p>
                                    <p v-if="nation=='jp' && item.set_jp" class="text-caption mb-0">{{item.set_jp}}</p>
                                </td>
                                <td class="pl-0">
                                    <font v-if="nation=='en' && item.rarity_en">{{item.rarity_en}}</font>
                                    <font v-if="nation=='kr' && item.rarity_kr">{{item.rarity_kr}}</font>
                                    <font v-if="nation=='jp' && item.rarity_jp">{{item.rarity_jp}}</font>
                                </td>
                                <td class="pl-0">
                                    <span v-if="item.supertype == 'Pokémon'">포켓몬</span>
                                    <span v-if="item.supertype == 'Trainer'">트레이너스</span>
                                    <span v-if="item.supertype == 'Energy'">에너지</span>
                                </td>
                                <td class="pl-0" v-if="item.subtypes">
                                    {{computed_subtypes(item)}}
                                </td>
                                <td>
                                    <v-icon class="mr-1" v-if="item.regulationMark">mdi-alpha-{{item.regulationMark.toLowerCase().replaceAll("\"", "")}}-box-outline</v-icon>
                                </td>
                                <td class="pl-0">
                                    {{item.amount}}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>

                <!-- 로딩 -->
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                    class="d-flex my-16 mx-auto"
                    size="100"
                ></v-progress-circular>
            </div>

            <!-- 시즌 -->
            <v-sheet
                class="rounded-12 mb-3 d-flex justify-space-between align-center"
                style="padding:8px 40px; position:relative;"
            >
                <p
                    class="mb-0"
                >
                    <font
                        v-if="deck.tag"
                        class="primary--text text-body-2"
                    >
                        {{"#"+deck.tag?.replaceAll(',', ' #')}}
                    </font>
                </p>

                <p
                    class="mb-0 text-body-2"
                >
                    <strong>시즌</strong>
                    <font
                    >
                        {{deck.season}}
                    </font>
                </p>
            </v-sheet>

            <!-- 내용 -->
            <v-sheet
                class="rounded-15 mb-3"
                style="padding:30px 30px; position:relative;"
            >
                <div class="d-flex align-center pl-4 pr-2">
                    <p
                        class="mb-0"
                        style="font-size:22px; font-weight:bold; color:#424361;"
                    >
                        내용
                    </p>

                    <v-spacer></v-spacer>

                    <v-icon color="#ADAFCA" class="mr-2">mdi-information-outline</v-icon>
                    <p
                        class="my-0"
                        style="font-size:14px;"
                    >
                        현재 스탠다드 레귤레이션은 <span class="primary--text font-weight-medium mr-1">{{standard_list.toString()}}</span>입니다.
                    </p>
                </div>

                <!-- 본문 -->
                <TipTapReader
                    v-if="options.content"
                    class="tiptap_style mb-10"
                    :options="options"
                />

                <p
                    v-if="original_deck.id"
                    class="text-center"
                    style="font-size:16px; cursor:pointer;"
                    @click="$router.push('/deck/read?id='+original_deck.id)"
                >
                    이 덱 레시피는 <span style="color:#23D2E2;">{{original_deck.title}}</span>에서 복사된 덱 레시피입니다
                </p>

                <!-- 버튼 (덱 추천, 덱 테스트, 덱 출력) -->
                <div
                    class="d-flex justify-center"
                >
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#615DFA"
                        width="124"
                        height="49" 
                        @click="like()"             
                    >
                        <v-icon size="18" class="mr-2">mdi-thumb-up-outline</v-icon>
                        덱 추천
                    </v-btn>
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 mx-2"
                        style="font-size:18px; font-weight:bold;"
                        color="#1DA1F2"
                        width="137"
                        height="49"      
                        @click="dialog.deck_test = true; reset_deck_test()"          
                    >
                        <v-icon size="18" class="mr-2">mdi-hand-back-left-outline</v-icon>
                        덱 테스트
                    </v-btn>
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#1DA1F2"
                        width="117"
                        height="49"           
                        @click="capture()"
                    >
                        <v-icon size="18" class="mr-2">mdi-export-variant</v-icon>
                        덱 출력
                    </v-btn>
                </div>
            </v-sheet>

            <!-- 댓글 -->
            <v-sheet
                class="rounded-15 mb-3"
                style="padding:30px 30px;"
            >
                <Review
                    :deck="deck"
                    @Updated="reloadDeck"
                />
            </v-sheet>

            <!-- 버튼 (목록, 덱 복사, 덱 수정, 덱 삭제) -->
            <div
                class="d-flex justify-space-between"
            >
                <v-btn
                    depressed
                    dark
                    class="rounded-10"
                    style="font-size:18px; font-weight:bold;"
                    color="#ADAFCA"
                    width="110"
                    height="46"
                    @click="$router.push('/deck/list')"
                >
                    목록
                </v-btn>

                <BannerView
                    v-if="$store.state.ads_banner.deck_recipe"
                    type="deck_recipe"
                    width="360"
                    height="46"
                    :round="'rounded-10'"
                />

                <div>
                    <v-btn
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#615DFA"
                        width="110"
                        height="46"
                        @click="dialog.copy = true"
                    >
                        덱 복사
                    </v-btn>
                    <v-btn
                        v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#FF7A51"
                        width="110"
                        height="46"
                        @click="updateDeck()"
                    >
                        덱 수정
                    </v-btn>
                    <v-btn
                        v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#F8468D"
                        width="110"
                        height="46"
                        @click="deleteDeck()"
                    >
                        덱 삭제
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- 덱 테스트 (dialog) -->
        <v-dialog
            v-model="dialog.deck_test"
            width="1220"
            content-class="rounded-15"
        >
            <v-sheet
                class="rounded-15 py-8 px-12"
                color="#f8f7fb"
            >
                <p
                    class="text-h6 font-weight-bold"
                >
                    덱 테스트
                </p>
                <v-sheet
                    class="rounded-lg py-3 px-5 mb-4"
                >
                    <p
                        class="font-weight-bold"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        첫 손패 7장
                    </p>
                    <div
                        class="d-flex"
                        style="overflow-x:auto"
                    >
                        <!-- 포켓몬 -->
                        <v-sheet
                            v-for="(item, index) in card_list_hand" :key="index"
                            class="pa-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <!-- {{item.name_kr}} -->
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                    v-on="{ ...menu }"
                                                    width="144"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{index+1}}</p>
                                                </v-sheet>
                                                
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                        :key="componentKey"
                                    />
                                </v-menu>
                            </div>
                        </v-sheet>
                    </div>
                </v-sheet>
                <v-sheet
                    class="rounded-lg py-3 px-5 mb-4"
                >
                    <p
                        class="font-weight-bold"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        프라이즈 카드 6장
                    </p>
                    <div
                        class="d-flex"
                        style="overflow-x:auto"
                    >
                        <!-- 포켓몬 -->
                        <v-sheet
                            v-for="(item, index) in card_list_prize" :key="index"
                            class="pa-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <!-- {{item.name_kr}} -->
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                        v-on="{ ...menu }"
                                                    width="144"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{index+8}}</p>
                                                </v-sheet>
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                        :key="componentKey"
                                    />
                                </v-menu>
                            </div>
                        </v-sheet>
                    </div>
                </v-sheet>
                <v-sheet
                    class="rounded-lg py-3 px-5 pb-0"
                    height="412"
                >
                    <p
                        class="font-weight-bold"
                    >
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-1">mdi-circle</v-icon>
                        드로우 카드 ({{card_list_draw.length+13}}/{{deck.size}})
                    </p>
                    <div
                        class="d-flex"
                        style="height:250px; overflow-x:auto"
                        ref="drawContainer"
                    >
                        <!-- 포켓몬 -->
                        <v-sheet
                            v-for="(item, index) in card_list_draw" :key="index"
                            class="pa-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <v-menu
                                    offset-x
                                    right
                                    open-on-hover
                                    nudge-right="0"
                                    max-width="320"
                                    content-class="rounded-15"
                                    :close-on-content-click="true"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <!-- 이미지 -->
                                        <v-card
                                            class="rounded-lg elevation-0"
                                            style="overflow:hidden;"
                                        >
                                            <!-- {{item.name_kr}} -->
                                            <v-card
                                                class="rounded-lg elevation-0 mb-6"
                                                style="position:relative;"
                                            >
                                                <v-img
                                                        v-on="{ ...menu }"
                                                    width="144"
                                                    class="rounded-lg"
                                                    position="center 30%"
                                                    :src="view_image(item)"
                                                ></v-img>
                                                <v-sheet
                                                    style="position:absolute; bottom:-16px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                                    width="72"
                                                    height="32"
                                                    class="d-flex align-center justify-center mx-auto rounded-20"
                                                >
                                                    <p
                                                        style="font-size:15px; font-weight:bold; color:#424361;"
                                                        class="mb-0 text-center mt-1px"
                                                    >{{index+14}}</p>
                                                </v-sheet>
                                            </v-card>
                                        </v-card>
                                    </template>
                                    <Detail
                                        :id="item.card_id"
                                        :isBoardContent="true"
                                        :nation="nation"
                                        :key="componentKey"
                                    />
                                </v-menu>
                            </div>
                        </v-sheet>
                    </div>

                    <div
                        class="mt-6 d-flex justify-center"
                    >
                        <v-select
                            style="width:120px;"
                            class="shrink rounded-20"
                            :items="select_list.draw_amount"
                            outlined
                            item-text="name"
                            item-value="value"
                            v-model="draw_amount"
                        ></v-select>

                        <v-btn
                            height="56"
                            depressed
                            dark
                            class="ml-3 rounded-15"
                            style="font-size:18px; font-weight:bold;"
                            color="#F8468D"
                            width="142"
                            @click="draw()"
                        >
                            드로우
                        </v-btn>
                    </div>
                </v-sheet>

                <div
                    class="d-flex justify-center mt-8"
                >
                    <v-btn
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#23d2e2"
                        width="142"
                        height="49"
                        @click="reset_deck_test()"
                    >
                        다시 뽑기
                    </v-btn>
                    <v-btn
                        v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-size:18px; font-weight:bold;"
                        color="#B3B3C2"
                        width="142"
                        height="49"
                        @click="dialog.deck_test = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.copy"
            width="auto"
            content-class="rounded-15"
        >
            <v-sheet
                color="#F8F7FB"
                class="pa-5"
                width="340"
                height="200"
            >
                <p
                    class="text-center mb-4"
                    style="font-size:20px; font-weight:bold; color:#424361;"
                >
                    덱 복사하기
                </p>

                <div
                    class="d-flex mb-5"
                    style="width:100%;"
                >
                    <v-sheet
                        width="50%"
                        color="transparent"
                        class="text-center"
                    >
                        <p style="font-size:11pt; margin-bottom:4px; color:#615DFA;">원본 덱 국가</p>
                        <v-text-field
                            class="rounded-20"
                            style="background:white;"
                            hide-details
                            dense
                            outlined
                            readonly
                            v-model="computed_nation"
                        ></v-text-field>
                    </v-sheet>
                    <v-sheet
                        color="transparent"
                        class="d-flex justify-center align-end pb-2 mx-2"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-sheet>
                    <v-sheet
                        width="50%"
                        color="transparent"
                        class="text-center"
                    >
                        <p style="font-size:11pt; margin-bottom:4px; color:#615DFA;">복사할 덱 국가</p>
                        <v-select
                            class="rounded-20"
                            style="background:white;"
                            hide-details
                            dense
                            outlined
                            :items="nation_list"
                            v-model="copy_nation"
                        ></v-select>
                    </v-sheet>
                </div>

                <div class="d-flex justify-center">
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-weight:bold;"
                        color="#615DFA"
                        width="107"
                        height="36"
                        :to="'/deck/copy?id='+this.$route.query.id+'&nation='+this.copy_nation"
                    >
                        복사하기
                    </v-btn>
                    <v-btn
                        v-if="deck.writer == $store.state.user_id || $store.state.type == '관리자'"
                        depressed
                        dark
                        class="ml-3 rounded-10"
                        style="font-weight:bold;"
                        color="#B3B3C2"
                        width="107"
                        height="36"
                        @click="dialog.copy = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.export"
            content-class="rounded-xl"
            width="480"
        >
            <v-sheet
                class="px-8 py-6"
            >
                <p
                    class="text-center text-h6 font-weight-bold"
                >
                    PTCGL 덱 코드 출력
                </p>
                <v-textarea
                    style="max-height:680px;"
                    class="rounded-lg"
                    outlined
                    rows="16"
                    hide-details
                    v-model="deck_export_text"
                ></v-textarea>

                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        depressed
                        width="120"
                        height="40"
                        color="#F8468D"
                        class="rounded-10 white--text mr-2"
                        @click="deckExport_copy()"
                    >
                        <v-icon small class="mr-1">mdi-content-copy</v-icon>
                        복사
                    </v-btn>
                    <v-btn
                        depressed
                        width="120"
                        height="40"
                        color="#ADAFCA"
                        class="rounded-10 white--text"
                        @click="dialog.export = false"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <v-dialog
            v-model="dialog.summary"
            content-class="rounded-xl"
            width="1600"
        >
            <v-sheet
                class="px-8 py-6"
                style="position:relative;"
            >
                <v-icon style="position:absolute; top:16px; right:16px;" size="24" color="black" @click="dialog.summary = false">mdi-close</v-icon>
                <p
                    class="text-h6 font-weight-bold mb-0"
                >
                    {{deck.title}}
                </p>
                <p
                    class="text-subtitle-1 mb-0"
                >
                    {{deck.season}}
                </p>

                <v-sheet
                    class="rounded-10 mt-2 pa-2"
                    outlined
                >
                    <!-- 포켓몬 -->
                    <v-row
                        no-gutters
                    >
                        <v-col
                            v-for="item in card_list" :key="'중복'+item.id"
                            class="px-1"
                            cols="1"
                        >
                            <div
                                class="d-flex"
                            >
                                <!-- 이미지 -->
                                <v-card
                                    class="rounded-lg elevation-0"
                                    style="overflow:hidden;"
                                >
                                    <v-card
                                        class="rounded-lg elevation-0 mb-4"
                                        style="position:relative;"
                                    >
                                        <v-img
                                            class="rounded-lg"
                                            position="center 30%"
                                            :src="view_image(item)"
                                        ></v-img>
                                        <v-sheet
                                            style="position:absolute; bottom:-10px; left:50%; transform:translateX(-50%); box-shadow:0px 4px 10px #eee;"
                                            width="44"
                                            height="20"
                                            class="d-flex align-center justify-center mx-auto rounded-20"
                                        >
                                            <p
                                                style="font-size:12px; font-weight:bold; color:#424361;"
                                                class="mb-0 text-center mt-1px"
                                            >{{item.amount}}</p>
                                        </v-sheet>
                                    </v-card>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Header from '../components/Header'
import Avatar from '@/components/AvatarForDeck'
import TipTapReader from "@/components/tiptap/Reader"
import PointModule from '@/components/PointModule.js'
import Review from "./Review"
import Detail from '@/components/card/detailForBoard/Detail'
import BannerView from '@/components/BannerView'
import html2canvas from 'html2canvas'

export default {
    components: {
        Header,
        Avatar,
        TipTapReader,
        PointModule,
        Review,
        Detail,
        BannerView
    },

    data: () => ({
        select_list: {
            view_type: [
                "기본",
                "이미지(전체)",
                "이미지(중복)",
                "표(카드포함)",
                "표(텍스트만)"
            ],

            nation: [
                {name: "국내판", value: "kr"},
                {name: "북미판", value: "en"},
                {name: "일본판", value: "jp"}
            ],

            // 포켓몬카드 정렬
            sort: [
                "기본",
                "카드번호순",
                "HP순",
                "HP(역순)"
            ],

            draw_amount: [
                { name: "1장", value: 1 },
                { name: "2장", value: 2 },
                { name: "3장", value: 3 },
                { name: "4장", value: 4 },
                { name: "5장", value: 5 },
                { name: "6장", value: 6 },
                { name: "7장", value: 7 },
                // { name: "전부", value: 60 },
            ],

            renewal: {
                en: [],
                kr: [],
                jp: []
            },
        },

        // 덱 기본 정보
        deck: {
            deck_name: "",
            deck_name_kr: "",
            title: "",
            writer: "",
            image: "",
            image_en: "",
            image_kr: "",
            image_jp: "",
            writer_nickname: "",
            regulation_group: "",
            deck_nation: "",
            size: "",
            deck_code: "",
            status: "",
            created: "",
            view_count: 0,
            like_count: 0,
            copy_count: 0,
            rating_avg: 0,
            rating_count: 0,
            tag: "",
            original_id: null
        },

        original_deck: {
            title: "",
            writer_nickname: "",
            regulation_group: "",
            deck_nation: "",
            size: "",
            deck_code: "",
            status: "",
            created: "",
        },

        nation_list: [
            {
                text: "국내판",
                value: "kr"
            },
            {
                text: "북미판",
                value: "en"
            },
            {
                text: "일본판",
                value: "jp"
            }
        ],

        copy_nation: "",


        view_type: "기본",
        nation: "",
        sort: "기본",


        // 덱 카드 목록
        card_list: [],

        // 덱 속성 목록
        type_list: [],

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        standard_list: [],

        dialog: {
            deck_test: false,
            copy:false,
            export: false,
            summary: false
        },

        draw_amount: 1,

        card_list_hand: [],
        card_list_prize: [],
        card_list_draw: [],

        like_list: [],

        componentKey: 0,

        supertype: {
            Energy: [],
            Pokémon: [],
            Trainer: []
        },

        is_exist:false,
        deck_export_text: "",

        loading: false
    }),

    computed: {
        // 재수록카드 목록
        renewal_list(){
            switch(this.nation){
                case 'en' : return this.select_list.renewal.en
                case 'kr' : return this.select_list.renewal.kr
                case 'jp' : return this.select_list.renewal.jp
            }
        },

        // 복사하기 기존 nation 표시
        computed_nation(){
            if(this.deck.deck_nation == "kr")
            {
                return "국내판"
            }
            else if(this.deck.deck_nation == "en")
            {
                return "북미판"
            }
            else if(this.deck.deck_nation == "jp")
            {
                return "일본판"
            }
        },

        // 선택한 목록 > 포켓몬
        card_list_pokemon() {
            console.log(this.card_list)
            return this.card_list.filter((e) => e.card_supertype == 'Pokémon')
        },

        // 선택한 목록 > 트레이너스
        card_list_trainer() {
            return this.card_list.filter((e) => e.card_supertype == 'Trainer')
        },

        // 선택한 목록 > 에너지
        card_list_energy() {
            return this.card_list.filter((e) => e.card_supertype == 'Energy')
        },

        card_list_amount() {
            return this.card_list.reduce((sum, item) => sum + (item.amount || 1), 0);
        },

        // 카드 보기 형식 > 이미지(전체)
        card_list_total_view(){
            let result = []
            for (let card of this.card_list) {
                for (let i = 0; i < card.amount; i++) {
                    // 객체를 복사하여 결과 배열에 추가
                    result.push({ ...card })
                }
            }
            return result
        },

        card_list_total_view_without_hand(){
            return this.card_list_total_view.filter(item => !this.card_list_hand.includes(item))
        },

        card_list_total_view_without_hand_prize(){
            return this.card_list_total_view_without_hand.filter(item => !this.card_list_prize.includes(item))
        },

        card_list_total_view_without_hand_prize_draw(){
            return this.card_list_total_view_without_hand_prize.filter(item => !this.card_list_draw.includes(item))
        },
    },

    watch: {
        sort(){
            this.sortList()
        },

        nation(){
            this.deck.deck_nation = this.nation
        }
    },

    mounted(){
        // 덱 추천한 사람 목록 불러오기
        this.loadDeckLikeList()

        // 덱 정보 불러오기
        this.loadDeck()

        // 덱 카드 목록 불러오기
        this.loadDeckCardList()

        // 덱 속성 목록 불러오기
        this.loadDeckTypeList()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        this.reset_deck_test()

        this.$http.post("/api/new/deck/update/view_count", {
            params: {
                id: this.$route.query.id
            }
        })

        // 카드종류(supertype)별 subtypes list 불러오기
        this.load_supertype_list()

        // 재수록카드 목록 불러오기
        this.loadRenewalList()
    },

    methods: {
        // 덱 이미지 표시
        computed_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },

        // PTCGO 마크 추가시 확인용
        checkBasicEnergy(item) {
            try {
                const subtypes = JSON.parse(item.subtypes)
                return item.ptcgoCode != null || (item.supertype == 'Energy' && Array.isArray(subtypes) && subtypes.includes('Basic'))
            } catch (e) {
                return false
            }
        },

        // 덱 출력 핸들러
        deckExport_handler(){
            // null이 없을 경우
            if("every", this.card_list.every(e => e.ptcgoCode != null))
            {
                this.dialog.export = true

                this.deck_export_text = ""

                this.deck_export_text +=  "Pokémon: " + this.card_list_pokemon.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for (let card of this.card_list_pokemon){
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"

                this.deck_export_text +=  "Trainer: " + this.card_list_trainer.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for (let card of this.card_list_trainer){
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"

                this.deck_export_text +=  "Energy: " + this.card_list_energy.reduce((sum, pokemon) => sum + pokemon.amount, 0) + "\n"

                for (let card of this.card_list_energy){
                    this.deck_export_text += card.amount + " " + card.name_en + " " + card.ptcgoCode + " " + parseInt(card.number_en.replace(/\D/g, '')) + "\n"
                }

                this.deck_export_text +=  "\n"

                this.deck_export_text +=  "Total Cards: " + this.deck.size

                this.$copyText(this.deck_export_text)
            }
            // null이 있을 경우
            else
            {
                alert("PTCGL에 출시 되지 않은 카드가 있어 출력이 불가능합니다.\n다른 카드로 변경해주세요. (카드 우측 하단의 PTCGL 마크를 확인해주세요)")
            }
        },

        convertName(){
            // 없는 값 들어오면 replace 안하고 return
            if(!text){
                return text
            }

            // 에너지 > 중괄호 변환
            if(text.includes("Energy")){
                text = text.replace("Grass", "{G}")
                text = text.replace("Fire", "{R}")
                text = text.replace("Water", "{W}")
                text = text.replace("Lightning", "{L}")
                text = text.replace("Psychic", "{P}")
                text = text.replace("Fighting", "{F}")
                text = text.replace("Darkness", "{D}")
                text = text.replace("Metal", "{M}")
            }

            // 에너지 > Basic 값 제거
            text = text.replace("Basic", "")

            // 괄호로 묶인 내용을 제거 (괄호 포함)
            text = text.replace(/\(.*?\)/g, '')

            // 앞뒤 여백 제거
            text = text.trim()

            return text
        },

        deckExport_copy(){
            // console.log(this.deck_export_text)
            // this.$copyText(this.deck_export_text)
            alert("PTCGL 덱 코드가 복사되었습니다")
        },

        // 재수록카드 목록 불러오기
        loadRenewalList(){
            // 한글
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })

            // 영어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.renewal.en = res.data.map(a => a.card_id)
            })

            // 일어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })
        },

        // 카드종류(supertype)별 subtypes list 불러오기
        load_supertype_list(){
            this.$http.post('/api/admin/card/supertype/list/show')
            .then((res) => {
                this.supertype.Energy = res.data.filter(e => e.category == "Energy")
                this.supertype.Pokémon = res.data.filter(e => e.category == "Pokémon")
                this.supertype.Trainer = res.data.filter(e => e.category == "Trainer")
            })
        },

        // Subtypes 매칭
        computed_subtypes(item){
            // console.log(item)
            if(item.supertype == "Energy")
            {
                // console.log(this.supertype.Energy.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Energy.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(item.supertype == "Pokémon")
            {
                // console.log(this.supertype.Pokémon.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Pokémon.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
            else if(item.supertype == "Trainer")
            {
                // console.log(this.supertype.Trainer.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', '))
                return this.supertype.Trainer.filter(e => JSON.parse(item.subtypes).includes(e.en)).map(e => e.kr).toString().replace(',', ', ')
            }
        },

        reset_deck_test(){
            this.loadDeckCardList()
            
            this.card_list_hand = []
            this.card_list_prize = []
            this.card_list_hand = this.getRandomElements(this.card_list_total_view, 7)

            // this.card_list_total_view

            this.card_list_prize = this.getRandomElements(this.card_list_total_view_without_hand, 6)
            this.card_list_draw = []
            this.componentKey++
        },


        getRandomElements(arr, count) {
            // 배열 복사 및 셔플
            const shuffled = [...arr].sort(() => 0.5 - Math.random())
            // 상위 'count' 개의 요소 반환
            return shuffled.slice(0, count)
        },

        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 스탠다드인지 확인
        is_Standard(item){
            if(this.renewal_list.includes(item.id))
            {
                return true
            }
            else
            {
                if(item.supertype == "Energy" && item.subtypes != null)
                {
                    if(JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))
                    {
                        return true
                    }
                    else
                    {
                        if(this.standard_list.includes(item.regulationMark))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                }
                else
                {
                    if(this.standard_list.includes(item.regulationMark))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
            }
        },
        
        // 이미지 표시
        view_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },

        // 덱 정보 불러오기
        loadDeck(){
            this.$http.post("/api/new/deck/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                console.log(res)
                if(res.data.length){
                    this.is_exist = true
                }

                this.deck = res.data[0]
                this.options.content = res.data[0].content
                this.nation = this.deck.deck_nation

                this.deck.image = this.computed_image(this.deck)

                if(this.deck.status == "비공개" && this.deck.writer != this.$store.state.user_id && this.$store.state.type != "관리자")
                {
                    alert("비공개된 덱은 작성자 외에는 볼 수 없습니다.")
                    this.$router.push("/deck/list")
                }

                if(this.deck.original_id != null)
                {
                    this.$http.post("/api/new/deck/select/specific", {
                        params: {
                            id: this.deck.original_id
                        }
                    }).then((res) => {
                        // console.log(res)
                        if(res.data.length){
                            this.original_deck = res.data[0]
                        }
                    })
                }

            })
        },

        // 덱 정보 다시 불러오기
        reloadDeck(){
            this.$http.post("/api/new/deck/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                // console.log(res)
                this.deck = res.data[0]
                this.options.content = res.data[0].content
                this.deck.image = this.computed_image(this.deck)
            })
        },

        // 덱 카드 목록 불러오기
        loadDeckCardList(){
            this.loading = true
            this.$http.post("/api/new/deck/select/card", {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                // console.log("card_list", res)
                // console.log("card_list.subtypes", res.data.map(e => e.subtypes))
                this.card_list = res.data
                this.sortList()
                this.loading = false
            })
        },

        // 덱 속성 목록 불러오기
        loadDeckTypeList(){
            this.$http.post("/api/new/deck/select/type", {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                this.type_list = res.data
            })
        },

        // 덱 복사하기
        copyDeck(){
            this.$router.push("/deck/copy?id="+this.$route.query.id)
        },

        // 덱 수정하기
        updateDeck(){
            this.$router.push("/deck/update?id="+this.$route.query.id+"&nation="+this.deck.deck_nation)
        },

        // 덱 삭제하기
        deleteDeck(){
            if(confirm("정말 해당 덱을 삭제하시겠습니까?")){
                // 포인트 적립 취소
                if(this.deck.status == "공개"){
                    PointModule.amountCheck('덱 레시피', '공개용으로 덱레시피 만들기').then((res) => {
                        PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                        PointModule.Record(this.$store.state.user_id, '덱 레시피', '공개용으로 덱레시피 만들기', this.deck.title, -res.data[0].amount)
                    })
                }
                this.$http.post("/api/new/deck/delete", {
                    params: {
                        id: this.$route.query.id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("덱이 삭제되었습니다.")
                        this.$router.push("/deck/list")
                    }
                })
            }
        },

        // 드로우
        draw(){
            //  this.card_list_draw.push this.getRandomElements(this.card_list_total_view, this.draw_amount)
            if(parseInt(this.card_list_draw.length) + parseInt(this.draw_amount) <= parseInt(this.deck.size) - 13){
                this.card_list_draw = this.card_list_draw.concat(this.getRandomElements(this.card_list_total_view_without_hand_prize_draw, this.draw_amount))

                // 스크롤 우측 끝으로 이동 (Ref 참조)
                this.$nextTick(() => {
                    setTimeout(() => {
                        const container = this.$refs.drawContainer;
                        container.scrollLeft = container.scrollWidth;
                    }, 50)  // 50ms 지연 후 실행
                })

            }else{
                alert("덱 총 수량 이상으로 드로우하실 수 없습니다")
            }
        },

        // 추천한 사람 리스트 가져오기
        loadDeckLikeList(){
            this.$http.post('/api/new/deck/like/select', {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                this.like_list = res.data.map(a => a.nickname)
            })
        },

        // 추천
        like(){
            if(!this.$store.state.is_logined){
                alert("덱 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.user_id==this.deck.writer){
                alert('자신의 덱에 추천을 할 수 없습니다.')
            }else{
                if(this.like_list.includes(this.$store.state.nickname)){
                    alert("이미 추천한 덱입니다.")
                }else{
                    this.$http.post('/api/new/deck/like/insert', {
                        params: {
                            deck_id: this.$route.query.id,
                            user_id: this.$store.state.user_id
                        }
                    }).then((res) => {
                        if(res.status=='200'){
                            alert("덱이 추천되었습니다")

                            // 포인트 적립
                            PointModule.amountCheck('덱 레시피', '덱 추천').then((res) => {
                                PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                PointModule.Record(this.$store.state.user_id, '덱 레시피', '덱 추천', this.deck.title, res.data[0].amount)
                            })

                            // 추천한 사람 리스트 가져오기
                            this.$http.post('/api/new/deck/like/select', {
                                params: {
                                    deck_id: this.$route.query.id
                                }
                            }).then((res) => {
                                this.like_list = res.data.map(a => a.nickname)
                            })
                        }
                    })
                }
            }   
        },

        // 정렬
        sortList(){
            if(this.sort == "기본")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => a.table_id - b.table_id),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
            else if(this.sort == "카드번호순")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => new Date(a.number_kr).getTime() - new Date(b.number_kr).getTime()),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
            else if(this.sort == "HP순")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => b.hp - a.hp),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
            else if(this.sort == "HP(역순)")
            {
                this.card_list = 
                    [
                        ...this.card_list.filter((e) => e.card_supertype == 'Pokémon').sort((a, b) => a.hp - b.hp),
                        ...this.card_list.filter((e) => e.card_supertype == 'Trainer'),
                        ...this.card_list.filter((e) => e.card_supertype == 'Energy')
                    ]
            }
        },

        // 캡처
        capture(){
            const captureElement = document.getElementById('capture')

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = this.deck.title
                a.click()
            })
        }
    }
}
</script>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
.custom_shadow{
    box-shadow: 0px 0px 12px 4px #f0f0f0 !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}

.gradient-overlay-expanded {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #ff521c, transparent);
  position: relative;
}

.gradient-overlay-standard {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #232323, transparent);
  position: relative;
}
</style>
