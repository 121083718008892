<template>
    <div>
        <Mobile
            v-if="$vuetify.breakpoint.mobile"
            @submit="submit_handler"
        />

        <Pc
            v-if="!$vuetify.breakpoint.mobile"
            @submit="submit_handler"
        />
    </div>
</template>
<script>
import Mobile from "./copy/Mobile"
import Pc from "./copy/Pc"

export default {
    components: {
        Mobile,
        Pc
    },

    data: () => ({
        pass: false
    }),

    // deck/copy ID 없이 접근시 목록으로 리다이렉트
    mounted(){
        if(!this.$route.query.id) {
            this.pass = true
            this.$router.push('/deck/list')
        }
    },

    methods: {
        submit_handler(){
            this.pass = true
            this.$router.push("/deck/list")
        },
    },

    // 페이지 이동 전 확인
    beforeRouteLeave (to, from, next) {
        // console.log(to)
        // console.log(from)

        if(!this.pass){
            const answer = window.confirm("지금 작성하고 계신 글이 저장되지 않을 수 있습니다.\n페이지를 이동하시겠습니까?")
            if (answer) {
                next()
            } else {
                next(false)
            }
        }else{
            next()
        }
    },
}
</script>