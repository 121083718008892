<template>
    <div
        class="mobile"
    >
        <!-- 제목 -->
        <div
            class="d-flex justify-space-between px-4 py-3 mobile"
        >
            <p
                style="font-size:16px; font-weight:bold; cursor:pointer;"
                class="ml-1 mb-0"
                @click="$router.push('/deck/list')"
            >
                덱 레시피
                <span style="color:#F8468D; font-size:7px; vertical-align:super; font-weight:400;">BETA</span>
            </p>


            <!-- 메뉴 -->
            <v-select
                height="36"
                class="shrink rounded-10"
                style="width:110px; font-size:12px;"
                dense
                outlined
                hide-details
                :items="select_list.nation"
                v-model="keyword.card_nation"
                item-text="text"
                item-value="value"
            >
                <template v-slot:selection="{ item }">
                    <span class="pl-2">{{item.text}}</span>
                </template>
                <template v-slot:append>
                    <v-icon size="20" class="mr-1 pt-2px" color="#412361">mdi-chevron-down</v-icon>
                </template>
            </v-select>
        </div>

        <!-- 기본 정보 -->
        <div
            class="px-4 mobile"
        >
            <!-- 덱 이름 -->
            <div class="mycustom">
                <v-text-field
                    height="36"
                    class="rounded-10"
                    outlined
                    hide-details
                    dense
                    placeholder="덱 이름"
                    persistent-placeholder
                    v-model="title"
                    type="text"
                    @keyup.enter="search(false)"
                ></v-text-field>
            </div>

            <!-- 레귤레이션 그룹 & 공개유무 & 덱 종류(사이즈) -->
            <!-- 시즌 & 태그 -->
            <div>
                <!-- 1단 -->
                <v-row no-gutters>
                    <!-- 레귤레이션 그룹 -->
                    <v-col cols="4" class="mobile">
                        <v-select
                            height="36"
                            class="rounded-10"
                            style="font-size:12px;"
                            readonly
                            dense
                            outlined
                            hide-details
                            :items="select_list.regulationGroup"
                            v-model="regulationGroup"
                            append-icon=""
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-2">{{item}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    
                    <!-- 공개유무 -->
                    <v-col cols="4" class="px-1 mobile">
                        <v-select
                            label=""
                            height="36"
                            class="rounded-10"
                            style="font-size:12px;"
                            dense
                            outlined
                            hide-details
                            :items="select_list.status"
                            v-model="status"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-2">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon size="20" class="mr-1 pt-2px" color="#412361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </v-col>
                    
                    <!-- 덱 종류(사이즈) -->
                    <v-col cols="4" class="mobile">
                        <v-select
                            :key="deck_type_key"
                            height="36"
                            class="rounded-10"
                            style="font-size:12px;"
                            dense
                            outlined
                            hide-details
                            :items="select_list.deck_type"
                            v-model="deck_type"
                            @change="onDeckTypeChange"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-2">{{item}}장</span>
                            </template>
                            <template v-slot:append>
                                <v-icon size="20" class="mr-1 pt-2px" color="#412361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>

                <!-- 2단 -->
                <v-row no-gutters>
                    <!-- 제품명 시즌 -->
                    <v-col cols="8" class="pr-1 mobile">
                        <v-autocomplete
                            height="36"
                            class="rounded-10"
                            style="font-size:12px;"
                            placeholder="시즌(환경) 선택"
                            persistent-placeholder
                            dense
                            outlined
                            hide-details
                            :items="season_list"
                            v-model="season"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-3">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon class="mr-1 pt-2px" size="20" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    
                    <!-- 덱 태그 -->
                    <v-col cols="4" class="mobile">
                        <v-autocomplete
                            height="36"
                            class="rounded-10"
                            style="font-size:12px;"
                            dense
                            outlined
                            hide-details
                            multiple
                            placeholder="태그선택"
                            persistent-placeholder
                            :items="select_list.tag"
                            v-model="tag"
                            @change="checkTagLength()"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="pl-3">{{item}}</span>
                            </template>
                            <template v-slot:append>
                                <v-icon size="20" class="mr-1 pt-2px" color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </div>
        </div>
        
        <!-- 버튼 (카드 추가, 내 콜렉션에서 불러오기) -->
        <div
            class="d-flex justify-space-between px-4 mb-2"
        >
            <v-btn
                depressed
                width="49.5%"
                small
                height="34"
                class="rounded-10 mr-1"
                style="font-size:12px; font-weight:400;"
                color="#615DFA"
                dark
                @click="card_switch = !card_switch"
            >
                {{!card_switch? '카드 추가' : '선택한 목록'}}
            </v-btn>
            <v-btn
                depressed
                width="49.5%"
                small
                height="34"
                class="rounded-10"
                style="font-size:12px; font-weight:400;"
                color="#615DFA"
                dark
                @click="loadMyCollection()"
            >
                내 콜렉션에서 불러오기
            </v-btn>
        </div>

        <!-- 카드 추가 -->
        <div
            v-if="card_switch"
        >
            <!-- 카드 검색 -->
            <v-sheet
                class="px-4 pt-4 pb-4 mobile"
            >
                <!-- 1단 -->
                <div
                    class="d-flex align-center mobile"
                >
                    <!-- 카드타입 -->
                    <v-select
                        class="grow-1 rounded-10 font-weight-bold mr-1"
                        placeholder="전체"
                        persistent-placeholder
                        label="카드타입"
                        dense
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        :items="select_list.supertype"
                        v-model="keyword.supertype"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2">{{item.name}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 검색범위 -->
                    <v-select
                        class="grow-1 rounded-10 font-weight-bold"
                        placeholder="전체"
                        persistent-placeholder
                        label="검색범위"
                        dense
                        outlined
                        hide-details
                        item-text="name"
                        item-value="value"
                        :items="select_list.supertype"
                        v-model="keyword.supertype"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="pl-2">{{item.name}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>

                <!-- 2단 -->
                <div
                    class="d-flex align-center mobile"
                    style="margin-top:-4px;"
                >
                    <!-- 카드 이름 -->
                    <v-text-field
                        ref="searchInput"
                        class="grow-1 rounded-10 font-weight-bold mr-1 mt-1"
                        outlined
                        hide-details
                        dense
                        placeholder="카드 이름"
                        persistent-placeholder
                        v-model="keyword.text"
                        append-icon="mdi-magnify"
                        @keyup.enter="search()"
                    >
                        <template v-slot:append>
                            <v-icon size="18" class="mt-1px mr-1" color="#412361">mdi-magnify</v-icon>
                        </template>
                    </v-text-field>

                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10 mr-1"
                        style="font-size:13px;"
                        color="primary"
                        width="41"
                        height="32"
                        @click="search()"
                    >
                        검색
                    </v-btn>

                    <!-- 검색 버튼 -->
                    <v-btn
                        depressed
                        dark
                        class="rounded-10"
                        style="font-size:13px;"
                        color="#615DFA"
                        width="41"
                        height="32"
                        type="text"
                        @click="detail_switch = !detail_switch"
                    >
                        {{detail_switch? '접기':'상세'}}
                    </v-btn>
                </div>

                <!-- 상세 -->
                <div v-if="detail_switch" class="mobile">
                    <!-- 희귀도 -->
                    <v-select
                        height="36"
                        class="rounded-10 font-weight-bold"
                        placeholder="전체"
                        persistent-placeholder
                        label="희귀도"
                        dense
                        outlined
                        hide-details
                        clearable
                        :items="computed_rarity_list"
                        v-model="keyword.rarity"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="">{{item}}</span>
                        </template>
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 제품명 -->
                    <v-select
                        height="36"
                        class="rounded-10 font-weight-bold"
                        label="제품명"
                        placeholder="전체"
                        persistent-placeholder
                        dense
                        outlined
                        clearable
                        hide-details
                        :items="set_list"
                        v-model="keyword.set_name"
                    >
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 시리즈 -->
                    <v-select
                        height="36"
                        class="rounded-10 font-weight-bold"
                        label="시리즈"
                        placeholder="전체"
                        persistent-placeholder
                        dense
                        outlined
                        clearable
                        hide-details
                        :items="series_list"
                        v-model="keyword.series"
                    >
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>

                    <!-- 레귤레이션 -->
                    <v-select
                        height="36"
                        class="rounded-10 font-weight-bold"
                        label="레귤레이션"
                        placeholder="전체"
                        persistent-placeholder
                        dense
                        outlined
                        clearable
                        hide-details
                        :items="select_list.regulationMark"
                        v-model="keyword.regulationMark"
                    >
                        <template v-slot:append>
                            <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                        </template>
                    </v-select>
                </div>
            </v-sheet>

            <!-- 순서 & 목록방식 선택 -->
            <v-sheet
                class="d-flex px-4 mobile"
            >
                <!-- 순서 -->
                <v-select
                    height="36"
                    class="grow-1 rounded-10 font-weight-bold mr-1"
                    placeholder="전체"
                    persistent-placeholder
                    label="순서"
                    dense
                    outlined
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="select_list.sequence"
                    v-model="sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#412361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>

                <v-spacer></v-spacer>

                <!-- 목록 방식 (card) -->
                <v-btn
                    width="45"
                    height="32"
                    x-small
                    dark
                    depressed
                    class="rounded-10 mr-1"
                    style="cursor:pointer;"
                    :color="list_type=='card'? '#23D2E2':'#DFDFDF'"
                    @click="list_type='card'"
                >
                    <v-icon size="22"
                    >
                        mdi-image
                    </v-icon>
                </v-btn>

                <!-- 목록 방식 (list) -->
                <v-btn
                    width="45"
                    height="32"
                    x-small
                    dark
                    depressed
                    class="rounded-10"
                    style="cursor:pointer;"
                    :color="list_type=='list'? '#23D2E2':'#DFDFDF'"
                    @click="list_type='list'"
                >
                    <v-icon size="22">
                        mdi-menu
                    </v-icon>
                </v-btn>

            </v-sheet>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                class="d-flex mx-auto my-16"
                color="primary"
                size="100"
            ></v-progress-circular>

            <!-- 검색 목록 -->
            <v-sheet
                v-if="!loading"
                class="px-4 pt-1 pb-6 mb-6"
                style="position:relative;"
            >

                <!-- 결과 있을때 -->
                <div
                    v-if="list.length"
                    class="mt-6"
                >
                    <!-- 목록 방식 (card) -->
                    <v-row no-gutters v-if="currentPageList.length && list_type=='card'">
                        <v-col class="pa-0 pb-2" cols="6" v-for="item in currentPageList" :key="item.id">
                            <v-card @click="selectCard(item)" width="150"  style="cursor:pointer" class="mx-auto">
                                <!-- 한글 이미지가 없을 경우 -->
                                <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="206">
                                    <!-- 영어 이미지가 있을 경우 -->
                                    <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                        <img width="150" height="206" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                    </div>
                                    <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                    <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                        <img width="150" height="206" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                    </div>
                                    <!-- 셋 다 이미지가 없는 경우 -->
                                    <div v-else>
                                        <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                                    </div>
                                </v-sheet>
                                <!-- 한글 이미지가 있을 경우 -->
                                <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="206">
                                    <!-- 한글 이미지 표시 -->
                                    <img width="150" height="206" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                </v-sheet>
                            </v-card>
                            <!-- <v-card @click="selectCard(item)" width="150" style="cursor:pointer" class="mx-auto">
                                <v-img width="150" height="206" src="@/assets/default.png"></v-img>
                            </v-card> -->
                            <div class="d-flex  justify-center px-2 py-2">
                                <!-- <v-icon size="14" class="mx-2" @click="item.amount>1? item.amount -= 1:''">mdi-minus</v-icon>
                                <v-sheet
                                    outlined
                                    class="px-4 pt-2px rounded-xl"
                                    style="font-size:12px;"
                                >
                                    {{item.amount}}
                                </v-sheet>
                                <v-icon size="14" class="mx-1" @click="item.amount+=1">mdi-plus</v-icon> -->
                                <!-- <v-spacer></v-spacer> -->
                                <v-btn
                                    width="57"
                                    height="24"
                                    style="font-size:10px;"
                                    depressed
                                    dark
                                    small
                                    color="#615DFA"
                                    class="rounded-10"
                                    @click="selectCard(item)"
                                >
                                    카드추가
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- 목록 방식 (list) -->
                    <v-row no-gutters v-if="currentPageList.length && list_type=='list'">
                        <v-col class="py-1" cols="12" v-for="item in currentPageList" :key="item.id">
                            <v-sheet outlined class="px-5 py-3 d-flex align-center rounded-15" style="cursor:pointer;" @click="selectCard(item)">
                                <v-card width="86" style="cursor:pointer" class="mx-auto red">
                                    <!-- 한글 이미지가 없을 경우 -->
                                    <v-sheet v-if="!item.image_kr || item.image_kr=='default.png'" height="120">
                                        <!-- 영어 이미지가 있을 경우 -->
                                        <div v-if="item.image_en!=null && item.image_en!='default.png'">
                                            <img width="86" height="120" :src="'/upload/card_en_image/'+encodeURI(item.image_en)" />
                                            <!-- PTCGL로고 표시-->
                                            <v-img
                                                v-if="checkBasicEnergy(item)"
                                                style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                class="d-flex"
                                                width="26"
                                                height="22"
                                                src="@/assets/tcg.png"
                                            ></v-img>
                                        </div>
                                        <!-- 영어 이미지는 없고 일본 이미지가 있을 경우 -->
                                        <div v-else-if="item.image_jp!=null || item.image_jp!='default.png'">
                                            <img width="86" height="120" :src="'/upload/card_jp_image/'+encodeURI(item.image_jp)" />
                                            <!-- PTCGL로고 표시-->
                                            <v-img
                                                v-if="checkBasicEnergy(item)"
                                                style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                                class="d-flex"
                                                width="26"
                                                height="22"
                                                src="@/assets/tcg.png"
                                            ></v-img>
                                        </div>
                                        <!-- 셋 다 이미지가 없는 경우 -->
                                        <div v-else>
                                            <v-img width="86" height="120" src="@/assets/default.png"></v-img>
                                        </div>
                                    </v-sheet>
                                    <!-- 한글 이미지가 있을 경우 -->
                                    <v-sheet v-if="item.image_kr && item.image_kr!='default.png'" height="120">
                                        <!-- 한글 이미지 표시 -->
                                        <img width="86" height="120" :src="'/upload/card_kr_image/'+encodeURI(item.image_kr)" />
                                        <!-- PTCGL로고 표시-->
                                        <v-img
                                            v-if="checkBasicEnergy(item)"
                                            style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                            class="d-flex"
                                            width="26"
                                            height="22"
                                            src="@/assets/tcg.png"
                                        ></v-img>
                                    </v-sheet>
                                </v-card>

                                <v-sheet class="ml-4 mt-2">
                                    <!-- 카드이름 -->
                                    <div>
                                        <!-- 영어 -->
                                        <p
                                            v-if="keyword.card_nation=='en' && item.name_en"
                                            style="font-size:13px; font-weight:600;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="keyword.card_nation=='kr' && item.name_kr"
                                            style="font-size:13px; font-weight:600;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="keyword.card_nation=='jp' && item.name_jp"
                                            style="font-size:13px; font-weight:600;"
                                            class="mb-2 text-truncate"
                                        >
                                            {{item.name_jp}}
                                        </p>
                                    </div>

                                    <!-- 레귤레이션, 넘버, 토탈넘버 & 희귀도 -->
                                    <div class="d-flex">
                                        <!-- 레귤레이션 -->
                                        <p
                                            v-if="item.regulationMark"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-0"
                                        >
                                            <v-icon size="19" class="pb-2px mr-1">
                                                mdi-alpha-{{item.regulationMark.toLowerCase()}}-box-outline
                                            </v-icon>
                                        </p>

                                        <!-- 넘버, 토탈넘버 -->
                                        <div class="mr-2">
                                            <!-- 영어 -->
                                            <p
                                                v-if="keyword.card_nation=='en' && item.number_en && item.printedTotal_en"
                                                style="font-size:10px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_en}} / {{item.printedTotal_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="keyword.card_nation=='kr' && item.number_kr && item.printedTotal_kr"
                                                style="font-size:10px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_kr}} / {{item.printedTotal_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="keyword.card_nation=='jp' && item.number_jp && item.printedTotal_jp"
                                                style="font-size:10px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.number_jp}} / {{item.printedTotal_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="d-flex ml-2px mb-1">
                                        <!-- 희귀도 -->
                                        <div>
                                            <!-- 영어 -->
                                            <p
                                                v-if="keyword.card_nation=='en' && item.rarity_en"
                                                style="font-size:10px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_en}}
                                            </p>

                                            <!-- 한글 -->
                                            <p
                                                v-if="keyword.card_nation=='kr' && item.rarity_kr"
                                                style="font-size:10px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_kr}}
                                            </p>

                                            <!-- 일어 -->
                                            <p
                                                v-if="keyword.card_nation=='jp' && item.rarity_jp"
                                                style="font-size:10px; font-weight:300;"
                                                class="mb-0"
                                            >
                                                {{item.rarity_jp}}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- 제품명 -->
                                    <div class="ml-1px text-truncate" style="width:180px;">
                                        <!-- 영어 -->
                                        <p
                                            v-if="keyword.card_nation=='en' && item.set_en"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_en}}
                                        </p>

                                        <!-- 한글 -->
                                        <p
                                            v-if="keyword.card_nation=='kr' && item.set_kr"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_kr}}
                                        </p>

                                        <!-- 일어 -->
                                        <p
                                            v-if="keyword.card_nation=='jp' && item.set_jp"
                                            style="font-size:10px; font-weight:300;"
                                            class="mb-1 text-truncate"
                                        >
                                            {{item.set_jp}}
                                        </p>
                                    </div>

                                    <div class="d-flex py-2">
                                        <!-- <v-icon size="14" class="mx-2" @click="item.amount>1? item.amount -= 1:''">mdi-minus</v-icon>
                                        <v-sheet
                                            outlined
                                            class="px-4 pt-2px rounded-xl"
                                            style="font-size:12px;"
                                        >
                                            {{item.amount}}
                                        </v-sheet>
                                        <v-icon size="14" class="mx-2" @click="item.amount+=1">mdi-plus</v-icon> -->
                                        <!-- <v-spacer></v-spacer> -->
                                        <v-btn
                                            width="57"
                                            height="24"
                                            style="font-size:10px;"
                                            depressed
                                            dark
                                            small
                                            color="#615DFA"
                                            class="rounded-10"
                                            @click="selectCard(item)"
                                        >
                                            카드추가
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>

                <!-- 결과 없을때 -->
                <div
                    v-if="!loading && !list.length"
                    class="mt-6 text-center py-16"
                >
                    <v-icon size="60" color="grey lighten-2">mdi-cancel</v-icon>
                    <p style="color:#ccc">검색 결과가 없습니다</p>
                </div>

                <!-- 페이지네이션 -->
                <v-pagination
                    class="mt-5"
                    :class="!$vuetify.breakpoint.mobile? 'mb-2':'mb-6'"
                    color="primary"
                    v-model="page"
                    :length="pageLength"
                    :total-visible="3"
                ></v-pagination>

                <v-btn
                    class="d-block rounded-10 mx-auto"
                    depressed
                    color="#615DFA"
                    width="100"
                    height="36"
                    style="font-size:12px; color:white;"
                    @click="card_switch = !card_switch"
                >
                    닫기
                </v-btn>
            </v-sheet>
        </div>

        <!-- 선택한 목록 -->
        <v-sheet
            v-if="!card_switch"
            class="rounded-15 px-7 py-5 mx-4"
        >
            <div
                class="d-flex justify-space-between"
            >
                <p
                    class="mb-0"
                    style="font-weight:bold; font-size:16px;"
                >
                    선택한 목록
                </p>
                <p
                    class="mb-0"
                    style="font-weight:bold; font-size:16px;"
                >
                    {{selected_list_amount}}/{{deck_type}}
                </p>
            </div>

            <!-- 포켓몬 -->
            <div
                class="mt-6 mb-1 font-weight-medium"
                style="color:#F8468D; font-size:15px;"
            >
                포켓몬 ({{selected_list_pokemon_amount}})

                <p
                    class="mb-1 font-weight-medium text-end mr-8"
                    style="color:#F8468D; font-size:11px; margin-top:-18px;"
                >
                    대표 이미지
                </p>
            
                <v-sheet
                    v-for="item in selected_list_pokemon" :key="item.id"
                    class="mt-1"
                >
                    <div
                        class="d-flex"
                    >
                        <v-menu
                            offset-x
                            left
                            open-on-hover
                            nudge-right="0"
                            max-width="320"
                            content-class="rounded-15"
                            :close-on-content-click="true"
                        >
                            <template v-slot:activator="{ on: menu }">
                                <v-img
                                    v-on="{ ...menu }"
                                    width="258"
                                    height="50"
                                    position="center 30%"
                                    style="position:relative;"
                                    :src="view_image(item)"
                                >
                                    <v-img
                                        v-if="checkBasicEnergy(item)"
                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                        class="d-flex"
                                        width="26"
                                        height="22"
                                        src="@/assets/tcg.png"
                                    ></v-img>
                                    <div
                                        class="d-flex"
                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                        style="position:relative;"
                                    >
                                        <p
                                            v-if="!is_Standard(item)"
                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                        >
                                            익스펜디드
                                        </p>
                                        <v-sheet
                                            height="36"
                                            width="36"
                                            class="rounded-circle text-center ma-2" 
                                            style="background:#f8468d"
                                        >
                                            <span style="line-height:36px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                        </v-sheet>
                                        <div
                                            class="pl-2 mr-1 mt-1 text-truncate"
                                            style="width:200px;"
                                        >
                                            <p
                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                            </p>
                                            <p
                                                style="font-size:14px; margin-bottom:0px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                            </p>
                                            <p
                                                style="font-size:9px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </v-img>
                            </template>
                            <Detail
                                :id="item.id"
                                :isBoardContent="true"
                                :nation="keyword.nation"
                            />
                        </v-menu>

                        <div
                            v-if="edit_mode == 'amount'"
                        >
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="plusAmount(item)"
                            >
                                <v-icon size="18">mdi-plus-circle</v-icon>
                            </v-btn>
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="minusAmount(item)"
                            >
                                <v-icon size="18">mdi-minus-circle</v-icon>
                            </v-btn>
                        </div>
                        <div
                            v-if="edit_mode == 'sequence'"
                        >
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="upSequence(item)"
                            >
                                <v-icon size="18">mdi-chevron-up-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="downSequence(item)"
                            >
                                <v-icon size="18">mdi-chevron-down-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>
            </div>
            <p
                v-if="!selected_list_pokemon.length"
                class="mt-2 grey--text text-caption"
            >
                아직 선택한 포켓몬 카드가 없습니다
            </p>

            <!-- 트레이너스 -->
            <p
                class="mt-6 mb-1 font-weight-medium"
                style="color:#F8468D; font-size:15px;"
            >
                트레이너스 ({{selected_list_trainer_amount}})

                <v-sheet
                    v-for="item in selected_list_trainer" :key="item.id"
                    class="mt-1"
                >
                    <div
                        class="d-flex"
                    >
                        <v-menu
                            offset-x
                            left
                            open-on-hover
                            nudge-right="0"
                            max-width="320"
                            content-class="rounded-15"
                            :close-on-content-click="true"
                        >
                                                        <template v-slot:activator="{ on: menu }">
                                <v-img
                                    v-on="{ ...menu }"
                                    width="258"
                                    height="50"
                                    position="center 30%"
                                    style="position:relative;"
                                    :src="view_image(item)"
                                >
                                    <v-img
                                        v-if="checkBasicEnergy(item)"
                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                        class="d-flex"
                                        width="26"
                                        height="22"
                                        src="@/assets/tcg.png"
                                    ></v-img>
                                    <div
                                        class="d-flex"
                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                        style="position:relative;"
                                    >
                                        <p
                                            v-if="!is_Standard(item)"
                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                        >
                                            익스펜디드
                                        </p>
                                        <v-sheet
                                            height="36"
                                            width="36"
                                            class="rounded-circle text-center ma-2" 
                                            style="background:#f8468d"
                                        >
                                            <span style="line-height:36px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                        </v-sheet>
                                        <div
                                            class="pl-2 mr-1 mt-1 text-truncate"
                                            style="width:200px;"
                                        >
                                            <p
                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                            </p>
                                            <p
                                                style="font-size:14px; margin-bottom:0px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                            </p>
                                            <p
                                                style="font-size:9px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </v-img>
                            </template>
                            <Detail
                                :id="item.id"
                                :isBoardContent="true"
                                :nation="keyword.nation"
                            />
                        </v-menu>

                        <div
                            v-if="edit_mode == 'amount'"
                        >
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="plusAmount(item)"
                            >
                                <v-icon size="18">mdi-plus-circle</v-icon>
                            </v-btn>
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="minusAmount(item)"
                            >
                                <v-icon size="18">mdi-minus-circle</v-icon>
                            </v-btn>
                        </div>
                        <div
                            v-if="edit_mode == 'sequence'"
                        >
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="upSequence(item)"
                            >
                                <v-icon size="18">mdi-chevron-up-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="downSequence(item)"
                            >
                                <v-icon size="18">mdi-chevron-down-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>
            </p>
            <p
                v-if="!selected_list_trainer.length"
                class="mt-2 grey--text text-caption"
            >
                아직 선택한 트레이너스 카드가 없습니다
            </p>

            <!-- 에너지 -->
            <p
                class="mt-6 mb-1 font-weight-medium"
                style="color:#F8468D; font-size:15px;"
            >
                에너지 ({{selected_list_energy_amount}})

                <v-sheet
                    v-for="item in selected_list_energy" :key="item.id"
                    class="mt-1"
                >
                    <div
                        class="d-flex"
                    >
                        <v-menu
                            offset-x
                            left
                            open-on-hover
                            nudge-right="0"
                            max-width="320"
                            content-class="rounded-15"
                            :close-on-content-click="true"
                        >
                                                        <template v-slot:activator="{ on: menu }">
                                <v-img
                                    v-on="{ ...menu }"
                                    width="258"
                                    height="50"
                                    position="center 30%"
                                    style="position:relative;"
                                    :src="view_image(item)"
                                >
                                    <v-img
                                        v-if="checkBasicEnergy(item)"
                                        style="position:absolute; bottom:2px; right:2px; z-index:0;"
                                        class="d-flex"
                                        width="26"
                                        height="22"
                                        src="@/assets/tcg.png"
                                    ></v-img>
                                    <div
                                        class="d-flex"
                                        :class="is_Standard(item)? 'gradient-overlay-standard' : 'gradient-overlay-expanded'"
                                        style="position:relative;"
                                    >
                                        <p
                                            v-if="!is_Standard(item)"
                                            style="font-size:8px; font-weight:medium; position:absolute; top:0px; left:1px; color:white;"
                                        >
                                            익스펜디드
                                        </p>
                                        <v-sheet
                                            height="36"
                                            width="36"
                                            class="rounded-circle text-center ma-2" 
                                            style="background:#f8468d"
                                        >
                                            <span style="line-height:36px; color:white; font-weight:bold; font-size:18px;">{{item.amount}}</span>
                                        </v-sheet>
                                        <div
                                            class="pl-2 mr-1 mt-1 text-truncate"
                                            style="width:200px;"
                                        >
                                            <p
                                                style="font-size:9px; text-align:end; margin-bottom:-12px; min-height:15px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.rarity_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.rarity_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.rarity_jp}}</span>
                                            </p>
                                            <p
                                                style="font-size:14px; margin-bottom:0px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.name_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.name_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.name_jp}}</span>
                                            </p>
                                            <p
                                                style="font-size:9px;"
                                            >
                                                <span v-if="keyword.card_nation == 'en'" style="color:white;">{{item.set_en}}</span>
                                                <span v-if="keyword.card_nation == 'kr'" style="color:white;">{{item.set_kr}}</span>
                                                <span v-if="keyword.card_nation == 'jp'" style="color:white;">{{item.set_jp}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </v-img>
                            </template>
                            <Detail
                                :id="item.id"
                                :isBoardContent="true"
                                :nation="keyword.nation"
                            />
                        </v-menu>

                        <div
                            v-if="edit_mode == 'amount'"
                        >
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="plusAmount(item)"
                            >
                                <v-icon size="18">mdi-plus-circle</v-icon>
                            </v-btn>
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="minusAmount(item)"
                            >
                                <v-icon size="18">mdi-minus-circle</v-icon>
                            </v-btn>
                        </div>
                        <div
                            v-if="edit_mode == 'sequence'"
                        >
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="upSequence(item)"
                            >
                                <v-icon size="18">mdi-chevron-up-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                                height="20"
                                width="18"
                                x-small
                                text
                                color="#ADAFCA"
                                @click="downSequence(item)"
                            >
                                <v-icon size="18">mdi-chevron-down-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-sheet>
            </p>
            <p
                v-if="!selected_list_energy.length"
                class="mt-2 grey--text text-caption"
            >
                아직 선택한 에너지 카드가 없습니다
            </p>

            <div
                class="d-flex justify-center mt-10"
            >
                <!-- 초기화 -->
                <v-btn
                    depressed
                    dark
                    class="rounded-10 mr-1"
                    style="font-size:12px; font-weight:600;"
                    color="primary"
                    width="78"
                    height="36"
                    @click="selected_list = []"
                >
                    초기화
                </v-btn>

                <!-- 순서변경 -->
                <v-btn
                    depressed
                    class="rounded-10"
                    style="font-size:12px; font-weight:600;"
                    color="#F8468D"
                    width="78"
                    height="36"
                    @click="edit_mode == 'amount'? edit_mode = 'sequence':edit_mode = 'amount'"
                >
                    <span
                        v-if="edit_mode == 'amount'"
                        class="white--text"
                    >
                        순서변경
                    </span>
                    <span
                        v-if="edit_mode == 'sequence'"
                        class="white--text"
                    >
                        수량변경
                    </span>
                </v-btn>
            </div>
        </v-sheet>

        <!-- 버튼 (카드 추가, 내 콜렉션에서 불러오기) -->
        <div
            class="d-flex justify-space-between px-4 mt-1 mb-2"
        >
            <v-btn
                depressed
                width="49.5%"
                small
                height="34"
                class="rounded-10 mr-1"
                style="font-size:12px; font-weight:400;"
                color="#615DFA"
                dark
                @click="card_switch = !card_switch"
            >
                {{!card_switch? '카드 추가' : '선택한 목록'}}
            </v-btn>
            <v-btn
                depressed
                width="49.5%"
                small
                height="34"
                class="rounded-10"
                style="font-size:12px; font-weight:400;"
                color="#615DFA"
                dark
                @click="loadMyCollection()"
            >
                내 콜렉션에서 불러오기
            </v-btn>
        </div>

        <!-- 스탠다드 레귤레이션 안내문구 -->
        <v-sheet
            height="40"
            class="rounded-10 d-flex align-center justify-center mx-4 mt-3"
        >
            <v-icon size="18" color="#ADAFCA" class="mr-1">mdi-information-outline</v-icon>
            <p
                class="my-0"
                style="font-size:11px;"
            >
                현재 스탠다드 레귤레이션은 <span class="primary--text font-weight-medium mr-1">{{standard_list.toString()}}</span>입니다.
            </p>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet
            class="rounded-15 pa-1 mt-3 mb-2 mx-4"
        >
            <!-- Writer -->
            <TipTapWriter
                v-if="options.content"
                class="tiptap_style"
                :options="options"
                @emit_count="import_content_count"
            />
        </v-sheet>

        <!-- 저작권법 안내문구 -->
        <v-sheet
            height="24"
            color="#F8468D"
            class="rounded-10 pt-1 mx-4 mb-2"
        >
            <p
                class="white--text text-center mb-0"
                style="font-size:9px; letter-spacing:-0.5px;"
            >
                타인이 촬영한 사진을 무단으로 사용시 저작권법 위반으로 처벌 될 수 있습니다.
            </p>
        </v-sheet>

        <!-- 비공개 안내문구 -->
        <v-sheet
            height="40"
            class="rounded-10 d-flex align-center justify-center px-4 mb-5 mx-4"
        >
            <v-icon size="18" color="#ADAFCA" class="mr-1 mb-2px">mdi-information-outline</v-icon>
            <p
                class="my-0"
                style="font-size:11px;"
            >
                비공개로 덱을 저장하면 포인트가 지급되지 않습니다.
            </p>
        </v-sheet>

        <!-- 하단 버튼 -->
        <div
            class="d-flex justify-center mb-16"
        >
            <!-- 등록 -->
            <v-btn
                :disabled="loading"
                depressed
                dark
                class="rounded-10 mr-2"
                style="font-size:12px; font-weight:400;"
                color="primary"
                width="84"
                height="36"
                @click="save()"
            >
                등록
            </v-btn>

            <!-- 취소 -->
            <v-btn
                depressed
                dark
                class="rounded-10"
                style="font-size:12px; font-weight:400;"
                color="#ADAFCA"
                width="84"
                height="36"
                @click="cancel()"
            >
                취소
            </v-btn>
        </div>

        <!-- 로딩 -->
        <!-- <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 90%; left: 55.5%; transform: translate(-50%, -50%);"
            indeterminate
            color="primary"
            size="80"
        ></v-progress-circular> -->
    </div>
</template>
<script>
import Header from '../components/Header'
import TipTapWriter from "@/components/tiptap/WriterMobile"
import Detail from '@/components/card/detailForBoard/Detail'
import PointModule from '@/components/PointModule.js'

export default {
    components: {
        Header,
        TipTapWriter,
        Detail,
        PointModule
    },

    data: () => ({
        select_list: {
            // 슈퍼타입
            supertype: [
                {name: '모든 카드',  value:'ALL'},
                {name: '에너지',  value:'Energy'},
                {name: '포켓몬', value:'Pokémon'},
                {name: '트레이너스', value:'Trainer'}
            ],

            // 덱 태그
            tag: [],

            nation: [
                { text: "국내판", value: "kr" },
                { text: "북미판", value: "en" },
                { text: "일어판", value: "jp" },
            ],

            card_nation: [
                "en",
                "kr",
                "jp",
            ],

            search_type: [
                "전체",
            ],

            status: [
                "공개",
                "비공개"
            ],

            deck_type: [
                60,
                30
            ],

            set: {
                en: [],
                kr: [],
                jp: []
            },

            season: {
                en: [],
                kr: [],
                jp: []
            },

            renewal: {
                en: [],
                kr: [],
                jp: []
            },


            rarity: {
                en: [],
                kr: [],
                jp: []
            },

            series: {
                en: [],
                kr: [],
                jp: []
            },

            tag: [],

            regulationMark: [],

            regulationGroup: [
                "익스펜디드",
                "스탠다드"
            ]
        },

        keyword: {
            text: "",
            card_nation: "kr",
            set_name: "",
            rarity: "",
            regulationMark: [],
            supertype: "ALL",
        },

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        title: "",
        status: "공개",

        season: "",
        tag: [],

        deck_type: 60,
        loading: true,

        detail_switch:false,

        card_switch: false,

        sequence: "채용건수",

        // 목록 & 페이징
        list: [],
        currentPageList: [],
        page: 1,
        pageLength:1,
        list_ready:false,

        list_type: "card",

        mobile: {
            search_detail_switch: false
        },

        selected_list: [],

        deck_type_key: 0,

        edit_mode: "amount",

        standard_list: [],
        sizeImported: false,
        size: 0,

        align_number: false,
        align_name: false,
        align_amount: false,
        align_date: false,

        componentKey: 0,

        dialog: {
            cardDetail: false
        }
    }),

    computed:{
        // 레귤레이션 그룹
        regulationGroup() {
            // console.log(this.selected_list)

            return this.selected_list.every(item =>
                    this.renewal_list.includes(item.id)
                ||
                    this.standard_list.includes(item.regulationMark)
                ||
                    (
                        item.regulationMark === null
                    &&
                        (
                                item.supertype == "Energy" && item.subtypes != null
                            &&
                                JSON.parse(item.subtypes).length
                            &&
                                (
                                    JSON.parse(item.subtypes).includes("Basic")
                                ||
                                    JSON.parse(item.subtypes).includes(null)
                                )
                        )
                    )
                )? '스탠다드':'익스펜디드'
        },

        // 재수록카드 목록
        renewal_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.renewal.en
                case 'kr' : return this.select_list.renewal.kr
                case 'jp' : return this.select_list.renewal.jp
            }
        },

        // 제품명
        set_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.set.en
                case 'kr' : return this.select_list.set.kr
                case 'jp' : return this.select_list.set.jp
            }
        },

        // 시리즈
        series_list(){
            switch(this.keyword.card_nation){
                case 'kr' : return this.select_list.series.kr
                case 'en' : return this.select_list.series.en
                case 'jp' : return this.select_list.series.jp
            }
        },

        // 제품명 시즌
        season_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.season.en
                case 'kr' : return this.select_list.season.kr
                case 'jp' : return this.select_list.season.jp
            }
        },

        // 계산된 희귀도 목록
        computed_rarity_list(){
            switch(this.keyword.card_nation){
                case 'en' : return this.select_list.rarity.en
                case 'kr' : return this.select_list.rarity.kr
                case 'jp' : return this.select_list.rarity.jp
            }
        },

        // 선택한 목록 > 포켓몬
        selected_list_pokemon() {
            return this.selected_list.filter((e) => e.supertype == 'Pokémon')
        },

        // 선택한 목록 > 트레이너스
        selected_list_trainer() {
            return this.selected_list.filter((e) => e.supertype == 'Trainer')
        },

        // 선택한 목록 > 에너지
        selected_list_energy() {
            // console.log("focus", this.selected_list)
            // console.log("focusFiltered", this.selected_list.filter((e) => e.supertype == 'Energy'))
            return this.selected_list.filter((e) => e.supertype == 'Energy')
        },

        selected_list_amount() {
            return this.selected_list.reduce((sum, item) => sum + (item.amount || 1), 0);
        },

        selected_list_type() {
            return this.selected_list.reduce((acc, e) => {
                if(JSON.parse(e.types) == null) return acc
                if (!e.types || !e.amount) return acc

                let typesArray = JSON.parse(e.types)
                for (let i = 0; i < e.amount; i++) {
                    acc = acc.concat(typesArray)
                }

                return acc
            }, [])
        },

        selected_list_pokemon_amount() {
            let result = this.selected_list.reduce((sum, item) => {
                if (item.supertype === "Pokémon") {
                    return sum + (item.amount || 1)
                }
                return sum
            }, 0)
            return result
        },

        selected_list_trainer_amount() {
            let result = this.selected_list.reduce((sum, item) => {
                if (item.supertype === "Trainer") {
                    return sum + (item.amount || 1)
                }
                return sum
            }, 0)
            return result
        },

        selected_list_energy_amount() {
            let result = this.selected_list.reduce((sum, item) => {
                if (item.supertype === "Energy") {
                    return sum + (item.amount || 1)
                }
                return sum
            }, 0)
            return result
        },
    },

    watch: {
        // 페이징
        page(){
            if(this.list_type == "card")
            {
                this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
            }
            else if(this.list_type == "list")
            {
                this.currentPageList = this.list.slice((this.page-1)*3,(this.page)*3)
            }
        },

        // 순서
        sequence(){
            this.sortList()
        },

        // 순서
        align_number(){
            this.sortList()
        },

        // 순서
        align_name(){
            this.sortList()
        },

        // 순서
        align_amount(){
            this.sortList()
        },

        // 순서
        align_date(){
            this.sortList()
        },

        // 목록 타입
        list_type(){
            if(this.list_type == "list")
            {
                this.currentPageList = this.list.slice(0,3)
                this.pageLength = Math.ceil(this.list.length/3)
                this.list_ready = true
                this.page = 1
            }
            else
            {
                this.currentPageList = this.list.slice(0,12)
                this.pageLength = Math.ceil(this.list.length/12)
                this.list_ready = true
                this.page = 1
            }
        },
    },

    mounted() {
        // 덱 정보 불러오기
        this.loadDeck()

        // 선택한 목록 불러오기
        this.loadDeckCardList()

        if(this.$store.state.grade == 0){
            alert("등급이 0인 회원은 덱 레시피를 작성하실 수 없습니다")
            this.$router.go(-1)
        }

        // 제품명 목록 불러오기
        this.loadSetsList()

        // 제품명 시즌 목록 불러오기
        this.loadSeasonList()
        
        // 희귀도 목록 불러오기
        this.loadRarityList()

        // 레귤레이션 목록 불러오기
        this.loadRegulationMarkList()

        // 레귤레이션 스탠다드 가져오기
        this.load_standard_list()

        if(this.$route.query.nation){
            this.keyword.card_nation = this.$route.query.nation
        }

        // 첫 검색
        this.search_first()

        // 태그 목록 불러오기
        this.loadTagList()

        // 재수록카드 목록 불러오기
        this.loadRenewalList()

        // 시리즈 목록 불러오기
        this.loadSeriesList()
    },

    methods: {
        // PTCGO 마크 추가시 확인용
        checkBasicEnergy(item) {
            try {
                const subtypes = JSON.parse(item.subtypes)
                return item.ptcgoCode != null || (item.supertype == 'Energy' && Array.isArray(subtypes) && subtypes.includes('Basic'))
            } catch (e) {
                return false
            }
        },
        
        // 시리즈 목록 불러오기
        loadSeriesList(){
            // 한글
            this.$http.post('/api/card/select/series/kr')
            .then(res => { 
                this.select_list.series.kr = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 영어
            this.$http.post('/api/card/select/series/en')
            .then(res => { 
                this.select_list.series.en = res.data.map(a => a.series).filter(e => e!=null)
            })

            // 일어
            this.$http.post('/api/card/select/series/jp')
            .then(res => { 
                this.select_list.series.jp = res.data.map(a => a.series).filter(e => e!=null)
            })
        },

        changeCardNation(card_nation){
            if(confirm("발매 국가를 변경하면 선택한 목록이 초기화됩니다.\n변경하시겠습니까?")){
                this.card_nation = card_nation
            }
        },

        checkTagLength(){
            if(this.tag.length > 4){
                alert("태그는 최대 4개까지 선택할 수 있습니다.")
                this.tag = this.tag.slice(0,4)
            }
        },

        // 태그 목록 불러오기
        loadTagList(){
            this.$http.get('/api/admin/deck/tag/list')
            .then((res) => {
                this.select_list.tag = res.data.reverse().map(e => e.tag)
            })
        },

        // 내 콜렉션
        loadMyCollection(){
            this.loading = true
            this.$http.post("/api/new/card_kr/select/search/collection", {
                params: {
                    keyword: this.keyword,
                    user_id: this.$store.state.user_id
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data
                    if(this.list_type == "card"){
                        this.currentPageList = this.list.slice(0,12)
                        this.pageLength = Math.ceil(res.data.length/12)
                    }
                    else if(this.list_type == "list")
                    {
                        this.currentPageList = this.list.slice(0,3)
                        this.pageLength = Math.ceil(res.data.length/3)
                    }
                    this.list_ready = true
                    this.page = 1

                    this.sortList()
                    this.loading = false
                }else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                    this.loading = false
                }
            })
        },
        
        // 덱 정보 불러오기
        loadDeck(){
            this.$http.post("/api/new/deck/select/specific", {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                this.title = res.data[0].title
                this.status = res.data[0].status
                this.size = parseInt(res.data[0].size)
                this.deck_type = parseInt(res.data[0].size)
                this.season = res.data[0].season
                this.tag = res.data[0].tag?.split(',')
                this.sizeImported = false
                this.options.content = res.data[0].content
            })
        },

        // 선택한 목록 불러오기
        loadDeckCardList(){
            this.$http.post("/api/new/deck/select/card", {
                params: {
                    deck_id: this.$route.query.id
                }
            }).then((res) => {
                this.selected_list = res.data
                console.log("res", res)
            })
        },

        // 스탠다드인지 확인
        is_Standard(item){
            // console.log(item)
            if(this.renewal_list.includes(item.id))
            {
                return true
            }
            else
            {
                if(item.supertype == "Energy" && item.subtypes != null)
                {
                    if(JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))
                    {
                        return true
                    }
                    else
                    {
                        if(this.standard_list.includes(item.regulationMark))
                        {
                            return true
                        }
                        else
                        {
                            return false
                        }
                    }
                }
                else
                {
                    if(this.standard_list.includes(item.regulationMark))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
            }
        },

        // 레귤레이션 목록 불러오기
        loadRegulationMarkList(){
            this.$http.post('/api/admin/card/regulation_mark/list')
            .then((res) => {
                this.select_list.regulationMark = res.data.map(e => e.regulation_mark)
            })
        },

        view_image(item){
            if(!item.image_kr || item.image_kr=='default.png'){
                if(item.image_en!=null && item.image_en!='default.png'){
                    return '/upload/card_en_image/'+encodeURI(item.image_en)
                }
                else if(item.image_jp!=null || item.image_jp!='default.png'){
                    return '/upload/card_jp_image/'+encodeURI(item.image_jp)
                }
                else {
                    return "@/assets/default.png"
                }
            }
            else if(item.image_kr && item.image_kr!='default.png'){
                return '/upload/card_kr_image/'+encodeURI(item.image_kr)
            }
        },


        // 레귤레이션 스탠다드 가져오기
        load_standard_list(){
            this.$http.post('/api/admin/card/regulation_mark/list').then((res) => {
                this.standard_list = res.data
                    .filter(e => e.type === '스탠다드')
                    .map(e => e.regulation_mark)
            })
        },

        // 재수록카드 목록 불러오기
        loadRenewalList(){
            // 한글
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })

            // 영어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.renewal.en = res.data.map(a => a.card_id)
            })

            // 일어
            this.$http.post('/api/admin/deck/renewal/search', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.renewal.kr = res.data.map(a => a.card_id)
            })
        },

        // 제품명 목록 불러오기
        loadSetsList(){
            // 한글
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })

            // 영어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "en"
                }
            }).then(res => { 
                this.select_list.set.en = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/card/select/sets', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                this.select_list.set.kr = res.data.map(a => a.name)
            })
        },

        // 제품명 시즌 목록 불러오기
        loadSeasonList(){
            // 영어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "en"
                }
            }).then(res => {
                // console.log(res)
                this.select_list.season.en = res.data.map(a => a.name)
            })

            // 한글
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "kr"
                }
            }).then(res => { 
                // console.log(res)
                this.select_list.season.kr = res.data.map(a => a.name)
            })

            // 일어
            this.$http.post('/api/admin/deck/season/select', {
                params: {
                    nation: "jp"
                }
            }).then(res => {
                // console.log(res)
                this.select_list.season.jp = res.data.map(a => a.name)
            })
        },

        // 희귀도 목록 불러오기
        loadRarityList(){
            this.$http.post('/api/card/select/rarity')
            .then(res => {
                this.select_list.rarity.en = res.data.map(e => e.en).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.kr = res.data.map(e => e.kr).filter(e => e!=null && e!="-" && e!="")
                this.select_list.rarity.jp = res.data.map(e => e.jp).filter(e => e!=null && e!="-" && e!="")
            })
        },

        // 에너지 text 아이콘으로 변환 (영어)
        replaceEnglishToIcon(text){
            text = text.replaceAll('Grass', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Grass.png"></img>`)
            text = text.replaceAll('Fire', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fire.png"></img>`)
            text = text.replaceAll('Water', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Water.png"></img>`)
            text = text.replaceAll('Lightning', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Lightning.png"></img>`)
            text = text.replaceAll('Psychic', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Psychic.png"></img>`)
            text = text.replaceAll('Fighting', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fighting.png"></img>`)
            text = text.replaceAll('Darkness', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Darkness.png"></img>`)
            text = text.replaceAll('Metal', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Metal.png"</img>`)
            text = text.replaceAll('Fairy', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Fairy.png"</img>`)
            text = text.replaceAll('Dragon', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Dragon.png"></img>`)
            text = text.replaceAll('Colorless', `<img width="30" height="30" style="vertical-align:sub;" src="https://icu.gg/upload/type/Colorless.png"></img>`)
            return text
        },

        // 국가선택 $Emit Handler
        nationUpdated_handler(card_nation){
            this.keyword = {
                card_nation : card_nation,
                text: "",
                set_name: "",
                rarity: "",
                regulationMark: [],
                types: "",
                supertype: "ALL"
            },

            this.search()

            this.selected_list = []
        },

        // 내용 Count 값 import
        import_content_count(count){
            this.content_count = count
        },

        // 카드 선택
        selectCard(item){
            // 찬란한 포켓몬
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "Radiant Rare" && this.selected_list.some(e => e.rarity_en == "Radiant Rare")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "K 찬란한" && this.selected_list.some(e => e.rarity_kr == "K 찬란한")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "K かがや" && this.selected_list.some(e => e.rarity_jp == "K かがや")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            // ACE SPEC
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "ACE SPEC Rare" && this.selected_list.some(e => e.rarity_en == "ACE SPEC Rare")){
                    alert("ACE SPEC Rare는 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "ACE SPEC" && this.selected_list.some(e => e.rarity_kr == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "ACE SPEC" && this.selected_list.some(e => e.rarity_jp == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            console.log("selected_card", item)

            if(this.selected_list_amount == this.deck_type) {
                alert("최대 수량에 도달하였습니다.")
            }else{
                // 이미 존재하는 지
                let findedExistCard = this.selected_list.find(e => e.id === item.id)

                // 존재하면 수량 증가
                if(findedExistCard)
                {
                    // 30장 덱
                    if(this.deck_type == 30)
                    {
                        // 기본 에너지의 경우
                        if(findedExistCard.supertype == "Energy" && (JSON.parse(findedExistCard.subtypes) == "Basic" || JSON.parse(findedExistCard.subtypes) == null))
                        {
                            findedExistCard.amount += 1
                        }

                        // 기본 에너지가 아닌 경우
                        else
                        {
                            const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 2 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 2)

                            console.log(existsAmountOverFour)

                            if(existsAmountOverFour){
                                alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                            }else {
                                if(item.amount >= 2)
                                {
                                    alert("60장 덱에 기본 에너지 이외의 카드는 2장까지 추가하실 수 있습니다")
                                }
                                else
                                {
                                    item.amount += 1
                                }
                            }
                        }
                    }

                    // 60장 덱
                    if(this.deck_type == 60)
                    {
                        // 기본 에너지의 경우
                        if(findedExistCard.supertype == "Energy" && (JSON.parse(findedExistCard.subtypes) == "Basic" || JSON.parse(findedExistCard.subtypes) == null))
                        {
                            findedExistCard.amount += 1
                        }

                        // 기본 에너지가 아닌 경우
                        else
                        {
                            const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 4 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)

                            if(existsAmountOverFour){
                                alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                            }else {
                                if(item.amount >= 4)
                                {
                                    alert("60장 덱에 기본 에너지 이외의 카드는 4장까지 추가하실 수 있습니다")
                                }
                                else
                                {
                                    item.amount += 1
                                }
                            }
                        }
                    }
                }

                // 존재하지 않으면 카드 추가
                else
                {
                    const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                    const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                        // 국가에 따라 다른 속성 이름을 사용
                        const propName = `name_${cardNation}`;
                        const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                        // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                        if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                            // 일치한다면 amount 합산
                            if (!acc[nameWithoutParentheses]) {
                                acc[nameWithoutParentheses] = 0;
                            }
                            acc[nameWithoutParentheses] += obj.amount;
                        }

                        // 각 단계마다의 acc 출력 (디버깅 용)
                        console.log(acc);

                        // acc를 반환해야 다음 iteration에서 사용할 수 있음
                        return acc;
                    }, {});

                    // 합산된 amount가 2, 4 이상인지 확인
                    const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)
                    const existsAmountOverTwo = Object.values(amountSumsByName).some(amount => amount >= 2)

                    if(this.deck_type == 30){
                        if(existsAmountOverTwo){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                        }else {
                            this.selected_list.push(item)
                        }
                    }
                    else if(this.deck_type == 60){
                        if(existsAmountOverFour){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                        }else {
                            this.selected_list.push(item)
                        }
                    }
                }
            }
        },

        // 순서
        sortList(){
            if(this.sequence == "번호")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => a.number_en - b.number_en)
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => a.number_kr - b.number_kr)
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => a.number_jp - b.number_jp)
                }
                if(this.align_number){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "이름")
            {
                if(this.keyword.card_nation == "en"){
                    this.list.sort((a, b) => (typeof a.name_en !== 'string' ? 1 : (typeof b.name_en !== 'string' ? -1 : a.name_en.localeCompare(b.name_en))))
                }else if(this.keyword.card_nation == "kr"){
                    this.list.sort((a, b) => (typeof a.name_kr !== 'string' ? 1 : (typeof b.name_kr !== 'string' ? -1 : a.name_kr.localeCompare(b.name_kr))))
                }else if(this.keyword.card_nation == "jp"){
                    this.list.sort((a, b) => (typeof a.name_jp !== 'string' ? 1 : (typeof b.name_jp !== 'string' ? -1 : a.name_jp.localeCompare(b.name_jp))))
                }
                if(this.align_name){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "채용건수")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => b.amount_en - a.amount_en)
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => b.amount_kr - a.amount_kr)
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => b.amount_jp - a.amount_jp)
                }
                if(this.align_amount){
                    this.list = this.list.reverse()
                }
            }
            else if(this.sequence == "발매일")
            {
                if(this.keyword.card_nation == "en"){
                    this.list = this.list.sort((a, b) => new Date(b.releaseDate_en).getTime() - new Date(a.releaseDate_en).getTime())
                }else if(this.keyword.card_nation == "kr"){
                    this.list = this.list.sort((a, b) => new Date(b.releaseDate_kr).getTime() - new Date(a.releaseDate_kr).getTime())
                }else if(this.keyword.card_nation == "jp"){
                    this.list = this.list.sort((a, b) => new Date(b.releaseDate_jp).getTime() - new Date(a.releaseDate_jp).getTime())
                }
                if(this.align_date){
                    this.list = this.list.reverse()
                }
            }

            // console.log("채용건수", this.list.map(e => e.amount_kr))
            // console.log("발매일", this.list.map(e => e.releaseDate_kr))


            if(this.list_type == "card"){
                this.currentPageList = this.list.slice(0,12)
                this.pageLength = Math.ceil(this.list.length/12)
            }
            else if(this.list_type == "list")
            {
                this.currentPageList = this.list.slice(0,3)
                this.pageLength = Math.ceil(this.list.length/3)
            }

            this.list_ready = true
        },

        // 검색
        search_first(){
            this.loading = true
            this.$http.post("/api/new/card/search/first", {
                params: {
                    nation: this.keyword.card_nation
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data.slice(0,120)
                    if(this.list_type == "card"){
                        this.currentPageList = this.list.slice(0,12)
                        this.pageLength = Math.ceil(res.data.length/12)
                    }
                    else if(this.list_type == "list")
                    {
                        this.currentPageList = this.list.slice(0,3)
                        this.pageLength = Math.ceil(res.data.length/3)
                    }
                    this.list_ready = true
                    this.page = 1

                    this.sortList()
                    this.loading = false
                }else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                    this.loading = false
                }
            })
        },

        // 검색
        search(){
            this.loading = true
            this.$http.post("/api/new/card_"+this.keyword.card_nation+"/select/search", {
                params: {
                    keyword: this.keyword
                }
            }).then((res) => {
                if(res.data.length){
                    this.list = res.data
                    if(this.list_type == "card"){
                        this.currentPageList = this.list.slice(0,12)
                        this.pageLength = Math.ceil(res.data.length/12)
                    }
                    else if(this.list_type == "list")
                    {
                        this.currentPageList = this.list.slice(0,3)
                        this.pageLength = Math.ceil(res.data.length/3)
                    }
                    this.list_ready = true
                    this.page = 1

                    this.sequence = "발매일"
                    this.sortList()
                    this.loading = false
                }else{
                    this.list = []
                    this.currentPageList = []
                    this.pageLength = 1
                    this.page = 1
                    this.loading = false
                }
            })
        },

        onDeckTypeChange(newValue) {
            if(this.sizeImported)
            {
                this.deck_type = this.size
                this.deck_type_key++
            }
            // 첫 불러오기 외
            else
            {
                if(newValue == 30){
                    if (this.selected_list_amount > newValue) {
                        alert("설정하시려는 덱 종류보다 선택한 카드 수가 더 많습니다")
                        this.deck_type = 60
                        this.deck_type_key++
                    } else {
                        // 2개 이상이면서 해당 기본에너지가 포함되지 않은 경우
                        if(this.selected_list.some(item => (item.amount > 2) && !(item.supertype == "Energy" && item.subtypes != null && (JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null)))))){
                            alert("30장 덱으로 변경하시려면 기본 에너지 이외의 카드는 2장까지로 수량을 변경하셔야 합니다")
                            this.deck_type = 60
                            this.deck_type_key++
                        }
                        // 2개 이상인 것은 모두 기본 에너지인 경우
                        else{
                            this.deck_type = 30
                            this.deck_type_key++
                        }
                    }
                // 60장으로 변경하는 거면
                }else{
                    this.deck_type = 60
                    this.deck_type_key++
                }
            }
        },

        cancel(){
            if(confirm("지금 수정하고 있는 내용은 저장되지 않으며\n이전 페이지로 이동됩니다. 이동하시겠습니까?"))
            {
                this.$router.go(-1)
            }
        },

        // 수량 증가
        plusAmount(item){
            // 찬란한 포켓몬
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "Radiant Rare" && this.selected_list.some(e => e.rarity_en == "Radiant Rare")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "K 찬란한" && this.selected_list.some(e => e.rarity_kr == "K 찬란한")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "K かがや" && this.selected_list.some(e => e.rarity_jp == "K かがや")){
                    alert("찬란한 포켓몬은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }

            // ACE SPEC
            if(this.keyword.card_nation == "en"){
                if(item.rarity_en == "ACE SPEC Rare" && this.selected_list.some(e => e.rarity_en == "ACE SPEC Rare")){
                    alert("ACE SPEC Rare는 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "kr"){
                if(item.rarity_kr == "ACE SPEC" && this.selected_list.some(e => e.rarity_kr == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            else if(this.keyword.card_nation == "jp"){
                if(item.rarity_jp == "ACE SPEC" && this.selected_list.some(e => e.rarity_jp == "ACE SPEC")){
                    alert("ACE SPEC은 덱에 1장만 넣을 수 있습니다")
                    return
                }
            }
            
            console.log(item)
            if(this.selected_list_amount < this.deck_type)
            {
                // 30장 덱
                if(this.deck_type == 30)
                {
                    // 기본 에너지의 경우
                    if(item.supertype == "Energy" && item.subtypes != null && (JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null))))
                    {
                        item.amount += 1
                    }

                    // 기본 에너지가 아닌 경우
                    else
                    {
                        const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 2 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 2)

                        console.log(existsAmountOverFour)

                        if(existsAmountOverFour){
                            alert("30장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 2장까지 추가하실 수 있습니다")
                        }else {
                            if(item.amount >= 2)
                            {
                                alert("30장 덱에 기본 에너지 이외의 카드는 2장까지 추가하실 수 있습니다")
                            }
                            else
                            {
                                item.amount += 1
                            }
                        }
                    }
                }

                // 60장 덱
                if(this.deck_type == 60)
                {
                    // 기본 에너지의 경우
                    if(item.supertype == "Energy" && item.subtypes != null && (JSON.parse(item.subtypes).length && (JSON.parse(item.subtypes).includes("Basic") || JSON.parse(item.subtypes).includes(null))))
                    {
                        item.amount += 1
                    }

                    // 기본 에너지가 아닌 경우
                    else
                    {
                        const cardNation = this.keyword.card_nation; // 'kr', 'jp', 또는 'en' 중 하나를 포함하고 있다고 가정

                        const amountSumsByName = this.selected_list.reduce((acc, obj) => {
                            // 국가에 따라 다른 속성 이름을 사용
                            const propName = `name_${cardNation}`;
                            const nameWithoutParentheses = obj[propName].replace(/\(.*?\)/g, '').trim()

                            // 주어진 이름과 괄호가 제거된 이름이 일치하는지 체크
                            if (nameWithoutParentheses === item[propName].replace(/\(.*?\)/g, '').trim()) {
                                // 일치한다면 amount 합산
                                if (!acc[nameWithoutParentheses]) {
                                    acc[nameWithoutParentheses] = 0;
                                }
                                acc[nameWithoutParentheses] += obj.amount;
                            }

                            // 각 단계마다의 acc 출력 (디버깅 용)
                            console.log(acc);

                            // acc를 반환해야 다음 iteration에서 사용할 수 있음
                            return acc;
                        }, {});

                        // 합산된 amount가 4 이상인지 확인
                        const existsAmountOverFour = Object.values(amountSumsByName).some(amount => amount >= 4)

                        console.log(existsAmountOverFour)

                        if(existsAmountOverFour){
                            alert("60장 덱에 기본 에너지 이외의 카드는 이름이 같은 카드 포함 4장까지 추가하실 수 있습니다")
                        }else {
                            if(item.amount >= 4)
                            {
                                alert("60장 덱에 기본 에너지 이외의 카드는 4장까지 추가하실 수 있습니다")
                            }
                            else
                            {
                                item.amount += 1
                            }
                        }
                    }
                }
            }
            else
            {
                alert("덱 최대 수량에 도달하였습니다")
            }
        },

        // 수량 감소
        minusAmount(item){
            if(item.amount >= 2){
                item.amount--
            }else if(item.amount == 1){
                let index = this.selected_list.indexOf(item)
                if (index !== -1) {
                    this.selected_list.splice(index, 1)
                }
            }
        },

        // 순서 위로
        upSequence(item){
            let filteredIndex = 0

            if(item.supertype == 'Pokémon')
            {
                filteredIndex = this.selected_list_pokemon.indexOf(item)
            }
            if(item.supertype == 'Trainer')
            {
                filteredIndex = this.selected_list_trainer.indexOf(item)
            }
            if(item.supertype == 'Energy')
            {
                filteredIndex = this.selected_list_energy.indexOf(item)
            }

            if (filteredIndex !== -1) {
                let originalIndex = this.selected_list.indexOf(item)

                if (originalIndex > 0) {
                    let temp = this.selected_list[originalIndex - 1]
                    this.selected_list[originalIndex - 1] = this.selected_list[originalIndex]
                    this.selected_list[originalIndex] = temp
                }

                this.selected_list = [...this.selected_list]
            }
        },

        // 순서 아래로
        downSequence(item){
            let filteredIndex = 0

            if(item.supertype == 'Pokémon')
            {
                filteredIndex = this.selected_list_pokemon.indexOf(item)
            }
            if(item.supertype == 'Trainer')
            {
                filteredIndex = this.selected_list_trainer.indexOf(item)
            }
            if(item.supertype == 'Energy')
            {
                filteredIndex = this.selected_list_energy.indexOf(item)
            }

            if (filteredIndex !== -1) {
                let originalIndex = this.selected_list.indexOf(item)

                if (originalIndex < this.selected_list.length - 1) {
                    let temp = this.selected_list[originalIndex + 1]
                    this.selected_list[originalIndex + 1] = this.selected_list[originalIndex]
                    this.selected_list[originalIndex] = temp
                }

                this.selected_list = [...this.selected_list]
            }
        },

        // 저장
        save(){
            if(this.title == "")
            {
                alert("덱 이름을 입력해주세요")
            }
            else if(this.season == "")
            {
                alert("제품명 시즌을 선택해주세요")
            }
            else if(this.options.content == "")
            {
                alert("덱 설명을 입력해주세요")
            }
            else if(this.selected_list.length == 0)
            {
                alert("카드를 선택해주세요")
            }
            else if(this.selected_list_amount != this.deck_type)
            {
                alert("덱 총 장수를 채워야 저장하실 수 있습니다")
            }
            else
            {
                this.loading = true
                this.$http.post("/api/new/deck/update", {
                    params: {
                        title: this.title,
                        content: this.options.content,
                        season: this.season,
                        tag: this.tag?.toString(),
                        regulation_group: this.regulationGroup,
                        deck_nation: this.keyword.card_nation,
                        size: this.deck_type,
                        status: this.status,
                        id: this.$route.query.id
                    }
                }).then(async (res) => {
                    await this.$http.post("/api/new/deck/delete/card", {
                        params: {
                            deck_id: this.$route.query.id,
                        }
                    })

                    await this.$http.post("/api/new/deck/delete/type", {
                        params: {
                            deck_id: this.$route.query.id,
                        }
                    })

                    for (let item of this.selected_list) {
                        await this.$http.post("/api/new/deck/insert/card", {
                            params: {
                                deck_id: this.$route.query.id,
                                deck_nation: this.keyword.card_nation,
                                card_supertype: item.supertype,
                                card_id: item.id,
                                amount: item.amount
                            }
                        })
                    }

                    for (let type of this.selected_list_type) {
                        await this.$http.post("/api/new/deck/insert/type", {
                            params: {
                                deck_id: this.$route.query.id,
                                type: type
                            }
                        })
                    }

                    // 덱 분류하기
                    let e = {
                        id: this.$route.query.id
                    }
                    await this.deckUpdate(e)

                    this.loading = false
                    alert("덱이 등록되었습니다.")
                    this.$emit("submit")
                })
            }
        },

        // 덱 분류하기
        async deckUpdate(e){
            // 덱 하나하나 돌리기
            console.log("덱", e)

            // 덱 카드 가져오기 (deck_card_list & card_dump)
            let res = await this.$http.post("/api/league/auto/load/deck_card_list", {
                params: {
                    deck_id: e.id
                }
            })

            let deck_card_list = res.data
            console.log("덱 내 카드 목록", deck_card_list)

            let deck_main_card_list = []

            // 덱 카드 별 메인포켓몬 매칭해보기
            let promises = deck_card_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_card_list", {
                    params: {
                        name: e.name,
                        count: e.amount
                    }
                }).then((res) => {
                    if (res.data.length) {
                        return res.data
                    }
                    return []
                })
            })

            // 메인포켓몬 매칭된 덱들 합치기 (덱분류 후보 생성)
            await Promise.all(promises).then((results) => {
                results.forEach(data => {
                    if (data.length) {
                        deck_main_card_list.push(...data)
                    }
                })
            })

            // 덱분류 후보 (1차 분류)
            console.log("덱분류 후보 (1차 분류)", deck_main_card_list)




            // 덱분류 후보별 메인포켓몬이 더 있는 경우 확인하기
            let promises2 = deck_main_card_list.map(e => {
                return this.$http.post("/api/league/auto/load/deck_auto_card_list/multiple", {
                    params: {
                        deck_auto_id: e.deck_auto_id
                    }
                }).then((res) => {
                    if (res.data.length == 1) {
                        // 메인 포켓몬이 하나인 경우
                        return { type: 'single', data: res.data }
                    } else {
                        // 메인 포켓몬이 두 개 이상인 경우
                        return { type: 'multiple', data: res.data }
                    }
                })
            })
            
            let list1 = [] // 메인 포켓몬이 하나인 경우
            let list2 = [] // 메인 포켓몬이 두 개 이상인 경우

            await Promise.all(promises2).then((results) => {
                results.forEach(item => {
                    if (item.type === 'single') {
                        list1.push(item.data)
                    } else if (item.type === 'multiple') {
                        list2.push(item.data)
                    }
                });
            });

            // 결과 확인
            console.log("메인 포켓몬 1개인 경우 (일단 확정): ", list1)
            console.log("메인 포켓몬 2개 이상인 경우: ", list2)


            // 메인 포켓몬 2개 이상을 모두 일치하는지 덱인지 확인
            let deckCardNames = deck_card_list.map(item => item.name)
            list2 = list2.filter(subList => {
                return subList.every(item => deckCardNames.includes(item.main_pokemon_name))
            })

            console.log("조건에 맞는 메인 포켓몬 2개 이상의 리스트: ", list2)

            // 메인 포켓몬 조건을 통과한 덱 (1, 2~ 합산)
            let main_pass_list = [...list1, ...list2]
            console.log("메인 포켓몬 조건을 통과한 덱 (1, 2~ 합산)", main_pass_list)

            // 통과된 덱이 없으면 PASS
            if(!main_pass_list.length){
                // continue
                return
            }

            // ! 덱분류 후보덱 별 하위덱분류(variant) 확인
            let deck_auto_variant_list = []
            for (const e of main_pass_list) {
                let res = await this.$http.post("/api/league/auto/load/deck_auto_variant_list", {
                    params: {
                        deck_auto_id: e.deck_auto_id
                    }
                })

                console.warn("하위덱분류가 있는지 확인", res.data)
                if(res.data.length){
                    console.error("하위덱분류 찾음", res.data)
                    deck_auto_variant_list.push(...res.data)
                }
            }

            //  덱분류 후보덱 별 하위덱분류(variant) 목록
            console.log("하위덱분류 목록", deck_auto_variant_list)
            // ! -- /


            // 첫번쨰 배열로 매핑(메인포켓몬으로 인해 배열로 됨)
            let final_list = []
            final_list = main_pass_list.map(e => e[0])

            // generation 높은순 -> priority 높은순 정렬
            final_list.sort((a, b) => {
                // 먼저 generation 값으로 비교
                if (a.generation > b.generation) {
                    return -1 // a가 b보다 큰 경우
                }
                if (a.generation < b.generation) {
                    return 1 // b가 a보다 큰 경우
                }

                // generation 값이 같으면 priority 값으로 비교
                return b.priority - a.priority // 내림차순 정렬
            })

            // 최종 선정된 덱 분류
            let final_deck = final_list[0]
            console.log("최종 선정된 덱 분류", final_deck)



            // 메인 포켓몬 다시 찾아오기
            let final_main_pokemon = deck_card_list.filter(e => e.name == final_deck.main_pokemon_name)[0]
            console.log("최종 선정된 메인포켓몬", final_main_pokemon)

            this.$http.post("/api/league/auto/update/deck/detail", {
                params: {
                    id: e.id,
                    deck_name: final_deck.deck_name,
                    main_pokemon: final_main_pokemon.name,
                    main_pokemon_id: final_main_pokemon.card_id,
                    main_pokemon_image: final_main_pokemon.image
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    console.log("덱 분류 입력됨")
                }
            })
        }
    }
}
</script>
<style scoped>
/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

::v-deep .v-input__icon--clear {
    padding-top:16px !important;
}
</style>
<style scoped>
.v-btn::before {
  background-color: transparent;
}

.gradient-overlay-expanded {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #ff521c, transparent);
  position: relative;
}

.gradient-overlay-standard {
  width: 258px;
  height: 50px;
  background: linear-gradient(to right, #232323, transparent);
  position: relative;
}


/* 모바일 카테고리 */
::v-deep .mobile .v-select {
    min-height: 36px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 36px;
    height:36px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 36px;
    min-height: 36px;
}

::v-deep .mobile .v-select .v-select__selections {
    min-height: 36px !important;
    height: 36px !important;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px; /* 글꼴 크기 조절 */
    height: 36px;
}

::v-deep .mobile .v-select .v-input__append-inner,
::v-deep .mobile .v-select .v-input__prepend-inner {
    margin-top: 0;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .mobile .v-input--switch .v-label {
    font-size:11px !important;
}

/* 모바일 카테고리 */
::v-deep .mobile .v-text-field {
    min-height: 36px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 36px;
    height:36px;
}

::v-deep .mobile .v-text-field .v-input__slot {
    height: 36px;
    min-height: 36px;
}

::v-deep .mobile .v-text-field .v-select__selections {
    min-height: 36px !important;
    height: 36px !important;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px !important; /* 글꼴 크기 조절 */
    height: 36px;
    margin-left: 12px;
}

/* v-switch 라벨 폰트 크기 조절 */
::v-deep .v-input--switch .v-label {
    font-size:10px !important;
}

::v-deep .mobile .v-v-select__slot {
    font-size:12px !important;
}

::v-deep .mobile .v-select__selections .v-label {
    font-size:12px !important;
}

::v-deep .mobile .v-label {
    font-size:12px !important;
}

::v-deep button {
    min-width: 10px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #36d6e4 !important;
}
</style>



<style scoped>
/* 크기 줄이기 성공 */
::v-deep .mobile .v-text-field fieldset {
    min-height: 36px;
    height: 36px;
}

/* 크기 줄이기 성공 */
::v-deep .mobile .v-select fieldset {
    min-height: 36px;
    height: 36px;
}

/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}


/* v-text-field (FIN) */
::v-deep .mobile .v-text-field .v-text-field__slot {
    font-weight:bold !important;
    margin-left:4px !important;
    font-size: 12px !important;
}

/* v-select, v-autocomplete (FIN) */
::v-deep .mobile .v-text-field .v-select__slot {
    font-weight:bold !important;
    margin-left:4px !important;
    font-size: 12px !important;
}

/* 작동함 (FIN) */

/* V-select, v-text-field 핵심 */
::v-deep .mobile .v-select .v-select__slot {
    height:36px;
    padding-bottom:6px !important;
}

::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    height: 36px;
}

::v-deep .mobile .v-select fieldset {
    height:36px;
}

/* V-select, v-text-field 핵심 */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    padding-bottom:4px !important;
}
</style>